import { Typography } from '@mui/material';
import * as React from 'react';
import { memo } from 'react';

const ClueGuesser: React.FunctionComponent = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Typography variant="h4">Time to guess!</Typography>
        </div>
    );
};
export default memo(ClueGuesser);
