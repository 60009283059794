import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

export interface HelpProps {
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    className?: string;
}

const useStyles = makeStyles()(() => ({
    tooltip: {
        whiteSpace: 'pre-line',
    },
    root: {
        padding: 0,
    },
}));

export const Help: React.FunctionComponent<React.PropsWithChildren<HelpProps>> = (props) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={<>{props.children}</>}
                    open={open}
                    onClose={handleClose}
                    placement={props.placement ?? 'bottom'}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                >
                    <IconButton onClick={handleClick} className={`${classes.root} ${props.className}`} size="large">
                        <HelpOutline></HelpOutline>
                    </IconButton>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
};
