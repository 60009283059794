import { ActiveGame, Result, User, failure, success } from '@playtime/database/src';
import { PremiumTeam, premiumTeams } from '../api/lobby';
import { getTeamForPlayer } from './pure/pure';

export function addStarDust(user: User, amount: number) {
    // needed for existing users introduced before star dust
    user.starDust = user.starDust ?? {};
    user.starDust.transactions = user.starDust.transactions ?? [];

    user.starDust.current += amount;
    user.starDust.lifetime += amount;
    user.starDust.transactions.push({ change: amount });
    return success();
}

export function buyTeamBanner(user: User, banner: PremiumTeam) {
    const teamBannerName = banner.prefix;
    if (user.starDust.current < banner.cost)
        return failure(`cannot buy banner, "${teamBannerName}": not enough star dust`);
    user.inventory = user.inventory ?? { teamBanners: [] };
    user.inventory.teamBanners.forEach((teamBanner) => (teamBanner.isActive = false));
    user.inventory.teamBanners.unshift({ isActive: true, name: teamBannerName });
    user.starDust.current -= banner.cost;
    return success();
}

export function applyBannerToExistingGame(userId: string, game: ActiveGame, banner: PremiumTeam): Result {
    const gamesPlayer = game.players[userId];
    if (!gamesPlayer) return failure('attempted to apply banner to game that user is not in');
    const { team: playersTeam, index: playersTeamIx } = getTeamForPlayer(game.teams, userId);
    if (!playersTeam) return failure(`player doesn't belong to a team of the game`);

    const currentTeamBannerValue = premiumTeams[playersTeam.prefix]?.value ?? 0;
    if (currentTeamBannerValue >= banner.value) return success();

    game.teams[playersTeamIx] = { ...game.teams[playersTeamIx], ...banner };
    return success();
}
