import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FindInPage, Opacity } from '@mui/icons-material';
import { default as FishbowlStats } from '../Fishbowl/Stats/Stats';
import { default as CodenamesStats } from '../Codenames/Stats/Stats';
import { GameType } from '@playtime/database/src/model/lobby';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    selected: {
        color: theme.palette.secondary.main,
        '& svg': {
            color: theme.palette.secondary.main,
        },
    },
    fullScreenWidth: {
        position: 'fixed',
        marginLeft: 'auto',
        marginRight: 'auto',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    container: {
        paddingBottom: 60,
    },
}));

const Stats: React.FunctionComponent = () => {
    const [statPage, setStatPage] = useState<GameType>(GameType.Codenames);
    const { classes } = useStyles();
    const statsContent = () => {
        if (statPage === GameType.Codenames) return <CodenamesStats />;
        return <FishbowlStats />;
    };
    return (
        <>
            <Box className={classes.container}>{statsContent()}</Box>
            <BottomNavigation
                value={statPage}
                onChange={(_, newValue) => {
                    setStatPage(newValue);
                }}
                showLabels
                className={classes.fullScreenWidth}
            >
                <BottomNavigationAction
                    label="Fishbowl"
                    icon={<Opacity />}
                    value={GameType.Fishbowl}
                    classes={{ selected: classes.selected }}
                />
                <BottomNavigationAction
                    label="Codenames"
                    icon={<FindInPage />}
                    value={GameType.Codenames}
                    classes={{ selected: classes.selected }}
                />
            </BottomNavigation>
        </>
    );
};

export default Stats;
