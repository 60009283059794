import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { SnackbarKey } from 'notistack';
import * as React from 'react';

export function dismissNotification(closeSnackbar: (key: SnackbarKey) => void) {
    const SnackbarClose = (key: SnackbarKey) => (
        <IconButton onClick={() => closeSnackbar(key)} size="large">
            <Close />
        </IconButton>
    );
    return SnackbarClose;
}
