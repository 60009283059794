import { Button, Typography } from '@mui/material';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';

interface ThirdPartySignInProps {
    onClick: () => void;
    logoImagePath: string;
    logoName: string;
}

const useStyles = makeStyles()(() => ({
    logo: {
        height: '25px',
        width: '25px',
        marginRight: '12px',
        margin: '2px',
    },
    button: { margin: '5px', width: '235px', display: 'flex', justifyContent: 'left' },
    buttonText: { paddingRight: '2px', textTransform: 'none' },
}));

const ThirdPartySignIn: React.FunctionComponent<ThirdPartySignInProps> = (props) => {
    const { classes } = useStyles();

    return (
        <Button variant="outlined" className={classes.button} onClick={props.onClick}>
            <img className={classes.logo} src={props.logoImagePath} color="white" alt="" />
            <Typography className={classes.buttonText}>Sign in with {props.logoName}</Typography>
        </Button>
    );
};

export default ThirdPartySignIn;
