import { Box, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import { PlayerStats, StatsType } from '@playtime/database/src/model/stats';
import * as React from 'react';
import { SyncedComponentProps } from '../hoc/sync';
import StatCard from './StatCard';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
    const darkenedRed = red[600];
    const darkenedGreen = green[600];
    const square = {
        height: 10,
        width: 10,
        display: 'inline-block',
    };
    return {
        circle: {
            position: 'absolute',
        },
        winCircle: {
            color: darkenedGreen,
        },
        loseCircle: {
            color: darkenedRed,
        },
        redSquare: {
            ...square,
            backgroundColor: darkenedRed,
        },
        greenSquare: {
            ...square,
            backgroundColor: darkenedGreen,
        },
        counts: {
            display: 'inline-block',
            paddingLeft: 4,
            paddingRight: 5,
        },
    };
});

const WinRate: React.FunctionComponent<
    SyncedComponentProps<Pick<PlayerStats[StatsType], 'winCount' | 'lossCount' | 'tieCount'>>
> = (props) => {
    const { classes } = useStyles();
    const getWinPercentage = () => {
        if (props.winCount === null || props.lossCount === null || props.tieCount === null) return null;
        if (
            props.winCount === undefined ||
            props.lossCount === undefined ||
            props.tieCount === undefined ||
            props.winCount + props.lossCount + props.tieCount === 0
        )
            return undefined;
        return Math.round((props.winCount / (props.winCount + props.lossCount + props.tieCount)) * 100);
    };
    const winPercentage = getWinPercentage();
    return (
        <StatCard title="Win Rate">
            <Grid container justifyContent="center">
                <Grid item>
                    <Box position="relative" display="inline-flex">
                        <CircularProgress
                            className={`${classes.loseCircle}`}
                            variant="determinate"
                            value={100}
                            thickness={8}
                            size={60}
                        />
                        <CircularProgress
                            className={`${classes.circle} ${classes.winCircle}`}
                            variant="determinate"
                            value={winPercentage === null ? undefined : winPercentage}
                            thickness={8}
                            size={60}
                        />
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="subtitle1" component="div">
                                {winPercentage === null ? (
                                    <CircularProgress />
                                ) : winPercentage === undefined ? (
                                    'N/A'
                                ) : (
                                    `${winPercentage}%`
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-around">
                <Grid item>
                    <div className={classes.redSquare}></div>
                    <Typography variant="subtitle2" className={classes.counts}>
                        {props.lossCount}
                    </Typography>
                </Grid>
                <Grid item>
                    <div className={classes.greenSquare}></div>
                    <Typography variant="subtitle2" className={classes.counts}>
                        {props.winCount}
                    </Typography>
                </Grid>
            </Grid>
        </StatCard>
    );
};
export default WinRate;
