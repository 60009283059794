import { Checkbox, FormControlLabel } from '@mui/material';
import { ConfigType } from '@playtime/database/src/model/lobby';
import * as React from 'react';
import { Help } from '../../../common/Help';
import { ConfigSettingsProps } from './ConfigSetting';
import { useStyles } from './styles';

const BooleanConfig: React.FunctionComponent<ConfigSettingsProps<ConfigType.Boolean>> = (props) => {
    const { classes } = useStyles();

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.checked;
        // TODO: need to fix typing
        // eslint-disable-next-line
        (props.onChange as any)({
            [props.property]: {
                value,
                hasError: false,
            },
        });
    };
    return (
        <>
            <FormControlLabel
                value="start"
                control={<Checkbox color="primary" checked={props.value} onChange={onChange} />}
                label={props.name}
                labelPlacement="start"
            />
            <Help placement="top" className={classes.help}>
                {props.tooltip}
            </Help>
        </>
    );
};

export default BooleanConfig;
