import { SetStateAction } from 'react';
import { Dispatch } from 'react';

const stateBinding = <S>(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    state: [S, Dispatch<SetStateAction<S>>]
): void => {
    state[1]({
        ...state[0],
        [event.target.name]: event.target.value,
    });
};

const handleEnter = (event: React.KeyboardEvent, callback: (evt: React.KeyboardEvent) => void): void => {
    if (event.key === 'Enter') {
        callback(event);
    }
};

export { stateBinding, handleEnter };
