import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/lab/Alert';
import { Grid } from '@mui/material';
import { GameInfo } from './GameInfo';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/auth/selectors';
import { useHistory } from 'react-router-dom';
import { getUserDisplayName } from '../../../util/user';
import { ConfigButton } from '../../Lobby/GameConfig/ConfigButton';
import { ActiveGame } from '@playtime/database/src/model/activeGame';
import { devDefaultCodenamesConfig, defaultCodenamesConfig } from '@playtime/database/src/model/codenames';
import { devDefaultFishbowlConfig, defaultFishbowlConfig } from '@playtime/database/src/model/fishbowl';
import { JoinableActiveGame } from '@playtime/database/src/model/joinableActiveGame';
import { Games, LobbyGame, GameType, GameConfig, LobbyGameType } from '@playtime/database/src/model/lobby';
import { createNewGame, joinGame, deleteGame, deleteActiveGame } from '../../../back-end';
import { getSystemAppMode } from '../../../store/devTools/selectors';
import { getGame } from '../../../store/game/selectors';
import { filterObject, mapObject } from '../../../util/util';
import { getGameRoute } from '../../router/routing';
import GameTypeSelection from './GameTypeSelection';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    centeredContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    menuButton: {
        height: '2.86em',
        padding: '1em',
    },
    fullWidth: {
        width: '100%',
    },
    gameNameContainer: {
        width: '80%',
        height: '20em',
        overflowY: 'scroll',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.dark,
        backgroundColor: 'lightgoldenrodyellow',
        borderRadius: '5px',
    },
    title: {
        textAlign: 'center',
        width: '100%',
        padding: '0.2em',
        fontSize: '20px',
        fontWeight: 'bold',
    },
    rowPadding: {
        padding: '.8em 0',
    },
    gameSettings: {
        width: '220px',
    },
}));

interface GameSelectionProps {
    lobbyGames: Games<LobbyGame>;
    activeGames: Games<ActiveGame>;
    joinableActiveGames: Games<JoinableActiveGame>;
}

export const GamesSelection: React.FunctionComponent<GameSelectionProps> = (props) => {
    const { classes } = useStyles();
    const user = useSelector(getUser);
    const game = useSelector(getGame);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [gameTypeSelection, setGameTypeSelection] = useState<GameType | null>(null);
    const [newGameName, setNewGameName] = useState<string>('');
    const systemAppMode = useSelector(getSystemAppMode);
    const isDev = systemAppMode === 'dev';
    const [gameConfig, setGameConfig] = useState<GameConfig<GameType> | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const route = useHistory();

    //for prod, filter games to games the current player doesn't belong to
    const belongsToGame = (gameId: string) => !!(game?.games ?? []).find((game) => game.id === gameId);
    const availableLobbyGames = isDev ? props.lobbyGames : filterObject(props.lobbyGames, ([id]) => !belongsToGame(id));
    const availableActiveGames = isDev
        ? props.activeGames
        : filterObject(props.activeGames, ([id]) => !belongsToGame(id));
    const availableJoinableActiveGames = isDev
        ? props.joinableActiveGames
        : filterObject(props.joinableActiveGames, ([id]) => !belongsToGame(id));
    const allJoinableGames = { ...availableLobbyGames, ...availableJoinableActiveGames };

    //validation
    const isGameTooShort = () => {
        return newGameName.trim().length < 3;
    };
    const isGameDuplicate = () => {
        return Object.keys(allJoinableGames).some(
            (x) => allJoinableGames[x].name.toLowerCase() === newGameName?.toLowerCase().trim()
        );
    };
    const isGameTypeUnselected = () => {
        return gameTypeSelection === null;
    };
    const gameNameHasError = () => {
        return isGameTooShort() || isGameDuplicate() || isGameTypeUnselected();
    };
    const helperText = () => {
        if (!isSubmitted) return '';
        if (isGameTooShort()) return 'Game name too short!';
        if (isGameDuplicate()) return 'Duplicate game name!';
        if (isGameTypeUnselected()) return 'Select game type above!';
        return '';
    };

    //actions
    const createGame = async () => {
        setIsSubmitted(true);
        if (gameNameHasError() || gameTypeSelection === null || gameConfig === null) return;

        const result = await createNewGame(
            newGameName,
            { gameType: gameTypeSelection, config: gameConfig },
            user.uid,
            getUserDisplayName(user)
        );
        if (!result.success) {
            alert('Create game failed. ' + result.message);
        }
        route.push(getGameRoute(gameTypeSelection));
    };
    const tryJoinGame = async (gameId: string, gameType: GameType) => {
        if (!allJoinableGames[gameId]) {
            setErrorMessage(`Could not find game ${gameId}`);
            return;
        }

        await joinGame(gameId, user.uid, getUserDisplayName(user));
        route.push(getGameRoute(gameType));
    };
    const tryKillGame = async (gameId: string, gameType: GameType) => {
        await deleteGame(gameId);
        await deleteActiveGame(gameId, gameType);
    };

    //renders
    const displayAlert = (): React.ReactNode => {
        return errorMessage === '' ? null : (
            <Alert variant="filled" severity="error" className={classes.fullWidth}>
                {errorMessage}
            </Alert>
        );
    };
    type GamesWithPlayerData = Games<Pick<LobbyGame, 'gameType' | 'name'> & { canJoin: boolean }>;
    const displayGames = (games: GamesWithPlayerData, title: string, isActive: boolean) => {
        const gameEntries = Object.entries(games);
        if (!gameEntries.length) return null;
        const gameTypeEntries = isGameTypeUnselected()
            ? gameEntries
            : gameEntries.filter((ge) => ge[1].gameType === gameTypeSelection);
        return gameTypeEntries.length > 0 ? (
            <div className={`${classes.fullWidth} ${classes.centeredContainer}`}>
                <div className={classes.title}>{title}</div>
                <div className={`${classes.gameNameContainer} ${classes.centeredContainer}`}>
                    {gameTypeEntries.map(([gameId, game], index) => (
                        <GameInfo
                            key={index}
                            gameId={gameId}
                            gameName={game.name}
                            gameType={isActive ? game.gameType : LobbyGameType.Lobby}
                            joinGame={game.canJoin ? () => tryJoinGame(gameId, game.gameType) : undefined}
                            killGame={isDev ? () => tryKillGame(gameId, game.gameType) : undefined}
                        />
                    ))}
                </div>
            </div>
        ) : null;
    };

    const displayLobbyGames = () => {
        return displayGames(
            mapObject(availableLobbyGames, ([id, lg]) => ({ ...lg, canJoin: !belongsToGame(id) })),
            'Available Games',
            false
        );
    };

    const displayActiveGames = () => {
        const joinableActiveGamesWithPlayerData: GamesWithPlayerData = {};
        for (const gameId in availableJoinableActiveGames) {
            joinableActiveGamesWithPlayerData[gameId] = {
                ...availableJoinableActiveGames[gameId],
                canJoin: !belongsToGame(gameId),
            };
        }
        if (isDev) {
            for (const gameId in availableActiveGames) {
                if (gameId in joinableActiveGamesWithPlayerData) continue;
                joinableActiveGamesWithPlayerData[gameId] = {
                    ...availableActiveGames[gameId],
                    canJoin: false,
                };
            }
        }
        return displayGames(joinableActiveGamesWithPlayerData, 'Active Games', true);
    };

    const checkForEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            createGame();
        }
    };

    const editConfig = async (gameConfig: GameConfig<GameType>) => {
        setGameConfig(gameConfig);
    };

    const onGameTypeChange = (gameType: GameType | null) => {
        setGameTypeSelection(gameType);
        let defaultConfig = null;
        if (gameType === GameType.Fishbowl) defaultConfig = isDev ? devDefaultFishbowlConfig : defaultFishbowlConfig;
        else if (gameType === GameType.Codenames)
            defaultConfig = isDev ? devDefaultCodenamesConfig : defaultCodenamesConfig;
        setGameConfig(defaultConfig);
    };

    return (
        <div className={classes.centeredContainer}>
            {displayAlert()}
            <GameTypeSelection onChange={onGameTypeChange} />
            <Grid container justifyContent="center" spacing={1}>
                <Grid item>
                    {gameConfig && gameTypeSelection !== null && (
                        <ConfigButton editConfig={editConfig} config={gameConfig} gameType={gameTypeSelection} />
                    )}
                </Grid>
                <Grid item className={classes.gameSettings}>
                    <Grid container>
                        <Grid item>
                            <TextField
                                label="Game Name"
                                variant="outlined"
                                size="small"
                                value={newGameName}
                                error={isSubmitted && gameNameHasError()}
                                helperText={helperText()}
                                onChange={(e) => setNewGameName(e.target.value)}
                                onKeyDown={checkForEnter}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" className={classes.menuButton} onClick={createGame}>
                        Host
                    </Button>
                </Grid>
            </Grid>
            {displayLobbyGames()}
            {displayActiveGames()}
        </div>
    );
};
