import { Result, result, failure } from '@playtime/database';
import { ActiveGame } from '@playtime/database/src/model/activeGame';
import { CodenamesGameData } from '@playtime/database/src/model/historicalGames';
import { GameType } from '@playtime/database/src/model/lobby';
import { getHistoricalGameRecord } from '../business/historicalGames';
import { getPlayerIds } from '../business/pure/pure';
import db from '../databases';
import { getPlaceholderElo, getPlayersElo } from './playerStats';

export type GameRecordResults = {
    playersNewElo: Record<string, number>;
    historicalGameId: string;
    eloChanges: number[];
};
/** Records historical game data.
 *  Returns players and their new elo rating.
 */
export async function recordGame<
    T extends GameType,
    S extends T extends GameType.Codenames ? CodenamesGameData : undefined
>(
    game: ActiveGame,
    winningTeamIndices: number[],
    gameType: T,
    gameTypeSpecificData?: S
): Promise<Result<GameRecordResults>> {
    const gamePlayerIds = getPlayerIds(game);
    const playersCurrentEloResult = await getPlayersElo(gamePlayerIds, gameType);
    if (!playersCurrentEloResult.success || Object.keys(playersCurrentEloResult.value).length !== gamePlayerIds.length)
        return failure("couldn't record game data. one or more game's players' data don't exist.");
    const placeholderEloResult = await getPlaceholderElo(gamePlayerIds.length, game.teams.length, gameType);
    if (!placeholderEloResult.success) return placeholderEloResult;

    const historicalGameResult = getHistoricalGameRecord(
        game,
        playersCurrentEloResult.value,
        winningTeamIndices,
        placeholderEloResult.value,
        gameType
    );
    if (!historicalGameResult.success) return historicalGameResult;

    const historicalId = await db.historicalGames.create({
        ...historicalGameResult.value.historicalGame,
        ...(gameTypeSpecificData ?? {}),
    });

    return result({
        playersNewElo: historicalGameResult.value.playersNewElo,
        historicalGameId: historicalId,
        eloChanges: historicalGameResult.value.eloChanges,
    });
}
