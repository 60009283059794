import { Box, Button } from '@mui/material';
import { GameType } from '@playtime/database/src/model/lobby';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { leaveActiveGame } from '../../../../back-end';
import { changeTeams, nextClueGiver } from '../../../../back-end/api/codenames/gameplay';
import { getUser } from '../../../../store/auth/selectors';
import { BaseCodenamesProps } from '../../../models/game';

const GameOver: React.FunctionComponent<BaseCodenamesProps> = (props) => {
    const user = useSelector(getUser);
    return (
        <Box display="inline-flex" alignItems="center" flexDirection="column">
            <Button color="primary" variant="outlined" onClick={() => nextClueGiver(props.gameId)}>
                Next Clue Giver!
            </Button>
            <br />
            <Button color="secondary" variant="outlined" onClick={() => changeTeams(props.gameId)}>
                Change Teams
            </Button>
            <br />
            <Button
                variant="outlined"
                style={{ width: '100%' }}
                onClick={() => leaveActiveGame(user.uid, props.gameId, GameType.Codenames)}
            >
                Leave Game
            </Button>
        </Box>
    );
};

export default GameOver;
