import { Box, ClickAwayListener, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import { User } from '@playtime/database/src';
import * as React from 'react';
import { SyncedComponentProps } from '../../hoc/sync';

const StarDust: React.FunctionComponent<{ sx?: SxProps } & SyncedComponentProps<Pick<User, 'starDust'>>> = ({
    starDust,
    sx = [],
}) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const handleClose = () => {
        setOpen(false);
    };
    if (!starDust || starDust.current === 0) return null;
    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                <Tooltip
                    sx={{ whiteSpace: 'pre-line' }}
                    title={<Typography>What is this? Looks like it might be valuable.</Typography>}
                    open={open}
                    onClose={handleClose}
                    placement="bottom"
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                >
                    <IconButton
                        onClick={handleClick}
                        sx={{
                            color: 'inherit',
                        }}
                    >
                        <Box display="flex" sx={sx}>
                            <Box sx={{ display: 'flex', marginTop: '-2px' }}>
                                <img src="./star-dust.png" color="white" alt="" width="26" height="26" />
                            </Box>
                            <Typography>{starDust.current}</Typography>
                        </Box>
                    </IconButton>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
};

export default StarDust;
