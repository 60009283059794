import React, { useState } from 'react';
import { Button, Grid, Link, TextField } from '@mui/material';
import { handleEnter, stateBinding } from '../../../util/formHandlers';
import { setFirebaseUser } from '../../../store/auth/actions';
import assert from 'assert';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useSelector } from 'react-redux';
import { isAuthError } from '../../../util/typeGuards';
import { getFirebaseAuth } from '../../../store/auth/selectors';
import { makeStyles } from 'tss-react/mui';

interface CreateAccountProps {
    onSignIn: () => void;
    onCreateAccount: () => void;
}

const useStyles = makeStyles()((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const CreateAccount: React.FunctionComponent<CreateAccountProps> = (props) => {
    const { classes } = useStyles();
    const firebaseAuth = useSelector(getFirebaseAuth);
    const [state, setState] = useState({
        userName: '',
        email: '',
        password: '',
    });
    if (!firebaseAuth) return null;

    const createEmailUser = async () => {
        try {
            const userCredential = await createUserWithEmailAndPassword(firebaseAuth, state.email, state.password);
            assert(userCredential.user, 'Expected user for credential');
            await updateProfile(userCredential.user, {
                displayName: state.userName,
            });
            setFirebaseUser(userCredential.user);
            props.onCreateAccount();
        } catch (error) {
            if (!isAuthError(error)) return;
            const errorCode = error.code;
            const errorMessage = error.message;
            if (errorCode === 'auth/weak-password') {
                alert('The password is too weak.');
            } else {
                alert(errorMessage);
            }
            console.log(error);
        }
    };

    return (
        <form className={classes.form} noValidate>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        autoComplete="fname"
                        name="userName"
                        variant="outlined"
                        required
                        fullWidth
                        id="userName"
                        label="User Name"
                        autoFocus
                        value={state.userName}
                        onChange={(event) => stateBinding(event, [state, setState])}
                        onKeyDown={(event) => handleEnter(event, createEmailUser)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={state.email}
                        onChange={(event) => stateBinding(event, [state, setState])}
                        onKeyDown={(event) => handleEnter(event, createEmailUser)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={state.password}
                        onChange={(event) => stateBinding(event, [state, setState])}
                        onKeyDown={(event) => handleEnter(event, createEmailUser)}
                    />
                </Grid>
            </Grid>
            <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={createEmailUser}>
                Sign Up
            </Button>
            <Grid container>
                <Grid item>
                    <Link style={{ cursor: 'pointer' }} onClick={props.onSignIn} variant="body2" underline="hover">
                        Already have an account? Sign in
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
};

export default CreateAccount;
