import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { CodenamesGame, isClue, Clue } from '@playtime/database/src/model/codenames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getClueCount, getTeamForPlayer, isPlayersTurn } from '../../../../../back-end';
import { getUser } from '../../../../../store/auth/selectors';
import { SyncedComponentProps } from '../../../../hoc/sync';
import { BaseCodenamesProps } from '../../../../models/game';
import GameOver from '../GameOver';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    text: {
        textAlign: 'center',
    },
});

const Status: React.FunctionComponent<
    SyncedComponentProps<Pick<CodenamesGame, 'teams' | 'teamTurn' | 'clueGiven' | 'gameLog'>> & BaseCodenamesProps
> = (props) => {
    const { classes } = useStyles();
    const user = useSelector(getUser);
    if (!Array.isArray(props.teams)) return null;
    const { index, team } = getTeamForPlayer(props.teams, user.uid);
    if (!team) return null;
    const isPlayerClueGiver = isPlayersTurn(team, user.uid);
    const areTeamsReady = !props.teams.some((t) => !t.isReady);
    const clueIx = props.gameLog?.findIndex((log) => isClue(log));
    const clueCount = props.gameLog && clueIx !== undefined && getClueCount(props.gameLog[clueIx] as Clue);
    const maxGuesses = clueCount && clueCount + props.initialGameState.config.numberOfExtraGuesses;
    const correctGuessesRemaining =
        !maxGuesses || clueIx === undefined || maxGuesses === -1 ? '∞' : maxGuesses - clueIx;

    const waitingForOtherTeam = 'Waiting for other team to give clues and guess';
    const clueGiving = "It's your turn to give a clue";
    const clueGuessing = `Tap a word to guess
    Correct guesses remaining: ${correctGuessesRemaining}`;
    const waitingForClue = 'Waiting for your teammate to give a clue';
    const waitingForGuess = 'Waiting for your team to pick from your clue';
    const gameOver = 'Game Over!';

    const status = () => {
        if (!areTeamsReady) return gameOver;
        if (index !== props.teamTurn) return waitingForOtherTeam;
        if (isPlayerClueGiver) return props.clueGiven ? waitingForGuess : clueGiving;
        return props.clueGiven ? clueGuessing : waitingForClue;
    };
    return (
        <Box my={1}>
            <Typography className={classes.text} variant="h6" style={{ lineHeight: 1, whiteSpace: 'pre-wrap' }}>
                {status()}
            </Typography>
            <Box my={1}>{!areTeamsReady && <GameOver {...props} />}</Box>
        </Box>
    );
};

export default Status;
