import { CodenamesGame, GameAssignment, LobbyGame } from '@playtime/database/src';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { joinGame } from '../../back-end';
import { isFirebaseLoaded } from '../../store/appSettings/selectors';
import { getUser } from '../../store/auth/selectors';
import { getUserDisplayName } from '../../util/user';
import { getGameRoute, HOME_ROUTE } from '../router/routing';

interface RouteParams {
    routeGameId: string;
}

const isGameAssignment = (game: CodenamesGame | LobbyGame | GameAssignment): game is GameAssignment => 'type' in game;

const GameRouting: React.FunctionComponent = () => {
    const { routeGameId }: RouteParams = useParams();
    const user = useSelector(getUser);
    const _isFirebaseLoaded = useSelector(isFirebaseLoaded);
    const history = useHistory();
    React.useEffect(() => {
        if (!_isFirebaseLoaded) return;
        // Attempt to join a game if a game id is supplied
        const tryJoinGame = async () => {
            if (!routeGameId) return;
            const joinResult = await joinGame(routeGameId, user.uid, getUserDisplayName(user));
            if (!joinResult.success) history.push(HOME_ROUTE);
            else {
                history.push(
                    getGameRoute(isGameAssignment(joinResult.value) ? joinResult.value.type : joinResult.value.gameType)
                );
            }
        };
        tryJoinGame();
    }, [routeGameId, _isFirebaseLoaded]);
    return <>Redirecting...</>;
};

export default GameRouting;
