import { CodenamesGame } from '@playtime/database/src';
import * as React from 'react';
import db from '../../../../back-end/databases';
import TeamRoster from '../../../common/ScoreBanner/TeamRoster';
import { Sync, syncDataItem, SyncedComponentProps } from '../../../hoc/sync';
import { getPlayersTurn } from '../../../../back-end/business/pure/codenames';
import { ScoreBannerTeamRosterProps } from '../../../common/ScoreBanner/ScoreBanner';

const ScoreBannerRosterWithData = (
    teamData: SyncedComponentProps<Pick<CodenamesGame, 'teams' | 'teamTurn' | 'words' | 'clueGiven'>>
) => {
    const ScoreBannerTeamRoster: React.FunctionComponent<ScoreBannerTeamRosterProps> = (props) => {
        const syncCodenames = syncDataItem(db.codenamesGame, props.gameId);
        const SyncedTeamRoster = Sync(TeamRoster);
        return (
            <SyncedTeamRoster
                players={syncCodenames.prop('players')}
                playersTurn={getPlayersTurn(teamData.teams, teamData.teamTurn, teamData.words, teamData.clueGiven)}
                {...props}
            />
        );
    };
    return ScoreBannerTeamRoster;
};

export default ScoreBannerRosterWithData;
