import React from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { deleteAllSingleUseAccounts } from '../../back-end';
import { useSelector } from 'react-redux';
import { getFirebaseAuth } from '../../store/auth/selectors';
import { getSystemAppMode } from '../../store/devTools/selectors';
import { Alert } from '@mui/lab';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    centeredContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    fishbowlMenuButton: {
        height: '2.86em',
        padding: '1em',
    },
}));

export const Management: React.FunctionComponent = () => {
    const { classes } = useStyles();
    const systemAppMode = useSelector(getSystemAppMode);
    const firebaseAuth = useSelector(getFirebaseAuth);
    if (!firebaseAuth) return null;

    return (
        <div className={classes.centeredContainer}>
            <Box display="flex" flexDirection="column">
                <Box display="flex" m={1} justifyContent="center">
                    <Button variant="contained" color="secondary" className={classes.fishbowlMenuButton} disabled>
                        Delete All Games
                    </Button>
                </Box>
                <Alert severity="warning">
                    Cannot delete all games anymore - delete games individually by: <br />
                    1. turning on &apos;App dev mode&apos;
                    <br /> 2. deleting games from lobby
                </Alert>
                <Box display="flex" m={1} justifyContent="center">
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.fishbowlMenuButton}
                        disabled={systemAppMode !== 'dev'}
                        onClick={() => deleteAllSingleUseAccounts(firebaseAuth)}
                    >
                        Delete All Single-Use Accounts
                    </Button>
                </Box>
            </Box>
        </div>
    );
};
