import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Link, TextField } from '@mui/material';
import { handleEnter, stateBinding } from '../../../util/formHandlers';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useSelector } from 'react-redux';
import { isAuthError } from '../../../util/typeGuards';
import { getFirebaseAuth } from '../../../store/auth/selectors';
import { makeStyles } from 'tss-react/mui';

interface EmailSignInProps {
    onSignIn: () => void;
    onCreateAccount: () => void;
}

const useStyles = makeStyles()((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const EmailSignIn: React.FunctionComponent<EmailSignInProps> = (props) => {
    const { classes } = useStyles();
    const firebaseAuth = useSelector(getFirebaseAuth);
    const [state, setState] = useState({
        email: '',
        password: '',
    });
    if (!firebaseAuth) return null;

    const emailSignIn = async () => {
        try {
            await signInWithEmailAndPassword(firebaseAuth, state.email, state.password);
            props.onSignIn();
        } catch (error) {
            if (!isAuthError(error)) return;
            const errorMessage = error.message;
            if (error.code === 'auth/wrong-password') {
                alert('Wrong password.');
            } else {
                alert(errorMessage);
            }
        }
    };
    return (
        <form className={classes.form} noValidate>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={state.email}
                onChange={(event) => stateBinding(event, [state, setState])}
                onKeyDown={(event) => handleEnter(event, emailSignIn)}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={state.password}
                onChange={(event) => stateBinding(event, [state, setState])}
                onKeyDown={(event) => handleEnter(event, emailSignIn)}
            />
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={emailSignIn}
                data-testid="signInButton"
            >
                Sign In
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link
                        style={{ cursor: 'pointer' }}
                        onClick={props.onCreateAccount}
                        variant="body2"
                        underline="hover"
                    >
                        Don&apos;t have an Account? Create One
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
};

export default EmailSignIn;
