import { Grid, Button } from '@mui/material';
import * as React from 'react';

interface TurnCommandsProps {
    onNextTurn(): void;
    text: string;
}

const TurnCommands: React.FunctionComponent<TurnCommandsProps> = (props) => {
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
                <Button variant="outlined" color="secondary" onClick={props.onNextTurn}>
                    {props.text}
                </Button>
            </Grid>
        </Grid>
    );
};

export default TurnCommands;
