import assert from 'assert';
import * as core from '../../business/codenames/gameSetup';
import * as lobbyCore from '../../business/lobby';
import * as playerStats from '../playerStats';
import * as lobby from '../lobby';
import * as gameplay from './gameplay';
import * as joinableActiveGame from '../joinableActiveGame';
import db from '../../databases';
import { CodenamesGame, GameType } from '@playtime/database/src/model/lobby';
import { Result, failure, result } from '@playtime/database';

export async function getCodenamesTeams(
    playersElo: Record<string, number>,
    placeholderElo: { id: string; elo: number } | null,
    count: number
): Promise<Result<CodenamesGame['teams']>> {
    const balancedTeams = lobbyCore.getBalancedTeams(playersElo, placeholderElo, count);
    const teams = await lobby.generateTeams(balancedTeams);
    if (!teams.success) return teams;
    return result(teams.value.map((team) => ({ ...team, isReady: false })));
}

export async function startCodenamesGame(gameId: string): Promise<Result> {
    const lobbyGameResult = await lobby.fetchLobbyGameType(gameId, GameType.Codenames);
    if (!lobbyGameResult.success) return lobbyGameResult;
    const games = {
        lobbyGame: lobbyGameResult.value,
        activeGame: await db.codenamesGame.fetch(gameId),
    };
    if (!games.lobbyGame) return failure("Cannot start lobby game that doesn't exist");
    const playerIds = Object.keys(games.lobbyGame.players ?? {});
    if (!playerIds.length) return failure('Not enough players');

    const playersEloResult = await playerStats.getPlayersElo(playerIds, GameType.Codenames);
    if (!playersEloResult.success) return playersEloResult;
    const placeholderEloResult = await playerStats.getPlaceholderElo(
        playerIds.length,
        games.lobbyGame.config.numberOfTeams,
        GameType.Codenames
    );
    if (!placeholderEloResult.success) return placeholderEloResult;

    const teams = await getCodenamesTeams(
        playersEloResult.value,
        placeholderEloResult.value,
        games.lobbyGame.config.numberOfTeams
    );
    if (!teams.success) return teams;
    const result = core.activateCodenamesGame(games, teams.value);
    if (!result.success) return result;
    const activeGame = games.activeGame;
    assert(activeGame !== undefined, 'Active game should be defined');
    await db.codenamesGame.set(gameId, activeGame);
    await db.lobbyGame.delete(gameId);
    if (activeGame.config.allowLateJoin)
        await joinableActiveGame.trackJoinableActiveGame(gameId, GameType.Codenames, activeGame.name);
    await lobby.setGameType(playerIds, gameId, GameType.Codenames);
    await gameplay.notifyPlayers(gameId);
    return result;
}
