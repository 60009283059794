export function randomName(length: number): string {
    let name = '';
    for (let i = 0; i < length; i++) {
        const number = Math.floor(Math.random() * 36);
        name += number < 10 ? number.toString() : String.fromCharCode(number - 10 + 65);
    }
    return name;
}

/** Generates a random number between 0 inclusive and upperBound exclusive. */
export function randomInt(upperBound: number): number {
    return Math.floor(Math.random() * upperBound);
}
