import { Badge, Button, Typography } from '@mui/material';
import { CodenamesGame } from '@playtime/database/src/model/codenames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getTeamForPlayer, isPlayersTurn } from '../../../../../back-end';
import { passTurn, readyClueGiver } from '../../../../../back-end/api/codenames/gameplay';
import { getUser } from '../../../../../store/auth/selectors';
import { SyncedComponentProps } from '../../../../hoc/sync';
import { BaseCodenamesProps } from '../../../../models/game';
import TurnCommands from './TurnCommands';

const AreYouReady: React.FunctionComponent<SyncedComponentProps<Pick<CodenamesGame, 'teams'>> & BaseCodenamesProps> = (
    props
) => {
    const user = useSelector(getUser);
    if (!props.teams) return null;
    const { index, team } = getTeamForPlayer(props.teams, user.uid);
    const isPlayerClueGiver = team && isPlayersTurn(team, user.uid);
    const onPassTurn = () => {
        passTurn(props.gameId, user.uid);
    };
    const readyTeams = props.teams.filter((team) => team.isReady);
    const isPlayerReady = index === -1 || props.teams[index].isReady;

    const statusText = () => {
        if (isPlayerClueGiver) {
            if (readyTeams.length > 0)
                return isPlayerReady ? 'Waiting for other team...' : 'Other team is waiting for you!';
            return 'Tap ready to give clues or pass';
        }

        return 'Waiting for clue givers to be ready.';
    };
    const readyCountComponent = (
        <>
            <sup>{readyTeams.length}</sup>&frasl;<sub>{props.teams.length}</sub>
        </>
    );
    const readyButton = (
        <Button
            color="primary"
            variant="outlined"
            style={{ margin: '5px' }}
            onClick={() => readyClueGiver(props.gameId, user.uid)}
        >
            Ready!
        </Button>
    );
    return (
        <>
            {isPlayerClueGiver && (
                <>
                    <Typography variant="h4">It&apos;s your turn!</Typography>
                    {readyTeams.length === 0 ? (
                        readyButton
                    ) : (
                        <Badge badgeContent={readyCountComponent} overlap="circular" color="secondary">
                            {readyButton}
                        </Badge>
                    )}
                </>
            )}
            {isPlayerClueGiver && <TurnCommands onPassTurn={onPassTurn} />}
            <br />
            <Typography variant="h5" style={{ textAlign: 'center' }}>
                {statusText()}
            </Typography>
        </>
    );
};

export default AreYouReady;
