import { Grid } from '@mui/material';
import { CodenamesGame, Word } from '@playtime/database/src/model/codenames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getTeamForPlayer, isPlayersTurn } from '../../../../back-end';
import { selectWord } from '../../../../back-end/api/codenames/gameplay';
import { getUser } from '../../../../store/auth/selectors';
import { isNullOrUndefined } from '../../../../util/util';
import { SyncedComponentProps } from '../../../hoc/sync';
import { BaseCodenamesProps } from '../../../models/game';
import { getColor } from '../utility';
import WordCard from './WordCard';

const WordGrid: React.FunctionComponent<
    SyncedComponentProps<Pick<CodenamesGame, 'words' | 'teams' | 'teamTurn'>> &
        BaseCodenamesProps & { isPreviousGame?: boolean }
> = (props) => {
    const user = useSelector(getUser);
    if (!props.words || (!props.isPreviousGame && !Array.isArray(props.teams))) return null;

    const gameOver = !!(props.isPreviousGame || props.teams?.some((t) => !t.isReady));
    let revealBoard = gameOver;
    let votePassCount: undefined | number = undefined;
    if (props.teams && !revealBoard) {
        const { team } = getTeamForPlayer(props.teams, user.uid);
        const isPlayerClueGiver = team && isPlayersTurn(team, user.uid);
        revealBoard = !!isPlayerClueGiver;
        // teamTurn would be undefined if gameOver
        votePassCount = isNullOrUndefined(props.teamTurn) ? undefined : props.teams[props.teamTurn].players.length - 1;
    }

    const onWordClick = (word: Word) => {
        if (word.revealed) return;
        selectWord(props.gameId, user.uid, word, props.initialGameState.config.secondsPerTurn > 0);
    };

    return (
        <Grid container justifyContent="space-around" alignItems="center" spacing={1} sx={{ maxWidth: '500px' }}>
            {Object.entries(props.words).map(([val, word]) => {
                if (!props.teams) return null;
                const { darkFont, primaryColor, secondaryColor } = getColor(word, props.teams);
                return (
                    <Grid item key={val} sx={{ paddingTop: '0 !important', paddingLeft: '0 !important' }}>
                        <WordCard
                            onClick={() => onWordClick(word)}
                            {...word}
                            revealBoard={revealBoard}
                            numberOfVotes={votePassCount}
                            primaryColor={primaryColor}
                            darkFont={darkFont}
                            secondaryColor={secondaryColor}
                        ></WordCard>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default WordGrid;
