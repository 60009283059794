import { ChatCompletionRequestMessage } from 'openai';
import store from '../../store';
import { getBackendUri } from '../../store/appSettings/selectors';
import Axios from 'axios';
import { Result, failure, result } from '@playtime/database/src';

export async function completeChat(
    context: ChatCompletionRequestMessage[],
    chatHistory: ChatCompletionRequestMessage[],
    message: ChatCompletionRequestMessage
): Promise<Result<string>> {
    const state = store.store.getState();
    const backendUri = getBackendUri(state);
    try {
        return result(
            (
                await Axios.post(`${backendUri}/api/completeChat`, {
                    chatCompletionRequest: [...context, ...chatHistory, message],
                })
            ).data.choices[0].message.content
        );
    } catch (e) {
        return failure(`failure completing chat: ${e}`);
    }
}
