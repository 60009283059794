import * as React from 'react';
import { startClueGiving } from '../../../../back-end';
import { getCurrentPhrase } from '../../../../back-end';
import { AreYouReady } from './AreYouReady';
import { ClueGiving } from './ClueGiving';
import { NotOptional } from '../../../../util/util';
import { BaseFishbowlProps } from '../../../models/game';
import { FishbowlGame } from '@playtime/database/src/model/fishbowl';

export interface ClueGiverProps
    extends BaseFishbowlProps,
        NotOptional<
            Pick<
                FishbowlGame,
                'turn' | 'phrasePool' | 'turnEndTime' | 'pausedRemainingMs' | 'skipsUsed' | 'guessedPhrases'
            >
        > {}

export const ClueGiver: React.FunctionComponent<ClueGiverProps> = (props) => {
    const localStartClueGiving = () => startClueGiving(props.gameId, props.turn);

    if (!props.turnEndTime) {
        return <AreYouReady pauseRemainingTimeMs={props.pausedRemainingMs} startClueGiving={localStartClueGiving} />;
    }
    const currentPhrase = getCurrentPhrase(props.phrasePool) ?? '<Missing Phrase>';
    const guessedPhrases = (props.guessedPhrases ?? []).map(({ phrase }) => phrase);
    return (
        <>
            <ClueGiving
                gameId={props.gameId}
                initialGameState={props.initialGameState}
                currentPhrase={currentPhrase}
                guessedPhrases={guessedPhrases}
                skipsUsed={props.skipsUsed ?? 0}
                turn={props.turn}
            />
        </>
    );
};
