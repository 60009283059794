import { FishbowlGame } from '@playtime/database/src';
import { isNullOrUndefined } from '../../../util/util';
import { SyncedComponentProp } from '../../../view/hoc/sync';

export function getPlayersTurn(
    teams: SyncedComponentProp<FishbowlGame['teams']>,
    teamTurn: SyncedComponentProp<FishbowlGame['teamTurn']>
): string[] {
    if (!teams || isNullOrUndefined(teamTurn)) return [];
    const activeTeam = teams[teamTurn];
    const activePlayer = activeTeam.players[activeTeam.playerTurn];
    return [activePlayer];
}
