import * as types from './types';
import store from '..';
import { Auth, User as FirebaseUser } from 'firebase/auth';
import { User } from '@playtime/database/src';

/** This deletes the playtime data of the user */
export const setFirebaseUser = (user: FirebaseUser): void => {
    window.localStorage.setItem('logged-in', 'true');
    store.store.dispatch({
        type: types.SET_USER,
        user,
    });
};

/** This adds the playtime user data to the existing firebaseUser data */
export const setUser = (user: User): void => {
    store.store.dispatch({
        type: types.MODIFY_USER,
        user,
    });
};

export const logout = (): void => {
    window.localStorage.setItem('logged-in', 'false');
    store.store.dispatch({
        type: types.LOGOUT,
        user: null,
    });
};

export const setFirebaseAuth = (firebaseAuth: Auth) => {
    store.store.dispatch({
        type: types.SET_FIREBASE_AUTH,
        firebaseAuth,
    });
};
