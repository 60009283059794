import { Button } from '@mui/material';
import React, { useEffect, FunctionComponent } from 'react';
import { useSnackbar } from 'notistack';
import { contestPhrase } from '../../../../back-end';
import { Handles } from '../../../../util/handles';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../store/auth/selectors';
import { BaseFishbowlProps } from '../../../models/game';
import db from '../../../../back-end/databases';

export interface GuessedPhrasesProps extends BaseFishbowlProps {
    turn: number;
}

const formKey = (index: number) => `k-${index}`;

export const GuessedPhrases: FunctionComponent<GuessedPhrasesProps> = (props) => {
    const user = useSelector(getUser);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = (phrase: string, guessedPhraseIndex: number) => (
        <Button
            color="secondary"
            size="small"
            onClick={() => {
                contestPhrase(props.gameId, user.uid, guessedPhraseIndex, props.turn).then((result) => {
                    if (!result.success) {
                        enqueueSnackbar(`Failed to contest ${phrase}`, { autoHideDuration: 2500, variant: 'warning' });
                    }
                });
                closeSnackbar(formKey(guessedPhraseIndex));
            }}
        >
            Contest
        </Button>
    );

    useEffect(() => {
        const liveSnackbars = new Set<string>(props.initialGameState.guessedPhrases?.map((_, i) => formKey(i)));
        closeSnackbar();
        let turnEndTime = props.initialGameState.turnEndTime;

        const handles = new Handles(
            db.fishbowlGame.property(props.gameId, 'guessedPhrases').listen((phrases) => {
                if (turnEndTime) {
                    (phrases ?? []).map((phrase, index) => {
                        const key = formKey(index);
                        if (!liveSnackbars.has(key)) {
                            liveSnackbars.add(key);
                            enqueueSnackbar(phrase.phrase, {
                                action:
                                    props.initialGameState.config.minContestVotes > 0
                                        ? action(phrase.phrase, index)
                                        : undefined,
                                key,
                                preventDuplicate: true,
                            });
                        }
                    });
                }
            }),
            db.fishbowlGame.property(props.gameId, 'turnEndTime').listen((newTurnEndTime) => {
                if (newTurnEndTime && !turnEndTime) {
                    // Start clue giving should clear them
                    liveSnackbars.clear();
                    closeSnackbar();
                }
                turnEndTime = newTurnEndTime;
            })
        );
        return () => {
            handles.stop();
            liveSnackbars.clear();
            closeSnackbar();
        };
    }, [props.turn]);

    return <></>;
};
