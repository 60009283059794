import { FishbowlStats } from '@playtime/database/src/model/stats';
import * as React from 'react';
import { SyncedComponentProps } from '../../hoc/sync';
import NumberStat from '../../Stats/NumberStat';

const TimePerClue: React.FunctionComponent<
    SyncedComponentProps<Pick<FishbowlStats, 'guessedCount' | 'timeGivingClues'>>
> = (props) => {
    const timePerClue = () => {
        if (props.timeGivingClues === null || props.guessedCount === null) return null;
        if (props.timeGivingClues === undefined || !props.guessedCount) return 'None';
        if (props.timeGivingClues === 0) return 0;
        return (props.timeGivingClues / props.guessedCount).toFixed(1);
    };
    return <NumberStat title="Time / Clue" caption="Average seconds" number={timePerClue()} />;
};

export default TimePerClue;
