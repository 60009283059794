import { FishbowlGame } from '@playtime/database/src';
import * as React from 'react';
import { getPlayersTurn } from '../../../../back-end/business/pure/fishbowl';
import { ScoreBannerTeamRosterProps } from '../../../common/ScoreBanner/ScoreBanner';
import TeamRoster from '../../../common/ScoreBanner/TeamRoster';

const ScoreBannerRosterWithData = (fishbowlData: Pick<FishbowlGame, 'teamTurn' | 'teams' | 'players'>) => {
    const ScoreBannerTeamRoster: React.FunctionComponent<ScoreBannerTeamRosterProps> = (props) => {
        return (
            <TeamRoster
                players={fishbowlData.players}
                playersTurn={getPlayersTurn(fishbowlData.teams, fishbowlData.teamTurn)}
                {...props}
            />
        );
    };
    return ScoreBannerTeamRoster;
};

export default ScoreBannerRosterWithData;
