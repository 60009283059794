import { signOut } from '@firebase/auth';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getFirebaseAuth } from '../../../store/auth/selectors';

interface LogoutProps {
    onClick?(): void;
}

const Logout: React.FunctionComponent<LogoutProps> = (props) => {
    const firebaseAuth = useSelector(getFirebaseAuth);
    if (!firebaseAuth) return null;
    const logout = () => {
        props.onClick && props.onClick();
        signOut(firebaseAuth);
    };
    return (
        <MenuItem onClick={logout}>
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>
            Log out
        </MenuItem>
    );
};

export default Logout;
