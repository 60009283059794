import { ActiveGame, GameResults, Team } from './activeGame';
import { ConfigType, GameConfigSettings, GameType, LobbyPlayer } from './lobby';

export const defaultCodenamesConfig = {
    /** Total number of teams, >0 */
    numberOfTeams: 2,
    /** Number of words, >1 */
    numberOfWords: 25,
    /** Number of clues to guess for team that goes second. Team that goes first will be numberOfClues + 1, >0 */
    numberOfClues: 8,
    /** Number of assassins, >=0 */
    numberOfAssassins: 1,
    /** Number of extra guesses the team can guess more than the number given with the clue, >=0 */
    numberOfExtraGuesses: 1,
    /** Number of seconds each player gets per turn. 0 is unlimited, >= 0 */
    secondsPerTurn: 0,
    /** Decides whether or not to send notifications to users after each user interaction */
    sendNotifications: false,
    /** Allows players to join the game late */
    allowLateJoin: false,
};
export const devDefaultCodenamesConfig = {
    ...defaultCodenamesConfig,
    numberOfWords: 10,
    numberOfClues: 2,
    numberOfTeams: 1,
    numberOfVotes: 1,
};

export const codenamesConfigSettings: GameConfigSettings<CodenamesConfig> = {
    numberOfWords: {
        name: 'Number of words',
        tooltip: 'The number of words to guess from.',
        configType: ConfigType.Numeric,
        minimum: 2,
    },
    numberOfClues: {
        name: 'Number of objective words',
        tooltip:
            'Number of objective words to guess for team that goes second. Team that goes first will have to guess this plus 1 extra clue.',
        configType: ConfigType.Numeric,
        minimum: 1,
    },
    numberOfAssassins: {
        name: 'Number of assassins',
        tooltip: 'Number of words disguised as assassins. If guessed, results in an automatic loss for that team.',
        configType: ConfigType.Numeric,
        minimum: 0,
    },
    numberOfExtraGuesses: {
        name: 'Extra Guesses',
        tooltip:
            'Number of extra guesses a team can do. If this is 1, then the team can guess the number indicated by the clue + 1.',
        configType: ConfigType.Numeric,
        minimum: 0,
    },
    secondsPerTurn: {
        name: 'Timer',
        tooltip: 'Optional timer that will end the turn. Leave as 0 for no timer.',
        configType: ConfigType.Numeric,
        minimum: 0,
    },
    numberOfTeams: {
        name: 'Number of Teams',
        tooltip: 'Number of teams to divide players into.',
        configType: ConfigType.Numeric,
        minimum: 1,
    },
    sendNotifications: {
        name: 'Turn Based',
        tooltip: "If checked, game will send players notifications when it's their turn.",
        configType: ConfigType.Boolean,
    },
    allowLateJoin: {
        name: 'Allow Late Join',
        tooltip: 'Allows players to join the game after it started.',
        configType: ConfigType.Boolean,
    },
};

export type CodenamesConfig = typeof defaultCodenamesConfig;

export type CodenamesPlayer = LobbyPlayer & {
    /**
     * the uri to cancel a players pending reminder
     */
    reminderCancelUri?: string;
};

export interface CodenamesTeam extends Team {
    isReady: boolean;
}

export enum SpyType {
    bystander,
    Assassin,
    TeamStart,
    Team = 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10, // maxing out at 8 teams
}
export interface Word {
    /** the word itself */
    word: string;
    /** Determines if a word is an objective, fail, neutral, etc. */
    spyType: SpyType;
    /** Whether the word's been guessed/revealed */
    revealed: boolean;
    /** The people voting for to guess the word */
    voters?: string[];
}
export interface Clue {
    /** the clue given by the clue giver */
    clue: string;
    /** the intended word count associated with the given clue */
    count: '∞' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
    /** the name of the clue giver */
    clueGiverName: string;
    /** the index of the team of the clue giver */
    clueGiverTeam: number;
    /** Corresponds to the game's turn when the Clue or Guess took place */
    turn: number;
}
export interface Guess {
    /** the word guessed */
    word: string;
    /** the index of the team that guessed the word */
    guesserTeam: number;
    /** Corresponds to the game's turn when the Clue or Guess took place */
    turn: number;
}
export function isClue(clueOrGuess: Clue | Guess): clueOrGuess is Clue {
    return (clueOrGuess as Clue).clue !== undefined;
}
export function isGuess(clueOrGuess: Clue | Guess): clueOrGuess is Guess {
    return (clueOrGuess as Guess).word !== undefined;
}

interface PreviousGame {
    words: CodenamesGame['words'];
    gameLog: CodenamesGame['gameLog'];
    teams: CodenamesGame['teams'];
    gameResults?: CodenamesGame['gameResults'];
}

export type CodenamesResults = {
    allyAgentCount: number;
    enemyAgentCount: number;
    bystanderCount: number;
    assassinCount: number;
};

export interface CodenamesGame extends ActiveGame<GameType.Codenames, CodenamesPlayer, CodenamesTeam> {
    /** List of words to guess from */
    words: Record<string, Word>;
    /** Zero index of team whose turn it is */
    teamTurn: number;
    /** Time the current turn should end; undefined if not giving clues */
    turnEndTime?: number;
    /** Streak of correctly guessed words of the current clue giver */
    streak?: string[];
    /** The clues given by clue givers */
    gameLog?: (Guess | Clue)[];
    /** Reflects whether the clue's been given or not yet */
    clueGiven: boolean;
    /**
     * Current turn number, spanning all players for entire game.
     * Increments when clue giver start giving clues.
     * Used for verifying calls are still in expected state.
     */
    turn: number;
    /** The previous game's data. Used for viewing previous game state. */
    previousGame?: PreviousGame;
    /** Game results summary data */
    gameResults?: GameResults<CodenamesResults>;
}
