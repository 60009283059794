import {
    goNextPhrase,
    incrementScore,
    decrementScore,
    returnSkippedPhrasesToPool,
    nextPlayerTurn,
} from './gameplayCore';
import { getCurrentClueGiver, getCurrentPhrase, getPlayersOutcome, getWinningTeamIndices, trueNow } from '../pure/pure';
import { Immutable } from '../../../util/util';
import { PlayerOutcome } from '../../api/playerStats';
import { Result, success, result, failure } from '@playtime/database';
import { FishbowlGame, FishbowlConfig } from '@playtime/database/src/model/fishbowl';

export function startClueGiving(game: FishbowlGame, turn: number, timeOffset: number): Result {
    if (game.turnEndTime) {
        return failure('Timer already started');
    }
    if (game.turn !== turn) {
        return failure('Unexpected turn');
    }
    game.turn++;
    delete game.guessedPhrases;
    let remainingTimeMs = game.config.secondsPerTurn * 1000;
    if (game.pausedRemainingMs) {
        remainingTimeMs = game.pausedRemainingMs;
        delete game.pausedRemainingMs;
    }
    game.turnEndTime = trueNow(timeOffset) + remainingTimeMs;
    goNextPhrase(game, timeOffset);
    return success();
}

export function passClueGiving(game: FishbowlGame, turn: number): Result {
    if (game.turn !== turn) {
        return failure('Unexpected turn');
    }
    if (game.turnEndTime !== undefined) {
        // TODO: could pause?
        return failure('Timer already started');
    }
    game.turn++;
    nextPlayerTurn(game);
    return success();
}

export type GuessPhraseResult =
    | {
          gameStillGoing: true;
          previousPhraseStartTime: number;
          currentClueGiverId: string;
      }
    | {
          gameStillGoing: false;
          turnEndTime: number;
          previousPhraseStartTime: number;
          currentClueGiverId: string;
          game: FishbowlGame;
          winningTeamIndices: number[];
          playersOutcome: PlayerOutcome[];
      };

/** Return true if game is still going, false if game is over. */
export function guessPhrase(
    game: FishbowlGame,
    userId: string,
    turn: number,
    phrase: string,
    timeOffset: number
): Result<GuessPhraseResult> {
    if (game.turn !== turn) {
        return failure('Unexpected turn');
    }
    const guessedPhrase = game.phrasePool?.pop();
    if (guessedPhrase === undefined) {
        return failure('No phrases in pool');
    }
    if (guessedPhrase !== phrase) {
        return failure('Guessed phrase is not the current phrase');
    }
    const currentClueGiver = getCurrentClueGiver(game);
    if (currentClueGiver.id !== userId) {
        return failure(`Expected clue giver '${currentClueGiver.name}', but received '${game.players[userId]}'`);
    }
    if (game.currentPhraseStartMs === undefined) {
        return failure('Clue start time should be defined');
    }
    if (game.turnEndTime === undefined) {
        return failure('Turn end time should be defined when guessing a phrase');
    }

    incrementScore(game);
    game.guessedPhrases = [...(game.guessedPhrases ?? []), { phrase: guessedPhrase }];
    const turnEndTime = game.turnEndTime;
    const previousPhraseStartTime = game.currentPhraseStartMs;
    const nextPhrase = goNextPhrase(game, timeOffset);

    if (nextPhrase) {
        return result({
            gameStillGoing: true,
            previousPhraseStartTime,
            currentClueGiverId: currentClueGiver.id,
        });
    }
    const winningTeamIndices = getWinningTeamIndices(game.teams);
    return result({
        gameStillGoing: false,
        turnEndTime,
        previousPhraseStartTime,
        currentClueGiverId: currentClueGiver.id,
        game,
        winningTeamIndices,
        playersOutcome: getPlayersOutcome(game.teams, winningTeamIndices),
    });
}

export function undoGuess(game: FishbowlGame, turn: number): Result {
    if (!game.config.enableUndoGuess) {
        return failure('Undo is disabled in config');
    }
    if (!game.turnEndTime) {
        return failure('Can only undo during turn');
    }
    if (game.turn !== turn) {
        return failure('Unexpected turn');
    }
    const undoPhrase = game.guessedPhrases?.pop();
    if (undoPhrase === undefined) {
        return failure('Can only undo guessed phrases');
    }
    if (!game.phrasePool || game.phrasePool.length < 1) {
        // TODO: This should be allowed at this layer
        return failure('No phrases in pool');
    }
    if ((undoPhrase.contests?.length ?? 0) < game.config.minContestVotes) {
        decrementScore(game);
    }
    game.phrasePool.push(undoPhrase.phrase);
    return success();
}

/** Returns current phrase and the timestamp it was given */
export function changeTurns(
    game: FishbowlGame,
    userId: string,
    teamTurn: number,
    playerTurn: number
): Result<{
    previousPhrase: string;
    previousPhraseStartTime: number;
    currentClueGiverId: string;
    gameConfig: Immutable<FishbowlConfig>;
}> {
    if (!game.turnEndTime || game.round >= game.config.rounds.length) {
        return failure('Timer should be started and game not over');
    }
    if (game.teamTurn !== teamTurn || game.teams[teamTurn].playerTurn !== playerTurn) {
        return failure('Unexpected team turn or player turn');
    }
    if (game.currentPhraseStartMs === undefined) {
        return failure('Last clue giving timestamp should be defined');
    }
    const previousPhrase = getCurrentPhrase(game.phrasePool);
    if (previousPhrase === undefined) {
        return failure('A current phrase should be defined at time of changing turns');
    }
    const currentClueGiver = getCurrentClueGiver(game);
    if (currentClueGiver.id !== userId) {
        return failure(`Expected clue giver '${currentClueGiver.name}', but received '${game.players[userId]}'`);
    }
    returnSkippedPhrasesToPool(game);

    nextPlayerTurn(game);
    if (++game.teamTurn >= game.teams.length) {
        game.teamTurn = 0;
    }

    game.skipsUsed = 0;

    delete game.turnEndTime;
    const previousPhraseStartTime = game.currentPhraseStartMs;
    delete game.currentPhraseStartMs;

    return result({
        previousPhrase,
        previousPhraseStartTime,
        currentClueGiverId: currentClueGiver.id,
        gameConfig: game.config,
    });
}

export function skipPhrase(
    game: FishbowlGame,
    userId: string,
    turn: number,
    timeOffset: number,
    phrase: string
): Result<{ previousPhrase: string; previousPhraseStartTime: number; currentClueGiverId: string }> {
    if (game.turn !== turn) {
        return failure('Unexpected turn');
    }
    if (!game.phrasePool || game.phrasePool.length < 1) {
        return failure('No phrases in pool');
    }
    const currentClueGiver = getCurrentClueGiver(game);
    if (currentClueGiver.id !== userId) {
        return failure(`Expected clue giver '${currentClueGiver.name}', but received '${game.players[userId]}'`);
    }

    if (++game.skipsUsed > game.config.freeSkips) {
        const penalty = game.config.skipPenaltySeconds;
        if (penalty > 0 && game.turnEndTime) {
            game.turnEndTime -= penalty * 1000;
            if (game.turnEndTime < trueNow(timeOffset)) {
                // If turn is over, end the turn
                const playerTurn = game.teams[game.teamTurn].playerTurn;
                return changeTurns(game, currentClueGiver.id, game.teamTurn, playerTurn);
            }
        }
    }
    // Add skipped phrase to list of passed phrases
    const previousPhrase = game.phrasePool.pop() as string;
    if (phrase !== previousPhrase) {
        return failure('Skipped phrase is not the current phrase');
    }
    // TODO: undo will not undo skipped phrases
    game.skippedPhrases = [...(game.skippedPhrases ?? []), phrase];
    const previousPhraseStartTime = game.currentPhraseStartMs;
    if (previousPhraseStartTime === undefined) {
        return failure('Clue start time should be defined');
    }
    goNextPhrase(game, timeOffset);
    return result({ previousPhrase, previousPhraseStartTime, currentClueGiverId: currentClueGiver.id });
}

/** Returns whether or not this vote passed the contestion and the contested phrase. */
export function contestPhrase(
    game: FishbowlGame,
    userId: string,
    guessedPhraseIndex: number,
    turn: number
): Result<{ didContestPass: boolean; contestedPhrase: string; currentClueGiverId: string }> {
    if (game.turn !== turn) {
        return failure(`Expected turn ${game.turn}, but received turn ${turn}`);
    }
    const guessedPhrases = game.guessedPhrases ?? [];
    if (guessedPhraseIndex < 0 || guessedPhraseIndex >= guessedPhrases.length) {
        return failure('Invalid guessed phrase index');
    }
    const guessedPhrase = guessedPhrases[guessedPhraseIndex];
    if (guessedPhrase.contests?.some((id) => id === userId)) {
        return failure('Already contested for this user');
    }

    const currentClueGiver = getCurrentClueGiver(game);
    guessedPhrase.contests = (guessedPhrase.contests ?? []).concat([userId]);
    if (guessedPhrase.contests.length === game.config.minContestVotes) {
        // TODO: wrong team will lose point if contested after the turn!
        decrementScore(game);
        return result({
            didContestPass: true,
            contestedPhrase: guessedPhrase.phrase,
            currentClueGiverId: currentClueGiver.id,
        });
    }
    return result({
        didContestPass: false,
        contestedPhrase: guessedPhrase.phrase,
        currentClueGiverId: currentClueGiver.id,
    });
}
