import { KeyboardBackspace } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { CodenamesGame } from '@playtime/database/src';
import * as React from 'react';
import { SyncedComponentProps } from '../../hoc/sync';
import { BaseCodenamesProps } from '../../models/game';
import Logs from './GameContent/Logs/Logs';
import WordGrid from './GameContent/WordGrid';

const PreviousGame: React.FunctionComponent<
    BaseCodenamesProps &
        SyncedComponentProps<Pick<CodenamesGame, 'previousGame'>> & {
            setShowPreviousGame: (show: boolean) => void;
        }
> = (props) => {
    const [logTab, setLogTab] = React.useState<'gameLog' | 'gameResults'>('gameLog');
    if (!props.previousGame) return null;
    return (
        <>
            <Box height="54px">
                <Typography align="center" variant="h4">
                    Results of Last Game
                </Typography>
            </Box>
            {props.previousGame && (
                <Button onClick={() => props.setShowPreviousGame(false)}>
                    <KeyboardBackspace />
                    &nbsp; Back to Current Game
                </Button>
            )}
            <Box height="64px"></Box>

            <WordGrid
                words={props.previousGame.words}
                teams={props.previousGame.teams}
                {...props}
                teamTurn={undefined}
                isPreviousGame={true}
            />
            <Box height="24px"></Box>

            <Box display="flex" justifyContent="center">
                <Logs
                    gameLog={props.previousGame.gameLog}
                    words={props.previousGame.words}
                    {...props}
                    teams={props.previousGame.teams}
                    gameResults={props.previousGame.gameResults}
                    logTab={logTab}
                    setLogTab={setLogTab}
                />
            </Box>
        </>
    );
};

export default PreviousGame;
