import React, { useLayoutEffect, useRef, useState } from 'react';
import { Button, Chip, Grid, TextField, Typography } from '@mui/material';
import { Help } from '../../common/Help';
import { convertPhraseToKey } from '../../../back-end';
import { consolidateSpaces } from '../../../util/util';
import OutlinedLabel from '../../common/OutlinedLabel';
import { Result } from '@playtime/database';
import { makeStyles } from 'tss-react/mui';

export interface PhraseInputProps {
    allPhrases: { [key: string]: string } | undefined;
    phraseCount: number;
    enterPhrase: (phrase: string) => Promise<Result>;
    deletePhrase: (phraseId: string) => void;
}

const useStyles = makeStyles()(() => ({
    extraLargeButton: { responsiveFontSizes: '1rem', padding: '15px 31px', margin: '0 6px' },
    thickPaper: {
        borderRadius: '6px',
        padding: '12px',
        border: '1px solid rgba(0, 0, 0, 0.32)',
        position: 'relative',
    },
    progressLabel: {
        position: 'absolute',
        top: '-.7rem',
        left: 'calc(50% - 20px)',
        display: 'block',
        zIndex: 1,
        background: 'white',
        padding: '0 5px',
        margin: '0',
    },
}));

let latestEnteringPhraseText = '';
export const PhraseInput: React.FunctionComponent<PhraseInputProps> = (props) => {
    const phraseInputRef = useRef<HTMLDivElement>(null);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const { classes } = useStyles();
    const [phrase, setStatePhrase] = useState<string>(latestEnteringPhraseText);
    const setPhrase = (text: string) => {
        latestEnteringPhraseText = text;
        setStatePhrase(text);
    };
    const phraseKeys = Object.keys(props.allPhrases ?? {});

    useLayoutEffect(() => {
        phraseInputRef.current?.focus();
    });

    //validation
    const isPhraseTooShort = () => {
        return consolidateSpaces(phrase).length < 1;
    };
    const isPhraseDuplicate = () => {
        return phraseKeys.some((key) => key === convertPhraseToKey(phrase));
    };
    const hasError = () => {
        return isPhraseDuplicate() || isPhraseTooShort();
    };
    const helperText = () => {
        if (!isSubmitted) return '';
        if (isPhraseTooShort()) return 'Phrase too short!';
        if (isPhraseDuplicate()) return 'Duplicate phrase!';
        return "e.g. 'walk in the park'";
    };

    //actions
    const addPhrase = async () => {
        setIsSubmitted(true);
        if (hasError()) {
            return;
        }
        setPhrase('');
        setIsSubmitted(false);

        // TODO: could use result failure message
        await props.enterPhrase(phrase);
        phraseInputRef.current?.focus();
    };

    const checkForEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            addPhrase();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhrase(event.target.value);
    };

    return (
        <div>
            <Grid container justifyContent="center" style={{ marginBottom: '5px' }}>
                <Grid item>
                    <OutlinedLabel label={`${phraseKeys.length} / ${props.phraseCount}`}>
                        {phraseKeys.length === 0 ? (
                            <Typography>Start adding phrases</Typography>
                        ) : (
                            Object.values(props.allPhrases ?? {}).map((phraseValue) => (
                                <Chip
                                    label={phraseValue}
                                    onDelete={() => props.deletePhrase(phraseValue)}
                                    color="primary"
                                    style={{ margin: '0 2px' }}
                                    key={phraseValue}
                                />
                            ))
                        )}
                    </OutlinedLabel>
                </Grid>
                <Grid item style={{ marginRight: '-24px' }}>
                    <Help>The phrases you enter will have to be guessed by other players.</Help>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item>
                    <TextField
                        label="Word or Phrase"
                        name="phrase"
                        inputRef={phraseInputRef}
                        variant="outlined"
                        helperText={helperText()}
                        error={isSubmitted && hasError()}
                        value={phrase}
                        disabled={phraseKeys.length === props.phraseCount}
                        onChange={handleChange}
                        onKeyDown={checkForEnter}
                    ></TextField>
                </Grid>
                <Grid item>
                    <Button
                        onClick={addPhrase}
                        variant="outlined"
                        className={classes.extraLargeButton}
                        disabled={phraseKeys.length === props.phraseCount}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};
