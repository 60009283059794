import { Card, Typography } from '@mui/material';
import { LobbyGame } from '@playtime/database/src/model/lobby';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { activateGame } from '../../../back-end/api/lobby';
import { getUser } from '../../../store/auth/selectors';
import { SyncedComponentProps } from '../../hoc/sync';
import { getGameRoute } from '../../router/routing';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    gameName: {
        textAlign: 'center',
        minWidth: 0,
    },
    lobbyContainer: {
        margin: 10,
        padding: 6,
        cursor: 'pointer',
        webkitTouchCallout: 'none',
        webkitUserSelect: 'none',
        khtmlUserSelect: 'none',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    list: {
        listStyleType: 'none',
        paddingLeft: 0,
        marginTop: 0,
        textAlign: 'center',
    },
});

interface MyLobbyGameProps extends SyncedComponentProps<Pick<LobbyGame, 'name' | 'gameType' | 'players'>> {
    gameId: string;
}

const MyLobbyGame: React.FunctionComponent<MyLobbyGameProps> = (props) => {
    const { classes } = useStyles();
    const user = useSelector(getUser);
    const route = useHistory();
    if (!props.players) return null;
    const goToGame = async () => {
        await activateGame(user.uid, props.gameId);
        if (props.gameType === undefined || props.gameType === null) return;
        route.push(getGameRoute(props.gameType));
    };
    return (
        <Card elevation={3} className={classes.lobbyContainer} onClick={goToGame}>
            <Typography variant="h5" className={classes.gameName}>
                {props.name}
            </Typography>
            <ul className={classes.list}>
                {Object.entries(props.players).map(([id, player]) => {
                    return (
                        <li key={id}>
                            <Typography variant="caption">{player.displayName}</Typography>
                        </li>
                    );
                })}
            </ul>
        </Card>
    );
};

export default MyLobbyGame;
