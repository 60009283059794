import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import React from 'react';
import { passClueGiving } from '../../../back-end';
import { NotOptional } from '../../../util/util';
import GenericTurnCommands from '../../common/TurnCommands';
import { BaseFishbowlProps } from '../../models/game';

export interface TurnCommandsProps extends BaseFishbowlProps, NotOptional<Pick<FishbowlGame, 'turnEndTime' | 'turn'>> {
    phase: 'clueGiving' | 'guessing';
}

export const TurnCommands: React.FunctionComponent<TurnCommandsProps> = (props) => {
    if (props.turnEndTime !== undefined) {
        return null;
    }
    return (
        <GenericTurnCommands
            onNextTurn={() => passClueGiving(props.gameId, props.turn)}
            text={props.phase === 'clueGiving' ? 'Pass Turn' : 'Next turn'}
        />
    );
};
