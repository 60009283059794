import { CircularProgress, Typography } from '@mui/material';
import { CodenamesStats } from '@playtime/database/src/model/stats';
import { BarElement, Chart, BarController, LinearScale, CategoryScale } from 'chart.js';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { NotOptional } from '../../../util/util';
import { SyncedComponentProps } from '../../hoc/sync';
import StatCard from '../../Stats/StatCard';

const StreakDistribution: React.FunctionComponent<
    SyncedComponentProps<NotOptional<Pick<CodenamesStats, 'streaks'>>>
> = (props) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const [chart, setChart] = useState<Chart | null>(null);

    const getData = () => {
        if (props.streaks === null) return null;
        if (props.streaks === undefined || Object.keys(props.streaks).length === 0) return undefined;
        return {
            labels: Object.keys(props.streaks),
            data: Object.values(props.streaks).map((streak) => streak.count),
        };
    };

    useEffect(() => Chart.register(BarController, BarElement, LinearScale, CategoryScale), []);
    useEffect(() => {
        const context = canvasRef.current?.getContext('2d');
        const data = getData();
        if (canvasRef?.current && context && !chart && data) {
            setChart(
                new Chart(context, {
                    type: 'bar',
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true,
                            },
                        },
                    },

                    data: {
                        labels: data.labels,
                        datasets: [
                            {
                                label: 'My First Dataset',
                                data: data.data,
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(255, 159, 64, 0.2)',
                                    'rgba(255, 205, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(201, 203, 207, 0.2)',
                                ],
                                borderColor: [
                                    'rgb(255, 99, 132)',
                                    'rgb(255, 159, 64)',
                                    'rgb(255, 205, 86)',
                                    'rgb(75, 192, 192)',
                                    'rgb(54, 162, 235)',
                                    'rgb(153, 102, 255)',
                                    'rgb(201, 203, 207)',
                                ],
                                borderWidth: 1,
                            },
                        ],
                    },
                })
            );
        }
    }, [canvasRef.current, props.streaks]);

    const data = getData();

    return (
        <StatCard
            title="Streaks"
            caption="Streak Distribution"
            toolTip="The number of streaks while you were a clue giver. A streak is the number correctly guessed codenames from a single clue."
        >
            {data === null ? (
                <CircularProgress />
            ) : data === undefined ? (
                <Typography variant="h3" style={{ color: '#555' }}>
                    None
                </Typography>
            ) : (
                <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }}></canvas>
            )}
        </StatCard>
    );
};

export default StreakDistribution;
