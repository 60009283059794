import * as React from 'react';
import db from '../../../back-end/databases';
import InGame from '../../Codenames/Game/InGame';
import Lobby from '../../Codenames/Lobby/Lobby';
import { BaseLobbyProps } from '../../Lobby/baseLobbyProps';
import { BaseGameProps } from '../../models/game';
import { Game } from './Game';

const Codenames: React.FunctionComponent = () => {
    return (
        <Game
            InGame={InGame as React.FunctionComponent<BaseGameProps>}
            GameSpecificLobby={Lobby as React.FunctionComponent<BaseLobbyProps>}
            activeGameDb={db.codenamesGame}
        />
    );
};

export default Codenames;
