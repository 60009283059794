import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getTimeOffset, tryGetTimeOffset } from '../../back-end/api/timeSync';

export interface TimerProps extends CircularProgressProps {
    endTime: number | undefined;
    totalTimeInSeconds: number | undefined;
    onExpiration(): void;
}

const calcMsRemaining = (endTime: number | undefined, timeOffset: number) => {
    return endTime ? endTime + timeOffset - Date.now() : undefined;
};

export const Timer: React.FunctionComponent<TimerProps> = (props) => {
    const [msRemaining, setMsRemaining] = useState<number | undefined>(undefined);
    useEffect(() => {
        setMsRemaining(calcMsRemaining(props.endTime, tryGetTimeOffset()));
    }, [props.endTime, props.totalTimeInSeconds]);

    useEffect(() => {
        if (msRemaining === undefined) {
            return;
        }
        const timer = setTimeout(async () => {
            const newRemainingMs = calcMsRemaining(props.endTime, await getTimeOffset());
            if (!newRemainingMs || newRemainingMs < 0) {
                props.onExpiration();
                return;
            }
            setMsRemaining(newRemainingMs);
        }, 1000);
        return () => clearTimeout(timer);
    }, [msRemaining]);

    if (!props.endTime || !props.totalTimeInSeconds || !msRemaining) {
        return null;
    }

    const getPercentage = () => {
        if (msRemaining && props.totalTimeInSeconds) {
            return msRemaining / props.totalTimeInSeconds / 10;
        }
        return undefined;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { endTime, totalTimeInSeconds, onExpiration, ...circularProps } = props;

    const secRemaining = msRemaining && msRemaining > 0 ? Math.ceil(msRemaining / 1000) : 0;

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                style={{ margin: 'auto' }}
                value={getPercentage()}
                {...circularProps}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h5" component="div">
                    {secRemaining}
                </Typography>
            </Box>
        </Box>
    );
};
