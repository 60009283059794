import { mergeResults, inTranDefault, success } from '@playtime/database';
import { convertPhraseToKey } from '../../business/pure/pure';
import db from '../../databases';

const defaultPhrase = (phrase: string) => ({
    phrase,
    count: 0,
    passedCount: 0,
    guessedCount: 0,
    timeTaken: 0,
    contestCount: 0,
});

export async function savePhrases(phrases: string[]) {
    return mergeResults(
        await Promise.all(
            phrases.map((phrase) => {
                const phraseKey = convertPhraseToKey(phrase);
                return inTranDefault(
                    db.phraseCollection,
                    phraseKey,
                    (phraseData) => {
                        phraseData.count++;
                        return success();
                    },
                    defaultPhrase(phrase)
                );
            })
        )
    );
}

export async function passed(phrase: string, timeTaken: number) {
    const phraseKey = convertPhraseToKey(phrase);
    return await inTranDefault(
        db.phraseCollection,
        phraseKey,
        (phraseData) => {
            phraseData.passedCount++;
            phraseData.timeTaken += timeTaken;
            return success();
        },
        defaultPhrase(phrase)
    );
}

export async function guessed(phrase: string, timeTaken: number) {
    const phraseKey = convertPhraseToKey(phrase);
    return await inTranDefault(
        db.phraseCollection,
        phraseKey,
        (phraseData) => {
            phraseData.guessedCount++;
            phraseData.timeTaken += timeTaken;
            return success();
        },
        defaultPhrase(phrase)
    );
}

export async function contested(phrase: string) {
    const phraseKey = convertPhraseToKey(phrase);
    return await inTranDefault(
        db.phraseCollection,
        phraseKey,
        (phraseData) => {
            phraseData.contestCount++;
            return success();
        },
        defaultPhrase(phrase)
    );
}

export async function timeTook(phrase: string, timeTaken: number) {
    const phraseKey = convertPhraseToKey(phrase);
    return await inTranDefault(
        db.phraseCollection,
        phraseKey,
        (phraseData) => {
            phraseData.timeTaken += timeTaken;
            return success();
        },
        defaultPhrase(phrase)
    );
}
