import { FishbowlStats } from '@playtime/database/src/model/stats';
import * as React from 'react';
import { SyncedComponentProps } from '../../hoc/sync';
import NumberStat from '../../Stats/NumberStat';

const Skips: React.FunctionComponent<SyncedComponentProps<Pick<FishbowlStats, 'guessedCount' | 'passedCount'>>> = (
    props
) => {
    const skipsPerClueGiven = () => {
        if (props.passedCount === null || props.guessedCount === null) return null;
        if (props.passedCount === undefined || !props.guessedCount) return 'None';
        if (props.passedCount === 0) return 0;
        return (props.passedCount / props.guessedCount).toFixed(2);
    };
    return (
        <NumberStat
            title="Skips"
            caption="Skips / Clue"
            number={skipsPerClueGiven()}
            toolTip="Average number of times you skipped per clues you gave"
        />
    );
};

export default Skips;
