import { DatabaseReference } from 'firebase/database';
import { Database } from '..';
import { FirebaseDatabase } from '../core';
import { JoinableActiveGame } from '../model/joinableActiveGame';
import { Reference } from '@firebase/database-types';

export function getJoinableActiveGameDb(
    getDbRef: () => DatabaseReference | Reference
): Database<JoinableActiveGame, string> {
    return new FirebaseDatabase<JoinableActiveGame, string>(getDbRef, 'joinableActiveGames');
}
