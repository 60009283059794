import { FishbowlStats } from '@playtime/database/src/model/stats';
import * as React from 'react';
import { SyncedComponentProps } from '../../hoc/sync';
import NumberStat from '../../Stats/NumberStat';

const Contested: React.FunctionComponent<
    SyncedComponentProps<Pick<FishbowlStats, 'guessedCount' | 'contestedByOthersCount'>>
> = (props) => {
    const contestedPerClueGiven = () => {
        if (props.contestedByOthersCount === null || props.guessedCount === null) return null;
        if (props.contestedByOthersCount === undefined || !props.guessedCount) return 'None';
        if (props.contestedByOthersCount === 0) return 0;
        return (props.contestedByOthersCount / props.guessedCount).toFixed(2);
    };
    return (
        <NumberStat
            title="Contested"
            caption="Contested / Clue"
            number={contestedPerClueGiven()}
            toolTip="Average number of times others contested you per clues you gave"
        />
    );
};

export default Contested;
