import { Box } from '@mui/material';
import React from 'react';
import { Sync, SyncedComponentProp, syncProp } from '../hoc/sync';
import { PlayerList } from './PlayerList';
import { BaseLobbyProps } from './baseLobbyProps';
import { GameConfigRow } from './GameConfig/ConfigRow';
import db from '../../back-end/databases';
import { CodenamesConfig } from '@playtime/database/src/model/codenames';

interface LobbyProps extends BaseLobbyProps {
    GameSpecificLobby: React.FunctionComponent<
        BaseLobbyProps & SyncedComponentProp<Pick<CodenamesConfig, 'sendNotifications'>>
    >;
}

export const Lobby: React.FunctionComponent<LobbyProps> = (props) => {
    const SyncedPlayerList = Sync(PlayerList);
    const SyncedGameConfigRow = Sync(GameConfigRow);
    const GameSpecificLobby = props.GameSpecificLobby;
    const SyncedGameSpecificLobby = Sync(GameSpecificLobby);

    const syncPlayersProp = syncProp(db.lobbyGame, props.gameId, 'players');
    const syncConfigProp = syncProp(db.lobbyGame, props.gameId, 'config');
    const syncHostProp = syncProp(db.lobbyGame, props.gameId, 'host');
    const syncPlayersPhrasesProp = syncConfigProp.prop('phrasesPerPlayer');
    const syncSendNotificationsProp = syncConfigProp.prop('sendNotifications');

    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="start">
            <SyncedGameSpecificLobby {...props} sendNotifications={syncSendNotificationsProp} />
            <br />
            <SyncedGameConfigRow {...props} host={syncHostProp} gameType={props.initialGameState.gameType} />
            <SyncedPlayerList
                {...props}
                host={syncHostProp}
                players={syncPlayersProp}
                phraseCount={syncPlayersPhrasesProp}
            />
        </Box>
    );
};
