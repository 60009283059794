import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    gameConfigTextInput: {
        width: '210px',
    },
    help: {
        paddingLeft: 10,
    },
});
