import { FirebaseApp } from '@firebase/app';
import assert from 'assert';
import store, { IStore } from '..';

export const getDatabaseURL = (state: IStore): string => {
    assert(state.appSettings.databaseURL, 'no databaseURL set in appSettings.json');
    return state.appSettings.databaseURL;
};

export const getIsDebugEnabled = (state: IStore): boolean => {
    assert(state.appSettings.debugEnabled, 'no debugEnabled set in appSettings.json');
    return state.appSettings.debugEnabled;
};

export const getEarlyBackendUri = (): string | undefined => {
    const state = store.store.getState();
    return state.appSettings.backendUri ?? undefined;
};

export const getBackendUri = (state: IStore): string => {
    assert(state.appSettings.backendUri, 'no backendUri set in appSettings.json');
    return state.appSettings.backendUri;
};

export const getVapidKey = (state: IStore): string => {
    assert(state.appSettings.vapidKey, 'no vapidKey set in appSettings.json');
    return state.appSettings.vapidKey;
};

export const isFirebaseLoaded = (state: IStore): boolean => {
    return state.appSettings.firebaseApp !== null;
};

export const getFirebaseApp = (state: IStore): FirebaseApp => {
    assert(state.appSettings.firebaseApp, "firebase hasn't loaded  yet");
    return state.appSettings.firebaseApp;
};
