import { Result, success, failure } from '@playtime/database';
import { CodenamesGame } from '@playtime/database/src/model/codenames';
import { LobbyGame, GameType } from '@playtime/database/src/model/lobby';

export function activateCodenamesGame(
    games: { lobbyGame?: LobbyGame<GameType.Codenames>; activeGame?: CodenamesGame },
    teams: CodenamesGame['teams']
): Result {
    const gameToStart = games.lobbyGame;
    if (gameToStart === undefined) {
        return failure('Game to start does not exist');
    }
    if (games.activeGame !== undefined) {
        return failure('Active game with same id already exists');
    }

    const playerList = Object.entries(gameToStart.players ?? {});
    const players: CodenamesGame['players'] = Object.fromEntries(
        playerList.map(([id, player]) => [id, { ...player, isActive: true }])
    );
    if (!players) {
        return failure('Game to start has no players');
    }

    // Assign teams
    const numTeams = gameToStart.config.numberOfTeams;
    if (numTeams < 1) {
        return failure('Expected positive number of teams');
    }

    if (playerList.length < numTeams) return failure('Fewer players than teams');
    if (!games.lobbyGame?.config.numberOfTeams) return failure('Number of teams config not set');

    // Initialize active game
    const game: CodenamesGame = {
        name: gameToStart.name,
        host: gameToStart.host,
        config: gameToStart.config,
        gameType: GameType.Codenames,
        words: {},
        players,
        teamTurn: 0,
        teams,
        gameLog: [],
        clueGiven: false,
        turn: 0,
        streak: [],
    };

    // Move game from lobby to active
    delete games.lobbyGame;
    games.activeGame = game;
    return success();
}
