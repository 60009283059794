import React from 'react';
import db from '../../../back-end/databases';
import { InGame } from '../../Fishbowl/Game/InGame';
import Lobby from '../../Fishbowl/Lobby/Lobby';
import { BaseGameProps } from '../../models/game';
import { Game } from './Game';

/** Rerenders only when user joins/leaves a game or a game starts/ends. */
export const Fishbowl: React.FunctionComponent = () => {
    return (
        <Game
            InGame={InGame as React.FunctionComponent<BaseGameProps>}
            GameSpecificLobby={Lobby}
            activeGameDb={db.fishbowlGame}
        />
    );
};
