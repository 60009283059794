import { TextField } from '@mui/material';
import { ConfigType } from '@playtime/database/src/model/lobby';
import * as React from 'react';
import { Help } from '../../../common/Help';
import { ConfigSettingsProps } from './ConfigSetting';
import { useStyles } from './styles';

const NumericConfig: React.FunctionComponent<ConfigSettingsProps<ConfigType.Numeric>> = (props) => {
    const { classes } = useStyles();

    const minValidation = (val: number) => props.minimum > val;
    const minValidationText = minValidation(props.value) && `Must be greater than ${props.minimum - 1}!`;

    const requiredValidation = (val: number) => isNaN(val);
    const requiredValidationText = requiredValidation(props.value) && `${props.name} is required`;

    const errorText = props.isDirty && (minValidationText || requiredValidationText);

    const onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
        const value = e.target.value ? Number(e.target.value) : NaN;
        props.onChange({
            [props.property]: {
                value,
                hasError: requiredValidation(value) || minValidation(value),
            },
        });
    };

    return (
        <>
            <TextField
                label={props.name}
                variant="outlined"
                type="number"
                size="small"
                required
                className={classes.gameConfigTextInput}
                value={isNaN(props.value) ? '' : props.value}
                error={!!errorText}
                helperText={errorText}
                onChange={onChange}
            />
            <Help placement="top" className={classes.help}>
                {props.tooltip}
            </Help>
        </>
    );
};

export default NumericConfig;
