import { MoveUp } from '@mui/icons-material';
import { Button } from '@mui/material';
import { CodenamesGame } from '@playtime/database/src';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { registerForGameNotifications } from '../../../back-end/api/messaging';
import db from '../../../back-end/databases';
import { getUser } from '../../../store/auth/selectors';
import { Sync, syncDataItem } from '../../hoc/sync';
import { BaseCodenamesProps } from '../../models/game';
import GameContent from './GameContent/GameContent';
import GameHeader from './GameHeader/GameHeader';
import PreviousGame from './PreviousGame';

const InGame: React.FunctionComponent<BaseCodenamesProps> = (props) => {
    const user = useSelector(getUser);
    const [showPreviousGame, setShowPreviousGame] = React.useState<boolean>(false);
    const [previousGame, setPreviousGame] = React.useState<CodenamesGame['previousGame'] | undefined>(undefined);
    React.useEffect(() => db.codenamesGame.property(props.gameId, 'previousGame').listen(setPreviousGame).stop, []);
    React.useEffect(() => {
        const setupNotifications = async () => {
            if (!props.initialGameState.config.sendNotifications) return;
            await registerForGameNotifications(user.uid);
        };
        setupNotifications();
    }, [props.initialGameState.config.sendNotifications]);
    const syncCodenames = syncDataItem(db.codenamesGame, props.gameId);
    const SyncGameContent = Sync(GameContent);
    const SyncGameHeader = Sync(GameHeader);
    const SyncPreviousGame = Sync(PreviousGame);

    return showPreviousGame && previousGame ? (
        <SyncPreviousGame
            previousGame={syncCodenames.prop('previousGame')}
            setShowPreviousGame={setShowPreviousGame}
            initialGameState={props.initialGameState}
            gameId={props.gameId}
        />
    ) : (
        <>
            <SyncGameHeader {...props} {...syncCodenames.props('teams', 'players', 'teamTurn', 'words', 'clueGiven')} />
            {previousGame && (
                <Button onClick={() => setShowPreviousGame(!showPreviousGame)}>
                    <MoveUp />
                    &nbsp; Show Last Game Results
                </Button>
            )}
            <br />
            <SyncGameContent
                {...syncCodenames.props('teams', 'teamTurn', 'words', 'clueGiven', 'gameLog')}
                {...props}
            />
        </>
    );
};

export default InGame;
