import * as React from 'react';
import Redeemable from './Redeemable';
import { PremiumTeam, premiumTeams } from '../../back-end';
import { ScoreBanner } from '../common/ScoreBanner/ScoreBanner';
import { PlayerStats, StatsType, Team } from '@playtime/database/src';
import ConfirmPurchase from './ConfirmPurchase';
import { getUser } from '../../store/auth/selectors';
import { useSelector } from 'react-redux';
import { SyncedComponentProp } from '../hoc/sync';
import { Box, Typography } from '@mui/material';
import { buyTeamBanner } from '../../back-end/api/store';

type RedeemableData = {
    rating?: number;
    cost: number;
} & {
    type: 'teamColor';
    prefix: string;
    primaryColor: [number, number, number];
    secondaryColor?: [number, number, number];
    darkFont?: boolean;
};
const redeemables: RedeemableData[] = Object.values(premiumTeams).map((premiumTeam) => ({
    type: 'teamColor',
    ...premiumTeam,
}));

interface RedeemablesProps {
    fishbowlElo: SyncedComponentProp<PlayerStats[StatsType.Fishbowl]['elo']>;
    codenamesElo: SyncedComponentProp<PlayerStats[StatsType.Fishbowl]['elo']>;
}

const Redeemables: React.FunctionComponent<RedeemablesProps> = (props) => {
    const [state, setState] = React.useState<
        | { showConfirmation: true; banner: Pick<PremiumTeam, 'cost' | 'prefix' | 'rating'> }
        | { showConfirmation: false }
    >({ showConfirmation: false });
    const user = useSelector(getUser);
    const onPurchase = () => {
        if (state.showConfirmation) buyTeamBanner(user.uid, state.banner.prefix);
        setState({ showConfirmation: false });
    };
    const initiatePurchase = (banner: PremiumTeam) => {
        setState({ showConfirmation: true, banner });
    };
    const onCancel = () => {
        setState({ showConfirmation: false });
    };
    const maxUserRating = Math.max(props.fishbowlElo ?? 0, props.codenamesElo ?? 0);
    return (
        <>
            {redeemables.map((redeemable, ix) => {
                const mockTeam: Team & { scoreDisplay: number } = {
                    scoreDisplay: ix + 1,
                    scores: [],
                    playerTurn: 0,
                    players: [],
                    suffix: '',
                    ...redeemable,
                };
                return (
                    <Redeemable
                        key={redeemable.prefix}
                        alreadyOwned={(user.inventory?.teamBanners ?? []).some(
                            (ownedBanner) => redeemable.prefix === ownedBanner.name
                        )}
                        onBuy={initiatePurchase}
                        banner={redeemable}
                    >
                        <ScoreBanner
                            side={'left'}
                            teamName={redeemable.prefix}
                            team={mockTeam}
                            TeamRoster={undefined}
                        />
                    </Redeemable>
                );
            })}
            {user.starDust && (
                <ConfirmPurchase
                    showDialog={state.showConfirmation}
                    userStarDust={user.starDust.current}
                    userRating={maxUserRating}
                    banner={state.showConfirmation ? state.banner : undefined}
                    onPurchase={onPurchase}
                    onCancel={onCancel}
                >
                    {state.showConfirmation && (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box>
                                <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {state.banner.prefix} for
                                    <Typography
                                        variant="overline"
                                        sx={{ fontStyle: 'italic', marginTop: '4px', marginLeft: '2px' }}
                                    >
                                        {state.banner.cost}
                                    </Typography>
                                    <img
                                        src="./star-dust.png"
                                        color="white"
                                        alt=""
                                        width="18"
                                        height="18"
                                        style={{ marginTop: 'auto', marginBottom: 'auto' }}
                                    />
                                </Typography>
                            </Box>
                            <Box>
                                <Typography>Confirm Purchase?</Typography>
                            </Box>
                        </Box>
                    )}
                </ConfirmPurchase>
            )}
        </>
    );
};

export default Redeemables;
