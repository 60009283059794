import { Box } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ScoreBanner, ScoreBannerTeamRosterProps } from './ScoreBanner';
import { makeStyles } from 'tss-react/mui';
import { getTeamForPlayer, getTeamName } from '../../../back-end';
import { getUser } from '../../../store/auth/selectors';
import { SyncedComponentProp } from '../../hoc/sync';
import { BaseGameProps } from '../../models/game';
import { Team } from '@playtime/database/src';

const useStyles = makeStyles()({
    vs: {
        fontSize: '1.2rem',
        fontWeight: 600,
        paddingTop: '3px',
        width: '10%',
        minWidth: '26px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'center',
    },
});

interface ScoreBannersProps extends BaseGameProps {
    gameId: string;
    roundIcons?: string[];
    teams: SyncedComponentProp<(Team & { scoreDisplay: number })[]>;
    TeamRoster: React.FunctionComponent<
        ScoreBannerTeamRosterProps & {
            gameId: string;
        }
    >;
}

const ScoreBanners: React.FunctionComponent<ScoreBannersProps> = (props) => {
    const { classes } = useStyles();
    const user = useSelector(getUser);

    if (!props.teams) return null;
    const myTeam = getTeamForPlayer(props.teams, user.uid);
    if (!myTeam.team) return null;

    return (
        <Box justifyContent="space-between" sx={{ width: '106%', marginLeft: '-3%' }} display="flex">
            <Box flexGrow={1}>
                <ScoreBanner
                    gameId={props.gameId}
                    TeamRoster={props.TeamRoster}
                    roundIcons={props.roundIcons}
                    side={'left'}
                    teamName={getTeamName(myTeam.team)}
                    team={myTeam.team}
                />
            </Box>
            {props.teams.length <= 1 ? null : <Box className={classes.vs}>vs</Box>}
            <Box flexGrow={1}>
                {props.teams.map((t, i) =>
                    i === myTeam.index ? null : (
                        <ScoreBanner
                            gameId={props.gameId}
                            key={getTeamName(t)}
                            TeamRoster={props.TeamRoster}
                            roundIcons={props.roundIcons}
                            side={'right'}
                            teamName={getTeamName(t)}
                            team={t}
                        />
                    )
                )}
            </Box>
        </Box>
    );
};

export default ScoreBanners;
