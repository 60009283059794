import { applyMiddleware, combineReducers, createStore, compose, Action, StoreEnhancer } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';
// Import reducers and state type
import auth from './auth/reducer';
import appSettings from './appSettings/reducer';
import game from './game/reducer';
import devTools from './devTools/reducer';

export type IAction<T> = T & {
    type: string;
} & Action;

const rootReducer = combineReducers({
    auth,
    appSettings,
    game,
    devTools,
});

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['auth', 'game'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

type MightHaveReduxDevTools = typeof window & {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(a: StoreEnhancer<R>) => StoreEnhancer<R>;
};

const composeEnhancers = (window as MightHaveReduxDevTools).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const _store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
const persistor = persistStore(_store);
const store = { store: _store, persistor };
export default store;
export type IStore = ReturnType<typeof _store.getState>;
