import { shuffleTake } from '../../../util/util';
import db from '../../databases';

export async function getRandomWords(count: number, excludeWords?: string[]) {
    const words = await db.codenamesWordCollection.getAll();
    if (excludeWords) {
        for (const excludeWord of excludeWords) {
            delete words[excludeWord];
        }
    }
    return shuffleTake(Object.keys(words), count);
}
