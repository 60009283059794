import { Button, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { getUser } from '../../../../store/auth/selectors';
import { useSelector } from 'react-redux';
import { leaveActiveGame, restartFishbowl } from '../../../../back-end';
import { Box } from '@mui/material';
import { BaseFishbowlProps } from '../../../models/game';
import { GameType } from '@playtime/database/src/model/lobby';

export const GameOver: FunctionComponent<BaseFishbowlProps> = (props) => {
    const user = useSelector(getUser);
    const localRestartGame = () => restartFishbowl(props.gameId);
    const localLeaveGame = () => leaveActiveGame(user.uid, props.gameId, GameType.Fishbowl);

    return props.initialGameState.host === user.uid ? (
        <div style={{ textAlign: 'center' }}>
            <Box display="inline-flex" alignItems="center" flexDirection="column">
                <Button color="primary" variant="outlined" onClick={localRestartGame}>
                    Restart Game
                </Button>
                <br />
                <Button color="secondary" variant="outlined" style={{ width: '100%' }} onClick={localLeaveGame}>
                    Leave Game
                </Button>
            </Box>
        </div>
    ) : (
        <Box display="flex" alignItems="center" flexDirection="column">
            <Typography variant="subtitle2" style={{ paddingBottom: 10 }}>
                Wait for host to restart game or
            </Typography>
            <Button color="secondary" variant="outlined" onClick={localLeaveGame}>
                Leave Game
            </Button>
        </Box>
    );
};
