import { CircularProgress, Typography } from '@mui/material';
import * as React from 'react';
import { SyncedComponentProp } from '../hoc/sync';
import StatCard, { StatCardProps } from './StatCard';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    numberFont: {
        color: '#555',
        fontSize: '2.8rem',
        textAlign: 'center',
    },
});

export interface NumberStatProps extends StatCardProps {
    number: SyncedComponentProp<number | string>;
}

const NumberStat: React.FunctionComponent<NumberStatProps> = (props) => {
    const { classes } = useStyles();
    const { number, ...statCardProps } = props;
    if (number === undefined) return null;
    return (
        <StatCard {...statCardProps}>
            <Typography variant="h3" className={classes.numberFont}>
                {number === null ? <CircularProgress /> : number}
            </Typography>
        </StatCard>
    );
};

export default NumberStat;
