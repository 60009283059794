import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface PrivateRoute {
    exact?: boolean;
    path?: string | string[];
}

const PrivateRoute: React.FunctionComponent<React.PropsWithChildren<PrivateRoute>> = ({ children, ...rest }) => {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                window.localStorage.getItem('logged-in') === 'true' ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
