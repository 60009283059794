import * as types from './types';
import store from '..';
import axios from 'axios';
import { AppSettings } from './reducer';
import { FirebaseApp } from '@firebase/app';

export const fetchAppSettings = async (): Promise<AppSettings> => {
    const appSettings = (await axios.get('/appsettings.json')).data;
    store.store.dispatch({
        type: types.FETCH_APPSETTINGS,
        appSettings,
    });
    return appSettings;
};

export const setFirebaseApp = (firebaseApp: FirebaseApp) => {
    store.store.dispatch({
        type: types.SET_FIREBASE_APP,
        firebaseApp,
    });
};
