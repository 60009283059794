import {
    ConfigType,
    GameConfig,
    GameType,
    NumericGameConfigSetting,
    BooleanGameConfigSetting,
    GameConfigSetting,
} from '@playtime/database/src/model/lobby';
import * as React from 'react';
import { ConfigState } from '../ConfigButton';
import BooleanConfig from './BooleanConfig';
import NumericConfig from './NumericConfig';

interface ConfigProps<
    T extends ConfigType,
    V = T extends ConfigType.Numeric ? number : T extends ConfigType.Boolean ? boolean : never
> {
    property: keyof GameConfig<GameType>;
    value: V;
    isDirty: boolean;
    onChange(delta: Partial<ConfigState>): void;
}
type ConfigSettings<T extends ConfigType> = T extends ConfigType.Numeric
    ? NumericGameConfigSetting
    : T extends ConfigType.Boolean
    ? BooleanGameConfigSetting
    : GameConfigSetting;
export type ConfigSettingsProps<T extends ConfigType> = ConfigSettings<T> & ConfigProps<T>;

function isNumericConfigProps(
    config: ConfigSettingsProps<ConfigType>
): config is ConfigSettingsProps<ConfigType.Numeric> {
    return config.configType === ConfigType.Numeric;
}

function isBooleanConfigProps(
    config: ConfigSettingsProps<ConfigType>
): config is ConfigSettingsProps<ConfigType.Boolean> {
    return config.configType === ConfigType.Boolean;
}

function ConfigSetting(props: ConfigSettingsProps<ConfigType>) {
    if (isNumericConfigProps(props)) return <NumericConfig {...props} />;
    else if (isBooleanConfigProps(props)) return <BooleanConfig {...props} />;
    return null;
}

export default ConfigSetting;
