import { Box, Button, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { CodenamesGame, Clue } from '@playtime/database/src/model/codenames';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { giveClue } from '../../../../back-end/api/codenames/gameplay';
import { getUser } from '../../../../store/auth/selectors';
import { getUserDisplayName } from '../../../../util/user';
import { SyncedComponentProps } from '../../../hoc/sync';
import { BaseCodenamesProps } from '../../../models/game';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    clue: {
        width: '140px',
    },
    count: {
        width: '105px',
    },
    giveClue: {
        marginTop: '8px',
        marginBottom: '4px',
        height: '40px',
    },
});

const ClueGiving: React.FunctionComponent<
    SyncedComponentProps<Pick<CodenamesGame, 'teamTurn'>> & BaseCodenamesProps
> = (props) => {
    // state initialization ssd
    const user = useSelector(getUser);
    const [clue, setClue] = useState('');
    const [count, setCount] = useState('');
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const { classes } = useStyles();
    const clueCounts = ['∞'];
    for (let i = 0; i <= props.initialGameState.config.numberOfClues + 1; i++) clueCounts.push(i.toString());

    // validation
    const clueNotGiven = () => !clue.length || clue.length === 0;
    const clueTooShort = () => clue.length < 2;
    const clueErrorText = () => {
        if (clueNotGiven()) return 'Required';
        if (clueTooShort()) return 'Too short';
        return null;
    };
    const countNotGiven = () => !count.length || count.length === 0;
    const countErrorText = () => {
        if (countNotGiven()) return 'Required';
        return null;
    };
    const formHasError = () => !!clueErrorText() || !!countErrorText();

    // submission
    const localGiveClue = () => {
        setIsSubmitted(true);
        if (formHasError() || props.teamTurn === null || props.teamTurn === undefined) return;
        giveClue(
            props.gameId,
            clue,
            count as Clue['count'],
            getUserDisplayName(user),
            props.teamTurn,
            props.initialGameState.config.secondsPerTurn > 0
        );
    };

    // form handling
    const clueChange = (event: React.ChangeEvent<HTMLInputElement>) => setClue(event.target.value);
    const countChange = (_: React.SyntheticEvent<Element, Event>, value: string | null) => value && setCount(value);
    const checkForEnter = (event: React.KeyboardEvent<HTMLDivElement>) => event.key === 'Enter' && localGiveClue();

    return (
        <Box display="inline-flex" mt={1}>
            <TextField
                className={classes.clue}
                margin="dense"
                size="small"
                label="Clue"
                color="primary"
                variant="outlined"
                helperText={isSubmitted && clueErrorText()}
                error={isSubmitted && !!clueErrorText()}
                value={clue}
                onChange={clueChange}
                onKeyDown={checkForEnter}
            />
            <Box px={1} className={classes.count}>
                <Autocomplete
                    size="small"
                    options={clueCounts}
                    onChange={countChange}
                    renderInput={(params) => (
                        <TextField
                            margin="dense"
                            {...params}
                            label="Count"
                            variant="outlined"
                            helperText={isSubmitted && countErrorText()}
                            error={isSubmitted && !!countErrorText()}
                            value={clue}
                            onKeyDown={checkForEnter}
                        />
                    )}
                />
            </Box>
            <Button className={classes.giveClue} color="secondary" variant="outlined" onClick={localGiveClue}>
                Give Clue
            </Button>
        </Box>
    );
};

export default ClueGiving;
