import { Check, ImportExport } from '@mui/icons-material';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    ClickAwayListener,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import * as React from 'react';
import { PremiumTeam } from '../../back-end';
import { green } from '@mui/material/colors';

interface RedeemableProps {
    banner: Pick<PremiumTeam, 'cost' | 'prefix' | 'rating'>;
    alreadyOwned: boolean;
    onBuy(banner: Pick<PremiumTeam, 'cost' | 'prefix' | 'rating'>): void;
}

const Redeemable: React.FunctionComponent<React.PropsWithChildren<RedeemableProps>> = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const alreadyOwnedDisplay = (
        <Typography variant="body2" color={green[600]} sx={{ fontStyle: 'italic', display: 'flex', alignItems: 'end' }}>
            Owned&nbsp;
            <Check />
        </Typography>
    );
    const purchaseDisplay = (
        <>
            {props.banner.rating && (
                <ClickAwayListener onClickAway={handleClose}>
                    <Tooltip
                        sx={{ whiteSpace: 'pre-line' }}
                        title={
                            <Typography>
                                This team banner requires a {props.banner.rating} rating threshold. Buying it won&apos;t
                                change your rating.
                            </Typography>
                        }
                        open={open}
                        onClose={handleClose}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                    >
                        <IconButton
                            onClick={handleClick}
                            sx={{
                                color: 'inherit',
                                paddingRight: '2px',
                            }}
                        >
                            <Typography variant="overline" sx={{ fontStyle: 'italic' }}>
                                {props.banner.rating}
                            </Typography>
                            <ImportExport fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </ClickAwayListener>
            )}
            <Typography variant="overline" sx={{ fontStyle: 'italic', marginLeft: '0 !important' }}>
                {props.banner.cost}
            </Typography>
            <img src="./star-dust.png" color="white" alt="" width="18" height="18" />
            <Button
                onClick={() => props.onBuy(props.banner)}
                sx={{ marginLeft: '5px' }}
                size="small"
                variant="outlined"
                color="secondary"
            >
                Buy
            </Button>
        </>
    );
    return (
        <Card raised sx={{ marginTop: '10px' }}>
            <CardContent sx={{ paddingBottom: 0 }}>{props.children}</CardContent>
            <CardActions sx={{ justifyContent: 'end', paddingTop: 0 }}>
                {props.alreadyOwned ? alreadyOwnedDisplay : purchaseDisplay}
            </CardActions>
        </Card>
    );
};

export default Redeemable;
