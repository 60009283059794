import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { fetchAppSettings, setFirebaseApp } from '../store/appSettings/actions';
import { setFirebaseUser, logout, setFirebaseAuth } from '../store/auth/actions';
import { setSystemAppMode, setSystemDbMode } from '../store/devTools/actions';
import { getAnalytics } from 'firebase/analytics';

const setupFirebase = async () => {
    const appSettings = await fetchAppSettings();

    const firebaseConfig = {
        databaseURL: appSettings.databaseURL ?? '',
        apiKey: 'AIzaSyCnHc0mvZqlxsr-gb_i0JgBH1FOM7rHubI',
        authDomain: 'play-time-afe62.firebaseapp.com',
        projectId: 'play-time-afe62',
        storageBucket: 'play-time-afe62.appspot.com',
        messagingSenderId: '749599797085',
        appId: '1:749599797085:web:7c9ff25c2fdc865b7502b4',
        measurementId: 'G-YG1FMTYJLR',
    };
    const firebaseApp = initializeApp(firebaseConfig);
    // TODO: add google analytics
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const analytics = getAnalytics(firebaseApp);

    const auth = await getAuth(firebaseApp);
    onAuthStateChanged(auth, function (user) {
        if (user) setFirebaseUser(user);
        else logout();
    });
    setSystemAppMode(appSettings.debugEnabled ? 'dev' : 'prod');
    setSystemDbMode(appSettings.debugEnabled ? 'dev' : 'prod');
    setFirebaseApp(firebaseApp);
    setFirebaseAuth(auth);
};

setupFirebase().catch(console.log);
