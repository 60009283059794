import * as React from 'react';
import { getGameDb } from '../../../back-end';
import { Sync, syncDataItem, SyncedComponentProps, SyncPropEvents } from '../../hoc/sync';
import { Box } from '@mui/material';
import OutlinedLabel from '../../common/OutlinedLabel';
import MyGame from './MyGame';
import MyLobbyGame from './MyLobbyGame';
import db from '../../../back-end/databases';
import { CodenamesGame } from '@playtime/database/src/model/codenames';
import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import { User, LobbyGameType, GameType } from '@playtime/database/src/model/lobby';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    gameGrid: { marginTop: 15, width: '100%' },
});

const MyGames: React.FunctionComponent<SyncedComponentProps<Pick<User, 'games'>>> = (props) => {
    const { classes } = useStyles();
    if (!props.games?.length) return null;

    const SyncMyLobbyGame = Sync(MyLobbyGame);
    const SyncMyGame = Sync(MyGame);
    const lobbyGames = props.games.filter((game) => game.type === LobbyGameType.Lobby);
    const activeGames = props.games.filter((game) => game.type !== LobbyGameType.Lobby);

    return (
        <Box width="100%">
            {!!lobbyGames.length && (
                <OutlinedLabel label="My Lobby Games" className={classes.gameGrid}>
                    {lobbyGames.map((lobbyGame) => {
                        const syncedLobbyGame = syncDataItem(db.lobbyGame, lobbyGame.id);
                        return (
                            <SyncMyLobbyGame
                                key={lobbyGame.id}
                                gameId={lobbyGame.id}
                                {...syncedLobbyGame.props('players', 'name', 'gameType')}
                            />
                        );
                    })}
                </OutlinedLabel>
            )}
            {!!activeGames.length && (
                <>
                    <br />
                    <OutlinedLabel label="My Active Games" className={classes.gameGrid}>
                        {activeGames.map((game) => {
                            const gameDb = getGameDb(game.type as GameType);
                            const syncedGame = syncDataItem<CodenamesGame | FishbowlGame, string>(gameDb, game.id);
                            return (
                                <SyncMyGame
                                    key={game.id}
                                    gameId={game.id}
                                    {...syncedGame.props('teams', 'players', 'name', 'gameType', 'teamTurn')}
                                    {...(syncedGame as SyncPropEvents<CodenamesGame>).props('clueGiven', 'words')}
                                />
                            );
                        })}
                    </OutlinedLabel>
                </>
            )}
        </Box>
    );
};

export default MyGames;
