import { Box, Button } from '@mui/material';
import { CodenamesGame } from '@playtime/database/src/model/codenames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getTeamForPlayer, isPlayersTurn } from '../../../../back-end';
import { endGuessing } from '../../../../back-end/api/codenames/gameplay';
import db from '../../../../back-end/databases';
import { getUser } from '../../../../store/auth/selectors';
import { Help } from '../../../common/Help';
import { syncDataItem, Sync, SyncedComponentProps } from '../../../hoc/sync';
import { BaseCodenamesProps } from '../../../models/game';
import AreYouReady from './AreYouReady/AreYouReady';
import ClueGiving from './ClueGiving';
import CurrentClue from './CurrentClue';
import Logs from './Logs/Logs';
import Status from './Status/Status';
import WordGrid from './WordGrid';

const GameContent: React.FunctionComponent<
    SyncedComponentProps<Pick<CodenamesGame, 'teams' | 'teamTurn' | 'words' | 'clueGiven' | 'gameLog'>> &
        BaseCodenamesProps
> = (props) => {
    const user = useSelector(getUser);
    const [logTab, setLogTab] = React.useState<'gameLog' | 'gameResults'>('gameLog');
    const syncCodenames = syncDataItem(db.codenamesGame, props.gameId);
    const SyncWordGrid = Sync(WordGrid);
    const SyncAreYouReady = Sync(AreYouReady);
    const SyncClueGiving = Sync(ClueGiving);
    const SyncStatus = Sync(Status);
    const SyncLogs = Sync(Logs);

    React.useEffect(() => {
        const gameOver = (props.teams ?? []).some((t) => !t.isReady);
        if (gameOver) setLogTab(gameOver ? 'gameResults' : 'gameLog');
    }, [props.teams]);
    if (!props.teams || props.teamTurn === undefined || props.teamTurn === null) return null;

    const { index, team } = getTeamForPlayer(props.teams, user.uid);
    const isPlayerActiveClueGiver = team && index === props.teamTurn && isPlayersTurn(team, user.uid);
    const areTeamsReady = !props.teams.some((t) => !t.isReady);
    const shouldShowPassTeam =
        index === props.teamTurn && areTeamsReady && user.uid !== team?.players[team?.playerTurn] && props.clueGiven;

    const areYouReady = (
        <SyncAreYouReady
            teams={syncCodenames.prop('teams')}
            gameId={props.gameId}
            initialGameState={props.initialGameState}
        />
    );
    const activeGame = (
        <>
            <Box display="flex" flexGrow={1} flexDirection="column" alignItems="center">
                <Box height="64px">
                    <CurrentClue {...props} />
                </Box>
                <SyncWordGrid {...syncCodenames.props('words', 'teams')} {...props} />
                {areTeamsReady && isPlayerActiveClueGiver && !props.clueGiven && (
                    <SyncClueGiving
                        teamTurn={syncCodenames.prop('teamTurn')}
                        gameId={props.gameId}
                        initialGameState={props.initialGameState}
                    />
                )}
                <SyncStatus {...syncCodenames.props('teams', 'teamTurn', 'clueGiven', 'gameLog')} {...props} />
                {shouldShowPassTeam && (
                    <Box mb={2} display="flex" alignItems="center" style={{ marginRight: '-24px' }}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                                endGuessing(props.gameId, user.uid, props.initialGameState.config.secondsPerTurn > 0)
                            }
                        >
                            End Guessing
                        </Button>
                        <Box ml={1}>
                            <Help>
                                This will end guessing on behalf of the team. Do not end guessing if you&apos;re waiting
                                for your teammates to guess.
                            </Help>
                        </Box>
                    </Box>
                )}
            </Box>
            <SyncLogs
                {...props}
                gameResults={syncCodenames.prop('gameResults')}
                logTab={logTab}
                setLogTab={setLogTab}
            />
        </>
    );
    const gameContent = () => {
        if (Object.keys(props.words ?? {}).length === 0 && !areTeamsReady) return areYouReady;
        return activeGame;
    };
    return (
        <Box display="flex" flexDirection="column" alignItems="center" height="calc(100% - 79px)">
            {gameContent()}
        </Box>
    );
};

export default GameContent;
