import { Badge, Box, Card, Typography } from '@mui/material';
import { CodenamesGame } from '@playtime/database/src/model/codenames';
import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import { Team as ITeam } from '@playtime/database/src/model/activeGame';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getTeamForPlayer, getTeamName } from '../../../back-end';
import { activateGame } from '../../../back-end/api/lobby';
import { getUser } from '../../../store/auth/selectors';
import { isNullOrUndefined } from '../../../util/util';
import { SyncedComponentProps } from '../../hoc/sync';
import { getGameRoute } from '../../router/routing';
import Team from './Team';
import { makeStyles } from 'tss-react/mui';
import { getPlayersTurn as getFishbowlPlayersTurn } from '../../../back-end/business/pure/fishbowl';
import { getPlayersTurn as getCodenamesPlayersTurn } from '../../../back-end/business/pure/codenames';

const useStyles = makeStyles()({
    gameName: {
        textAlign: 'center',
        minWidth: 0,
    },
    container: {
        margin: 10,
        padding: 6,
        width: '100%',
        cursor: 'pointer',
        webkitTouchCallout: 'none',
        webkitUserSelect: 'none',
        khtmlUserSelect: 'none',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    myTurnBadge: {
        width: '100%',
    },
    myTurnBadgeAnchor: {
        top: 9,
        right: 20,
    },
});

interface MyGameProps
    extends SyncedComponentProps<
            Pick<CodenamesGame | FishbowlGame, 'name' | 'teams' | 'players' | 'gameType' | 'teamTurn'>
        >,
        SyncedComponentProps<Pick<CodenamesGame, 'clueGiven' | 'words'>> {
    gameId: string;
}

const MyGame: React.FunctionComponent<MyGameProps> = (props) => {
    const { classes } = useStyles();
    const user = useSelector(getUser);
    const route = useHistory();
    if (!props.teams?.length || !props.players) return null;
    const { team: myTeam, index: myTeamIndex } = getTeamForPlayer(props.teams, user.uid);
    const goToGame = async () => {
        await activateGame(user.uid, props.gameId);
        if (isNullOrUndefined(props.gameType)) return;
        route.push(getGameRoute(props.gameType));
    };
    const playersTurn =
        isNullOrUndefined(props.words) && isNullOrUndefined(props.clueGiven)
            ? getFishbowlPlayersTurn(props.teams, props.teamTurn)
            : getCodenamesPlayersTurn(
                  props.teams as CodenamesGame['teams'],
                  props.teamTurn,
                  props.words,
                  props.clueGiven
              );

    const BadgeIfTurn: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
        const isMyTurn = playersTurn.includes(user.uid);
        return isMyTurn ? (
            <Badge
                overlap="rectangular"
                classes={{ root: classes.myTurnBadge, anchorOriginTopRightRectangular: classes.myTurnBadgeAnchor }}
                color="secondary"
                badgeContent="My Turn"
            >
                {children}
            </Badge>
        ) : (
            <Box width="100%" display="inline-flex">
                {children}
            </Box>
        );
    };
    return (
        <BadgeIfTurn>
            <Card elevation={3} className={classes.container} onClick={goToGame}>
                <Typography variant="h5" className={classes.gameName}>
                    {props.name}
                </Typography>
                <Box display="inline-flex" flexDirection="row" width="100%">
                    <Box flex={1}>
                        {myTeam && (
                            <Team team={myTeam} players={props.players} playersTurn={playersTurn} align="right" />
                        )}
                    </Box>
                    {props.teams.length > 1 && (
                        <Box mx={1} marginTop="4px">
                            vs
                        </Box>
                    )}
                    <Box flex={1} flexWrap="nowrap">
                        {(props.teams as ITeam[]).map((team, i) => {
                            if (!props.players || i === myTeamIndex) return;
                            return (
                                <Team
                                    key={getTeamName(team)}
                                    team={team}
                                    players={props.players}
                                    playersTurn={playersTurn}
                                    align="left"
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Card>
        </BadgeIfTurn>
    );
};

export default MyGame;
