import { unstable_createMuiStrictModeTheme as createMuiStrictModeTheme } from '@mui/material';
import { pink } from '@mui/material/colors';
import { lightGreen } from '@mui/material/colors';

export const fishbowlColorPrimary = lightGreen['A700'];
export const fishbowlColorSecondary = lightGreen['900'];
export const codenamesColorPrimary = pink['A700'];
export const codenamesColorSecondary = pink['900'];

const theme = createMuiStrictModeTheme({
    palette: {
        primary: {
            main: lightGreen['A700'],
        },
        secondary: {
            main: lightGreen['900'],
        },
    },
    typography: {
        fontFamily: 'Quicksand',
        fontWeightLight: 600,
        fontWeightRegular: 600,
        fontWeightMedium: 600,
        fontWeightBold: 600,
    },
});

export default theme;
