import { CodenamesResults, GameResults } from '@playtime/database/src';

export function getStarDustAmount(gameResults: GameResults<CodenamesResults>): Record<string, number> {
    return Object.fromEntries(
        Object.entries(gameResults).map(([playerId, gameResult]) => {
            const winAward = Math.max(0, gameResult.eloChange ?? 0);
            const wordAward = gameResult.allyAgentCount ?? 0;
            const starDustAmount = winAward + wordAward;
            return [playerId, starDustAmount];
        })
    );
}
