import { makeStyles } from 'tss-react/mui';
import { FishbowlPlayer } from '@playtime/database/src/model/fishbowl';
import { LobbyGame } from '@playtime/database/src/model/lobby';
import React from 'react';
import { useSelector } from 'react-redux';
import { leaveGame } from '../../back-end';
import { getUser } from '../../store/auth/selectors';
import { NotOptional } from '../../util/util';
import { BaseLobbyProps } from './baseLobbyProps';
import { PlayerInfo } from './PlayerInfo';

const useStyles = makeStyles()({
    centeredContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
});

interface PlayerListProps {
    phraseCount?: number;
    host: string;
}

export const PlayerList: React.FunctionComponent<
    NotOptional<Pick<LobbyGame, 'players'>> & BaseLobbyProps & PlayerListProps
> = (props) => {
    const { classes } = useStyles();
    const user = useSelector(getUser);
    const playerEntries = Object.entries(props.players ?? {});
    const isHost = props.host === user.uid;

    return (
        <div className={`${classes.fullWidth} ${classes.centeredContainer}`}>
            {playerEntries.map(([id, player]) => (
                <PlayerInfo
                    key={id}
                    leaveGame={() => leaveGame(id, props.gameId)}
                    playerName={player.displayName}
                    isHost={isHost}
                    isThisUser={user.uid === id}
                    playerPhraseCount={Object.keys((player as FishbowlPlayer)?.phrases ?? {}).length}
                    targetPhraseCount={props.phraseCount}
                />
            ))}
        </div>
    );
};
