import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { EmojiPeople } from '@mui/icons-material';

const useStyles = makeStyles()((theme) => ({
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    playersLine: {
        width: '80%',
        padding: '0.5em',
        textAlign: 'center',
    },
    playerBox: {
        width: '100%',
        color: 'white',
        borderRadius: '20px',
        padding: '1em',
    },
    notHost: {
        backgroundColor: theme.palette.secondary.main,
    },
    host: {
        backgroundColor: '#22CC22',
        borderStyle: 'solid',
        borderWidth: '2px',
        color: 'black',
    },
    playerInfo: {
        paddingLeft: '1em',
        paddingRight: '1em',
    },
    playerName: {
        fontWeight: 'bold',
        width: '50%',
    },
}));

interface PlayerInfoProps {
    isHost: boolean;
    playerName: string;
    isThisUser: boolean;
    leaveGame(): void;
    playerPhraseCount?: number;
    targetPhraseCount?: number;
}

export const PlayerInfo: React.FunctionComponent<PlayerInfoProps> = (props) => {
    const { classes } = useStyles();

    const phraseCountText =
        props.playerPhraseCount !== undefined &&
        props.targetPhraseCount !== undefined &&
        `${props.playerPhraseCount}/${props.targetPhraseCount}`;

    const leaveBtnMessage = props.isThisUser ? 'Leave' : 'Kick';
    const showLeaveBtn = props.isHost || props.isThisUser;
    return (
        <div className={`${classes.playersLine} ${classes.centered}`}>
            <div
                className={`${classes.playerBox} ${classes.centered} ${
                    props.isThisUser ? classes.host : classes.notHost
                }`}
            >
                <EmojiPeople />
                <span className={`${classes.playerName} ${classes.playerInfo}`}>{props.playerName}</span>
                {phraseCountText && (
                    <span className={`${classes.playerName} ${classes.playerInfo}`}>{phraseCountText}</span>
                )}
                {showLeaveBtn ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.playerInfo}
                        onClick={props.leaveGame}
                    >
                        {leaveBtnMessage}
                    </Button>
                ) : undefined}
            </div>
        </div>
    );
};
