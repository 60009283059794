import { Box, Grid } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { changeTurns, getTeamForPlayer, getTeamName } from '../../../../back-end';
import { getUser } from '../../../../store/auth/selectors';
import { Sync, syncDataItem } from '../../../hoc/sync';
import GameProgress from './GameProgress';
import { useSelector } from 'react-redux';
import RemainingPhraseCount from './RemainingPhraseCount';
import { Timer } from '../../../common/Timer';
import { ContestReview } from '../ContestReview';
import assert from 'assert';
import { NotOptional } from '../../../../util/util';
import { BaseFishbowlProps } from '../../../models/game';
import db from '../../../../back-end/databases';
import { Team } from '@playtime/database/src/model/activeGame';
import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import ScoreBanners from '../../../common/ScoreBanner/ScoreBanners';
import ScoreBannerRosterWithData from './ScoreBannerTeamRoster';

export interface GameHeaderProps
    extends BaseFishbowlProps,
        NotOptional<Pick<FishbowlGame, 'teamTurn' | 'teams' | 'players'>> {
    playerTurn: Team['playerTurn'] | undefined;
}

export const GameHeader: FunctionComponent<GameHeaderProps> = (props) => {
    const user = useSelector(getUser);
    const SyncedGameProgress = Sync(GameProgress);
    const SyncedRemainingPhraseCount = Sync(RemainingPhraseCount);
    const SyncedTimer = Sync(Timer);
    const SyncedContestReview = Sync(ContestReview);
    const myTeam = getTeamForPlayer(props.initialGameState.teams, user.uid);

    const localChangeTurns = () => {
        assert(props.teamTurn !== undefined, 'team turn not defined when changing turns');
        assert(props.playerTurn !== undefined, 'player turn not defined when changing turns');
        changeTurns(props.gameId, user.uid, props.teamTurn, props.playerTurn);
    };

    const syncGame = syncDataItem(db.fishbowlGame, props.gameId);
    const SyncedScoreBanners = Sync(ScoreBanners);
    const turnEndSyncProp = syncGame.prop('turnEndTime');
    const playerTeam = getTeamForPlayer(props.initialGameState.teams, user.uid).team;
    const teamName = playerTeam ? getTeamName(playerTeam) : 'Unknown';

    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} style={{ padding: 0 }}>
                <SyncedGameProgress
                    rounds={props.initialGameState.config.rounds}
                    team={myTeam.team}
                    round={syncGame.prop('round')}
                />
            </Grid>
            <Grid item xs={12}>
                <SyncedScoreBanners
                    gameId={props.gameId}
                    initialGameState={props.initialGameState}
                    TeamRoster={ScoreBannerRosterWithData(props)}
                    roundIcons={props.initialGameState.config.rounds.map((round) => round.icon)}
                    teams={props.teams?.map((team) => ({
                        ...team,
                        scoreDisplay: team.scores.reduce((sum, cur) => sum + cur, 0),
                    }))}
                />
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" mx={1}>
                    <Box>
                        <h3>{teamName}</h3>
                    </Box>
                    <Box display="flex" flexGrow={1} justifyContent="center">
                        <SyncedTimer
                            endTime={turnEndSyncProp}
                            totalTimeInSeconds={props.initialGameState.config.secondsPerTurn}
                            onExpiration={localChangeTurns}
                        />
                    </Box>
                    <Box>
                        <SyncedRemainingPhraseCount {...props} {...syncGame.props('phrasePool', 'skippedPhrases')} />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <SyncedContestReview
                    {...props}
                    turnEndTime={turnEndSyncProp}
                    guessedPhrases={syncGame.prop('guessedPhrases')}
                />
            </Grid>
        </Grid>
    );
};
