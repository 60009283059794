import { Paper } from '@mui/material';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';

interface OutlinedLabelProps {
    label: string;
    background?: string;
    labelBackground?: string;
    className?: string;
}

const useStyles = makeStyles<{ background?: string; labelBackground?: string }>()(
    (_, { background, labelBackground }) => {
        return {
            container: {
                position: 'relative',
            },
            thickPaper: {
                borderRadius: '6px',
                padding: '12px',
                border: '1px solid rgba(0, 0, 0, 0.32)',
                position: 'relative',
                background: background,
            },
            progressLabel: {
                position: 'absolute',
                top: '-.7rem',
                left: '50%',
                transform: 'translate(-50%)',
                textAlign: 'center',
                display: 'block',
                padding: '0 5px',
                zIndex: 1,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                background: labelBackground,
                backgroundColor: '#fafafa',
                color: '#666',
                maxWidth: '90%',
                borderRadius: '5px',
            },
        };
    }
);

const OutlinedLabel: React.FunctionComponent<React.PropsWithChildren & OutlinedLabelProps> = (props) => {
    const { classes } = useStyles({ background: props.background, labelBackground: props.labelBackground });
    return (
        <div className={`${classes.container} ${props.className}`}>
            <label className={classes.progressLabel}>{props.label}</label>
            <Paper variant="outlined" className={classes.thickPaper}>
                {props.children}
            </Paper>
        </div>
    );
};

export default OutlinedLabel;
