import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import Button from '@mui/material/Button';
import { GameAssignmentType, Players, Player } from '@playtime/database/src/model/lobby';
import { getGameOrLobbyDb } from '../../../back-end';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gameLine: {
        width: '80%',
        padding: '0.5em',
        textAlign: 'center',
    },
    gameBox: {
        width: '20em',
        backgroundColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: 'thin',
        borderColor: theme.palette.secondary.main,
        borderRadius: '20px',
        padding: '1em',
    },
    gameInfo: {
        marginLeft: '4px',
        marginRight: '4px',
    },
}));

interface GameInfoProps {
    gameId: string;
    gameName: string;
    gameType: GameAssignmentType;
    joinGame?: () => void;
    killGame?: () => void;
}

export const GameInfo: React.FunctionComponent<GameInfoProps> = (props: GameInfoProps) => {
    const { classes } = useStyles();
    const [players, setPlayers] = useState<Players<Player> | null>(null);
    useEffect(() => {
        const db = getGameOrLobbyDb(props.gameType);
        return db.property(props.gameId, 'players').listen((players) => setPlayers(players ?? null)).stop;
    }, []);

    return (
        <div className={`${classes.gameLine} ${classes.centered}`}>
            <Box className={classes.gameBox} display="flex" flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{props.gameName}</Typography>
                <Box flexGrow={2} />
                <span className={`${classes.gameInfo} ${classes.centered}`}>
                    <EmojiPeopleIcon />
                    {Object.keys(players ?? {}).length}
                </span>
                {props.joinGame ? (
                    <Button variant="contained" className={classes.gameInfo} onClick={props.joinGame}>
                        Join
                    </Button>
                ) : null}
                {props.killGame ? (
                    <Button variant="contained" className={classes.gameInfo} color="secondary" onClick={props.killGame}>
                        Delete
                    </Button>
                ) : null}
            </Box>
        </div>
    );
};
