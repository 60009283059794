import { getDatabases } from '@playtime/database';
import assert from 'assert';
import { getDatabase, ref } from 'firebase/database';
import store from '../store';
import { getFirebaseApp } from '../store/appSettings/selectors';
import { getSystemDbMode } from '../store/devTools/selectors';

function firebaseRoot() {
    const systemDbMode = getSystemDbMode(store.store.getState());
    const firebaseApp = getFirebaseApp(store.store.getState());
    assert(firebaseApp, "firebase hasn't loaded yet");
    return ref(getDatabase(firebaseApp), systemDbMode);
}
const db = getDatabases(firebaseRoot);

export default db;
