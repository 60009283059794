import * as types from './types';
import { AnyAction } from 'redux';
import { Auth, User as FirebaseUser } from 'firebase/auth';
import { User } from '@playtime/database/src';

export interface AuthStore {
    user: (FirebaseUser & Partial<User>) | null;
    firebaseAuth: Auth | null;
}
const initialState: AuthStore = {
    user: null,
    firebaseAuth: null,
};

const auth = (state = initialState, action: AnyAction): AuthStore => {
    switch (action.type) {
        case types.MODIFY_USER:
            return {
                ...state,
                user: { ...state.user, ...action.user },
            };
        case types.SET_USER:
            return {
                ...state,
                user: action.user,
            };
        case types.LOGOUT:
            return {
                ...state,
                user: null,
            };
        case types.SET_FIREBASE_AUTH:
            return {
                ...state,
                firebaseAuth: action.firebaseAuth,
            };
    }
    return state;
};

export default auth;
