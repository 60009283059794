import * as types from './types';
import { AnyAction } from 'redux';

export interface AppSettings {
    systemModes: SystemModes;
}
export type SystemMode = 'prod' | 'dev';
export interface SystemModes {
    systemDbMode: SystemMode;
    systemAppMode: SystemMode;
}
const initialState: AppSettings = {
    systemModes: {
        systemDbMode: 'dev',
        systemAppMode: 'dev',
    },
};

const devTools = (state = initialState, action: AnyAction): AppSettings => {
    switch (action.type) {
        case types.SET_SYSTEM_APP_MODE:
            return {
                ...state,
                systemModes: {
                    ...state.systemModes,
                    systemAppMode: action.systemAppMode,
                },
            };
        case types.SET_SYSTEM_DB_MODE:
            return {
                ...state,
                systemModes: {
                    ...state.systemModes,
                    systemDbMode: action.systemDbMode,
                },
            };
    }
    return state;
};

export default devTools;
