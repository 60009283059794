import { Paper } from '@mui/material';
import { ActiveGame } from '@playtime/database/src';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { getColorSchemeRgb } from '../../../back-end';
import { SyncedComponentProp } from '../../hoc/sync';
import { ScoreBannerTeamRosterProps } from './ScoreBanner';

interface TeamRosterProps extends ScoreBannerTeamRosterProps {
    players: SyncedComponentProp<ActiveGame['players']>;
    playersTurn: SyncedComponentProp<string[]>;
}

const useStyles = makeStyles<{
    isLeft: boolean;
    primaryColor: [number, number, number];
    secondaryColor?: [number, number, number];
}>()((_, { isLeft, primaryColor, secondaryColor }) => {
    const rgbColors = getColorSchemeRgb(primaryColor, secondaryColor);
    const styles: Record<string, Record<string, string | number>> = {
        teamRoster: {
            backgroundColor: 'rgba(0,0,0,.5)',
            padding: '6px 6px 2px 6px',
            color: '#fff',
            top: 24,
            position: 'absolute',
            width: '100%',
            zIndex: 2,
        },
    };

    const opacity = 0.5;
    const secondaryOrPrimary = rgbColors.secondary ?? rgbColors.primary;
    const intermediateOrPrimary = rgbColors.intermediate ?? rgbColors.primary;
    styles.teamRoster.backgroundImage = `linear-gradient( ${isLeft ? 135 : 45}deg, 
            rgba(${isLeft ? rgbColors.primary : secondaryOrPrimary},${opacity}) 0%, 
            rgba(${intermediateOrPrimary},${opacity}) ${isLeft ? 70 : 30}%, 
            rgba(${isLeft ? secondaryOrPrimary : rgbColors.primary},${opacity}) 100%)`;
    styles.teamRoster.textAlign = isLeft ? 'start' : 'end';

    return styles;
});

const TeamRoster: React.FunctionComponent<TeamRosterProps> = (props) => {
    const { classes } = useStyles({
        isLeft: props.isLeft,
        primaryColor: props.primaryColor,
        secondaryColor: props.secondaryColor,
    });

    const isPlayerTurn = (id: string) => (props.playersTurn ?? []).includes(id);
    return (
        <Paper square={true} elevation={2} className={classes.teamRoster}>
            {props.players &&
                props.team.players.map((id, ix) => (
                    <span key={id}>
                        {props.isLeft && isPlayerTurn(id) ? <>&#9656;</> : null}
                        <span
                            style={{
                                marginLeft: props.isLeft && isPlayerTurn(id) ? 0 : 14,
                                marginRight: !props.isLeft && isPlayerTurn(id) ? 0 : 14,
                            }}
                        >
                            {props.players && props.players[id].displayName}
                        </span>
                        {!props.isLeft && isPlayerTurn(id) ? <>&#9666;</> : null}
                        {ix === Object.keys(props.players ?? []).length - 1 ? null : <br />}
                    </span>
                ))}
        </Paper>
    );
};

export default TeamRoster;
