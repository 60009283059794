import { Badge, Box, useMediaQuery } from '@mui/material';
import { Paper } from '@mui/material';
import { Word } from '@playtime/database/src/model/codenames';
import * as React from 'react';
import { getColorSchemeRgb } from '../../../../back-end';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../store/auth/selectors';

const useStyles = (
    darkFont: boolean,
    primaryColor: [number, number, number],
    secondaryColor?: [number, number, number]
) => {
    const rgbColors = getColorSchemeRgb(primaryColor, secondaryColor);
    const opacity = '.85';
    return makeStyles()((theme) => ({
        verySmallCard: {
            marginTop: '4px',
            marginLeft: '4px',
            width: 'calc((100vw - 53px) / 5)',
        },
        smallCard: {
            marginTop: '8px',
            marginLeft: '8px',
            width: 'calc((100vw - 73px) / 5)',
        },
        mediumCard: {
            marginTop: '8px',
            marginLeft: '8px',
            width: 'calc((100vw - 73px) / 5)',
        },
        largeCard: {
            marginTop: '8px',
            marginLeft: '8px',
            width: '85px',
            height: '35px',
        },
        card: {
            height: '35px',
            cursor: 'pointer',
            textAlign: 'center',
            lineHeight: '1',
        },
        spyTypeBackground: {
            background: secondaryColor
                ? `linear-gradient(45deg, rgb(${rgbColors.secondary}, ${opacity}) 5%, rgb(${rgbColors.primary}, ${opacity}) 70%)`
                : `rgb(${rgbColors.primary}, ${opacity})`,
        },
        wordColor: {
            color: darkFont ? '#666' : '#fff',
        },
        userHasNotVoted: {
            color: '#666',
            backgroundColor: 'white',
            border: `1px solid ${theme.palette.primary.dark}`,
        },
        badgePositioning: {
            top: '5px',
            right: '2px',
        },
    }));
};

const getLargeWordSize = (isVerySmall: boolean, isSmall: boolean, isMedium: boolean, length: number) => {
    if (isVerySmall) return length >= 11 ? '.55em' : length >= 10 ? '.65em' : '.75em';
    if (isSmall) return length >= 11 ? '.6em' : length >= 10 ? '.7em' : '.8em';
    if (isMedium) return length >= 11 ? '.7em' : length >= 10 ? '.8em' : '.9em';
    return length >= 11 ? '.8em' : length >= 10 ? '.9em' : '1em';
};

export interface WordCardProps extends Word {
    onClick(): void;
    revealBoard: boolean;
    revealed: boolean;
    numberOfVotes: number | undefined;
    primaryColor: [number, number, number];
    darkFont?: boolean;
    secondaryColor?: [number, number, number];
}

const WordCard: React.FunctionComponent<WordCardProps> = (props) => {
    const user = useSelector(getUser);
    const { classes } = useStyles(props.darkFont ?? false, props.primaryColor, props.secondaryColor)();
    const isVerySmall = useMediaQuery('(max-width:400px)');
    const isSmall = useMediaQuery('(max-width:450px)');
    const isMedium = useMediaQuery('(max-width:500px)');
    const cardSizeClass = isVerySmall
        ? classes.verySmallCard
        : isSmall
        ? classes.smallCard
        : isMedium
        ? classes.mediumCard
        : classes.largeCard;
    const backgroundColor = props.revealBoard || props.revealed ? classes.spyTypeBackground : '';
    const wordColor = props.revealBoard || props.revealed ? classes.wordColor : '';
    const userVoted = props.voters && props.voters.includes(user.uid);

    const wordCard = (
        <Paper className={`${cardSizeClass} ${classes.card} ${backgroundColor}`}>
            <Box onClick={props.onClick} display="flex" alignItems="center" justifyContent="center" height="100%">
                {!props.revealed || !props.revealBoard ? (
                    <span
                        style={{
                            fontSize: getLargeWordSize(isVerySmall, isSmall, isMedium, props.word.length),
                        }}
                        className={wordColor}
                    >
                        {props.word}
                    </span>
                ) : null}
            </Box>
        </Paper>
    );
    const wordCardWithBadge =
        props.voters && props.voters.length > 0 ? (
            <Badge
                overlap="rectangular"
                classes={{ badge: `${classes.badgePositioning} ${userVoted ? '' : classes.userHasNotVoted}` }}
                badgeContent={
                    <>
                        {props.voters.length}/{props.numberOfVotes}
                    </>
                }
                color="secondary"
            >
                {wordCard}
            </Badge>
        ) : (
            wordCard
        );
    return wordCardWithBadge;
};

export default WordCard;
