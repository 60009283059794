import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import React, { FunctionComponent } from 'react';
import { NotOptional } from '../../../util/util';
import { BaseFishbowlProps } from '../../models/game';

export interface ContestReviewProps
    extends NotOptional<Pick<FishbowlGame, 'turnEndTime' | 'guessedPhrases'>>,
        BaseFishbowlProps {}

export const ContestReview: FunctionComponent<ContestReviewProps> = (props) => {
    if (props.initialGameState.config.minContestVotes < 1) {
        // Contest disabled
        return null;
    }
    if (props.turnEndTime !== undefined) {
        return null;
    }
    const contestedPhrases = (props.guessedPhrases ?? []).filter(
        (phrase) => (phrase.contests?.length ?? 0) >= props.initialGameState.config.minContestVotes
    );
    if (contestedPhrases.length < 1) {
        return null;
    }
    return (
        <>
            <h2>Contested Phrases</h2>
            {contestedPhrases.map((phrase) => (
                <div key={phrase.phrase}>
                    <div>
                        <h3>{phrase.phrase}</h3>
                    </div>
                    <div>
                        Contested by:{' '}
                        {phrase.contests?.map((id) => props.initialGameState.players[id].displayName).join(', ')}
                    </div>
                </div>
            ))}
        </>
    );
};
