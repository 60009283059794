import { IStore } from '..';
import { SystemMode } from './reducer';

export const getSystemDbMode = (state: IStore): SystemMode => {
    return state.devTools.systemModes.systemDbMode;
};

export const getSystemAppMode = (state: IStore): SystemMode => {
    return state.devTools.systemModes.systemAppMode;
};
