import { randomName } from '../../util/random';
import assert from 'assert';
import * as core from '../business/lobby';
import * as store from './store';
import * as messaging from './messaging';
import * as joinableActiveGame from './joinableActiveGame';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from '@firebase/auth';
import { validateFishbowlConfig } from '../business/fishbowl/gameSetup';
import db from '../databases';
import {
    Database,
    inTranIfDefined,
    mergeResults,
    Result,
    result,
    success,
    failure,
    JoinableActiveGame,
    inTranDefault,
    GameAssignment,
    Team,
    TeamBanner,
} from '@playtime/database';
import { ActiveGame } from '@playtime/database/src/model/activeGame';
import { CodenamesGame } from '@playtime/database/src/model/codenames';
import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import {
    GameType,
    LobbyGame,
    GameAssignmentType,
    GenericGame,
    LobbyGameType,
    GameTypeConfig,
    isFishbowlTypeConfig,
    Users,
} from '@playtime/database/src/model/lobby';

export const defaultUser = {
    starDust: { current: 0, lifetime: 0, transactions: [] },
};
function isGameType<T extends GameType>(game: LobbyGame, gameType: T): game is LobbyGame<T> {
    return game.gameType === gameType;
}

export function getGameDb(gameType: GameType): Database<ActiveGame & (FishbowlGame | CodenamesGame), string> {
    if (gameType === GameType.Fishbowl) return db.fishbowlGame;
    if (gameType === GameType.Codenames) return db.codenamesGame;
    throw new Error('no game db for given game type');
}

export function getGameOrLobbyDb(gameType: GameAssignmentType): Database<GenericGame, string> {
    if (gameType === GameType.Fishbowl) return db.fishbowlGame;
    if (gameType === GameType.Codenames) return db.codenamesGame;
    if (gameType === LobbyGameType.Lobby) return db.lobbyGame;
    throw new Error('no game db for given game type');
}

export async function fetchLobbyGameType<T extends GameType>(
    gameId: string,
    gameType: T,
    notExistMessage?: string,
    notGameTypeMessage?: string
): Promise<Result<LobbyGame<T>>> {
    const game = await db.lobbyGame.fetch(gameId);
    if (game === undefined) return failure<LobbyGame<T>>("Fetched game doesn't exist" ?? notExistMessage);
    if (!isGameType(game, gameType)) return failure('Fetched game not expected type' ?? notGameTypeMessage);
    return result(game);
}

/**
 * @returns true if player joined the game, false if the player was already in the game
 */
export async function assignPlayerToGame(
    gameId: string,
    userId: string,
    gameType: GameAssignmentType = LobbyGameType.Lobby
) {
    return inTranDefault(db.users, userId, (pa) => core.assignPlayerToGame(pa, gameId, gameType), defaultUser);
}

export async function createNewGame(gameName: string, configType: GameTypeConfig, userId: string, displayName: string) {
    const result = isFishbowlTypeConfig(configType) ? validateFishbowlConfig(configType.config) : success();
    if (!result.success) {
        return result;
    }
    const gameId = await db.lobbyGame.create({
        name: gameName,
        ...configType,
        host: userId,
        players: { [userId]: { displayName } },
    });
    return assignPlayerToGame(gameId, userId);
}

export async function joinGame(
    gameId: string,
    userId: string,
    displayName: string
): Promise<Result<CodenamesGame | LobbyGame | GameAssignment>> {
    const [user, joinedActiveGameResult, joinedLobbyGameResult] = await Promise.all([
        db.users.fetch(userId),
        joinableActiveGame.joinActiveGame(gameId, userId, displayName),
        inTranIfDefined(db.lobbyGame, gameId, (game) => {
            game.players = { ...game.players, [userId]: { displayName } };
            return result(game);
        }),
    ]);
    const alreadyJoinedGame = user?.games?.find((game) => game.id === gameId);
    const joinedActiveGame = joinedActiveGameResult.success && joinedActiveGameResult.value;
    const joinedLobbyGame = joinedLobbyGameResult.success && joinedLobbyGameResult.value;
    const game = joinedActiveGame || joinedLobbyGame || alreadyJoinedGame;
    if (!game) return failure(`game either doesn't exist or is active and not joinable`);
    const gameType = joinedActiveGame
        ? joinedActiveGame.gameType
        : joinedLobbyGame
        ? LobbyGameType.Lobby
        : alreadyJoinedGame?.type;
    const assignPlayerResults = await assignPlayerToGame(gameId, userId, gameType);
    const alreadyJoined = assignPlayerResults.success && !assignPlayerResults.value;
    if (!alreadyJoined) await messaging.notifyLobbyHost(gameId, userId, false);
    return result(game);
}

export async function deleteAllGames() {
    await Promise.all([
        db.users.deleteAll(),
        db.lobbyGame.deleteAll(),
        db.fishbowlGame.deleteAll(),
        db.codenamesGame.deleteAll(),
    ]);
}

export async function leaveGame(userId: string, gameId: string): Promise<void> {
    const [isEmptyGame] = await Promise.all([
        inTranIfDefined(db.lobbyGame, gameId, (game) => core.removePlayer(game, userId)),
        inTranIfDefined(db.users, userId, (pa) => core.unassignPlayerFromGame(pa, gameId)),
    ]);
    if (isEmptyGame.success && isEmptyGame.value) {
        return await db.lobbyGame.delete(gameId);
    }
    await messaging.notifyLobbyHost(gameId, userId, true);
}

export async function leaveActiveGame(userId: string, gameId: string, gameType: GameType): Promise<void> {
    const gameDb = getGameDb(gameType);
    const [isEmptyGame] = await Promise.all([
        inTranIfDefined(gameDb, gameId, (game) => core.deactivatePlayer(game, userId)),
        inTranIfDefined(db.users, userId, (pa) => core.unassignPlayerFromGame(pa, gameId)),
    ]);
    if (isEmptyGame.success && isEmptyGame.value) {
        await gameDb.delete(gameId);
    }
}

export async function deleteGame(gameId: string): Promise<void> {
    const game = await db.lobbyGame.fetch(gameId);
    if (!game) {
        return;
    }
    await Promise.all(Object.keys(game.players ?? {}).map((userId) => leaveGame(userId, gameId)));
}

export async function deleteActiveGame(gameId: string, gameType: GameType): Promise<Result> {
    const gameDb = getGameDb(gameType);
    const gameToAbort = await gameDb.fetch(gameId);
    if (gameToAbort === undefined) {
        return failure('Game to abort does not exist');
    }

    await Promise.all([
        Object.keys(gameToAbort?.players ?? {}).map((userId) => {
            return inTranIfDefined(db.users, userId, (pa) => {
                return core.unassignPlayerFromGame(pa, gameId);
            });
        }),
        gameDb.delete(gameId),
        db.joinableActiveGames.delete(gameId),
    ]);
    return success();
}

export async function getJoinableGame(gameId: string): Promise<JoinableActiveGame | undefined> {
    const [lobbyGame, joinableActiveGame] = await Promise.all([
        db.lobbyGame.fetch(gameId),
        db.joinableActiveGames.fetch(gameId),
    ]);
    if (lobbyGame !== undefined) return { id: gameId, ...lobbyGame };
    if (joinableActiveGame !== undefined) return joinableActiveGame;
}

export async function activateGame(userId: string, gameId: string) {
    return inTranIfDefined(db.users, userId, (pa) => core.activateGame(pa, gameId));
}

export async function setGameType(users: string[], gameId: string, gameType: GameType) {
    return mergeResults(
        await Promise.all(
            users.map((userId) => inTranIfDefined(db.users, userId, (pa) => core.setGameType(pa, gameId, gameType)))
        )
    );
}

export async function deleteAllSingleUseAccounts(firebaseAuth: Auth) {
    const accounts = await db.accounts.getAll();
    for (const [, account] of Object.entries(accounts)) {
        if (account.isSingleUse) {
            const cred = await signInWithEmailAndPassword(firebaseAuth, account.email, account.password);
            const userAuth = cred.user;
            if (userAuth) {
                await userAuth.delete();
                const user = await db.users.fetch(userAuth.uid);
                const gameId = user?.activeGameId;
                if (gameId) {
                    await leaveGame(userAuth.uid, gameId);
                }
                await db.users.delete(userAuth.uid);
            }
        }
    }
}

export async function createSingleUseAccount(firebaseAuth: Auth, userName: string) {
    const email = `${randomName(5)}@${randomName(8)}.fake`;
    const password = randomName(9);
    const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
    assert(userCredential.user, 'Expected user for credential');
    await updateProfile(userCredential.user, { displayName: userName });
    await db.accounts.set(userCredential.user.uid, {
        isSingleUse: true,
        email,
        password,
    });
    return userCredential.user;
}

export const allTeamNames: TeamBanner[] = [
    { prefix: 'Red', primaryColor: [255, 0, 0], darkFont: false },
    { prefix: 'Blue', primaryColor: [0, 0, 255], darkFont: false },
    { prefix: 'Green', primaryColor: [0, 199, 0], darkFont: false },
    { prefix: 'Yellow', primaryColor: [227, 227, 16], darkFont: true },
    { prefix: 'Orange', primaryColor: [255, 165, 0], darkFont: false },
    { prefix: 'Purple', primaryColor: [128, 0, 107], darkFont: false },
    { prefix: 'Pink', primaryColor: [255, 102, 128], darkFont: false },
    { prefix: 'Teal', primaryColor: [0, 212, 201], darkFont: false },
    { prefix: 'Cyan', primaryColor: [0, 255, 242], darkFont: true, suffixChar: 'S' },
    { prefix: 'Maroon', primaryColor: [128, 21, 0], darkFont: false },
    { prefix: 'Burgundy', primaryColor: [166, 5, 5], darkFont: false },
    { prefix: 'Brown', primaryColor: [122, 73, 31], darkFont: false },
    { prefix: 'Gray', primaryColor: [92, 92, 92], darkFont: false },
    { prefix: 'Indigo', primaryColor: [75, 0, 130], darkFont: false },
];

export interface PremiumTeam extends TeamBanner {
    value: number;
    cost: number;
    rating?: number;
}

export const premiumTeams: Record<string, PremiumTeam> = {
    Ruby: {
        value: 1,
        prefix: 'Ruby',
        primaryColor: [196, 0, 0],
        secondaryColor: [125, 25, 0],
        darkFont: false,
        cost: 200,
    },
    Sapphire: {
        value: 2,
        prefix: 'Sapphire',
        primaryColor: [13, 0, 255],
        secondaryColor: [195, 191, 255],
        darkFont: false,
        cost: 200,
    },
    Emerald: {
        value: 3,
        prefix: 'Emerald',
        primaryColor: [2, 145, 0],
        secondaryColor: [124, 255, 122],
        darkFont: false,
        cost: 200,
    },
    Topaz: {
        value: 4,
        prefix: 'Topaz',
        primaryColor: [237, 205, 59],
        secondaryColor: [38, 255, 248],
        darkFont: true,
        cost: 300,
    },
    Onyx: {
        value: 5,
        prefix: 'Onyx',
        primaryColor: [34, 9, 82],
        secondaryColor: [66, 6, 6],
        darkFont: false,
        cost: 300,
    },
    Bulldog: {
        value: 14,
        prefix: 'Bulldog',
        primaryColor: [153, 51, 0],
        secondaryColor: [166, 166, 166],
        darkFont: false,
        cost: 300,
    },
    ['The A']: {
        value: 6,
        prefix: 'The A',
        primaryColor: [0, 110, 33],
        secondaryColor: [110, 68, 0],
        darkFont: false,
        suffixChar: 'T',
        cost: 300,
    },
    ['Number One']: {
        value: 7,
        prefix: 'Number One',
        primaryColor: [0, 0, 255],
        secondaryColor: [184, 255, 218],
        darkFont: false,
        cost: 400,
    },
    Boys: {
        value: 9,
        prefix: 'Boys',
        primaryColor: [49, 209, 245],
        secondaryColor: [37, 0, 161],
        darkFont: false,
        cost: 400,
    },
    Girls: {
        value: 10,
        prefix: 'Girls',
        primaryColor: [245, 157, 230],
        secondaryColor: [242, 113, 33],
        darkFont: false,
        cost: 400,
    },
    Big: {
        value: 11,
        prefix: 'Big',
        primaryColor: [255, 187, 0],
        secondaryColor: [255, 98, 0],
        darkFont: false,
        cost: 400,
    },
    Small: {
        value: 12,
        prefix: 'Small',
        primaryColor: [138, 243, 255],
        secondaryColor: [254, 217, 255],
        darkFont: true,
        cost: 400,
    },
    Omega: {
        value: 13,
        prefix: 'Omega',
        primaryColor: [181, 133, 0],
        secondaryColor: [255, 0, 0],
        darkFont: false,
        cost: 400,
    },
    Raven: {
        value: 8,
        prefix: 'Raven',
        primaryColor: [108, 3, 173],
        secondaryColor: [0, 0, 0],
        darkFont: false,
        cost: 500,
    },
    Alpha: {
        value: 15,
        prefix: 'Alpha',
        primaryColor: [201, 224, 255],
        secondaryColor: [246, 255, 0],
        darkFont: true,
        cost: 500,
    },
    Unicorn: {
        value: 16,
        prefix: 'Unicorn',
        primaryColor: [49, 209, 245],
        secondaryColor: [255, 0, 212],
        darkFont: false,
        cost: 500,
    },
    ['Sour Patch']: {
        value: 17,
        prefix: 'Sour Patch',
        primaryColor: [255, 230, 0],
        secondaryColor: [166, 255, 0],
        darkFont: true,
        suffixChar: 'P',
        cost: 500,
    },
    Chameleon: {
        value: 18,
        prefix: 'Chameleon',
        primaryColor: [2, 2, 191],
        secondaryColor: [98, 255, 0],
        darkFont: false,
        cost: 500,
    },
    Bronze: {
        value: 19,
        prefix: 'Bronze',
        primaryColor: [204, 112, 0],
        secondaryColor: [237, 205, 59],
        darkFont: false,
        cost: 50,
        rating: 1000,
    },
    Silver: {
        value: 20,
        prefix: 'Silver',
        primaryColor: [200, 200, 200],
        secondaryColor: [150, 150, 150],
        darkFont: false,
        cost: 100,
        rating: 1100,
    },
    Gold: {
        value: 21,
        prefix: 'Gold',
        primaryColor: [251, 255, 23],
        secondaryColor: [196, 154, 0],
        darkFont: true,
        cost: 150,
        rating: 1200,
    },
    Platinum: {
        value: 22,
        prefix: 'Platinum',
        primaryColor: [153, 153, 153],
        secondaryColor: [217, 255, 252],
        darkFont: false,
        cost: 200,
        rating: 1300,
    },
    Diamond: {
        value: 23,
        prefix: 'Diamond',
        primaryColor: [219, 254, 255],
        secondaryColor: [251, 252, 220],
        darkFont: true,
        cost: 300,
        rating: 1400,
    },
};

export async function generateTeams(balancedTeams: Pick<Team, 'players'>[]): Promise<Result<Team[]>> {
    if (balancedTeams.length <= 0) return failure('team count should be greater than zero');

    const allPlayers = balancedTeams.reduce<string[]>((players, team) => [...players, ...team.players], []);
    const playersTeamBanners = await store.getPlayersTeamBanners(allPlayers);
    return result(core.mergePurchasedBanners(balancedTeams, playersTeamBanners));
}

export async function getUsers(userIds: string[]): Promise<Result<Users>> {
    const userResponses = await Promise.all(
        userIds.map(async (userId) => {
            const user = await db.users.fetch(userId);
            return [userId, user];
        })
    );
    const nonExistentUsers = userResponses.filter(([_, user]) => user === undefined);
    if (nonExistentUsers.length > 0) return failure(`these user ids don't exist: ${nonExistentUsers.join(', ')}`);
    return result(Object.fromEntries(userResponses));
}
