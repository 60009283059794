import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/auth/selectors';
import { editGameConfig } from '../../../back-end/api/fishbowl/gameSetup';
import { Sync, syncProp } from '../../hoc/sync';
import { BaseLobbyProps } from '../baseLobbyProps';
import { ConfigButton } from './ConfigButton';
import db from '../../../back-end/databases';
import { GameType, GameConfig } from '@playtime/database/src/model/lobby';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    configButtonLabel: {
        padding: '0.5em',
    },
    configButtonRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    configButton: {
        marginLeft: '0.5em',
    },
}));

interface GameConfigRowProps extends BaseLobbyProps {
    host: string;
    gameType: GameType;
}

export const GameConfigRow: React.FunctionComponent<GameConfigRowProps> = (props) => {
    const { classes } = useStyles();
    const user = useSelector(getUser);

    const SyncedGameConfigButton = Sync(ConfigButton);

    const syncConfigProp = syncProp(db.lobbyGame, props.gameId, 'config');

    const isHost = props.host === user.uid;

    const editConfig = async (gameConfig: GameConfig<GameType>) => {
        await editGameConfig(props.gameId, gameConfig);
    };

    return isHost ? (
        <div className={classes.configButtonRow}>
            <span className={classes.configButtonLabel}>Edit Game Settings</span>
            <span className={classes.configButton}>
                <SyncedGameConfigButton editConfig={editConfig} config={syncConfigProp} gameType={props.gameType} />
            </span>
        </div>
    ) : (
        <></>
    );
};
