import { Grid } from '@mui/material';
import * as React from 'react';
import assert from 'assert';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/auth/selectors';
import { NotOptional } from '../../../util/util';
import { Sync, syncDataItem } from '../../hoc/sync';
import { ClueGiver } from './ClueGiver/ClueGiver';
import { NotClueGiver } from './NotClueGiver/NotClueGiver';
import { TurnCommands } from './TurnCommands';
import { BaseFishbowlProps } from '../../models/game';
import db from '../../../back-end/databases';
import { FishbowlGame } from '@playtime/database/src/model/fishbowl';

interface InGameProps
    extends NotOptional<Pick<FishbowlGame, 'teamTurn' | 'round' | 'turn' | 'turnEndTime'>>,
        BaseFishbowlProps {
    playerTurn: number | undefined;
}

function findMyTurns(game: FishbowlGame, myUserId: string): Readonly<{ myTeamIndex: number; myPlayerIndex: number }> {
    const result = { myTeamIndex: -1, myPlayerIndex: -1 };
    result.myTeamIndex = game.teams.findIndex((team) => {
        result.myPlayerIndex = team.players.indexOf(myUserId);
        return result.myPlayerIndex >= 0;
    });
    assert(result.myTeamIndex >= 0 && result.myPlayerIndex >= 0, 'User not in this game');
    return result;
}

const GameContent: React.FunctionComponent<InGameProps> = (props) => {
    const user = useSelector(getUser);

    const SyncedClueGiver = Sync(ClueGiver);
    const SyncedNotClueGiver = Sync(NotClueGiver);
    const SyncedTurnCommands = Sync(TurnCommands);
    if (props.teamTurn === undefined || props.round === undefined || props.turn === undefined) {
        return <div>Loading active game...</div>;
    }
    const { myTeamIndex, myPlayerIndex } = findMyTurns(props.initialGameState, user.uid);
    const phase =
        props.round >= props.initialGameState.config.rounds.length
            ? 'gameOver'
            : props.teamTurn !== myTeamIndex
            ? 'standBy'
            : props.playerTurn === myPlayerIndex
            ? 'clueGiving'
            : 'guessing';

    const syncGame = syncDataItem(db.fishbowlGame, props.gameId);

    return (
        <>
            {phase === 'clueGiving' ? (
                <SyncedClueGiver
                    {...props}
                    {...syncGame.props('phrasePool', 'turnEndTime', 'pausedRemainingMs', 'skipsUsed', 'guessedPhrases')}
                />
            ) : (
                <SyncedNotClueGiver {...props} phase={phase} teams={syncGame.prop('teams')} />
            )}
            {(phase === 'clueGiving' || phase === 'guessing') && props.turnEndTime === undefined ? (
                <Grid item>
                    <SyncedTurnCommands {...props} phase={phase} />
                </Grid>
            ) : null}
        </>
    );
};

export default GameContent;
