import { Auth, User as FirebaseUser } from 'firebase/auth';
import { IStore } from '..';
import { User } from '@playtime/database/src';

export const checkForUser = (state: IStore): (FirebaseUser & Partial<User>) | null => {
    return state.auth.user;
};

export const getUser = (state: IStore): FirebaseUser & Partial<User> => {
    const user = state.auth.user;
    if (user == null) throw new Error('User not logged in');
    return user;
};

export const getFirebaseAuth = (state: IStore): Auth | null => {
    return state.auth.firebaseAuth;
};
