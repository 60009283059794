import * as React from 'react';
import { useSelector } from 'react-redux';
import { deletePhrase, enterPhrase } from '../../../back-end';
import { getUser } from '../../../store/auth/selectors';
import { Sync, syncDataItem, SyncPropEvents } from '../../hoc/sync';
import { BaseLobbyProps } from '../../Lobby/baseLobbyProps';
import { StartGame } from './StartGame';
import { PhraseInput } from './PhraseInput';
import db from '../../../back-end/databases';
import { GameType, LobbyGame } from '@playtime/database/src/model/lobby';

const Lobby: React.FunctionComponent<BaseLobbyProps> = (props) => {
    const user = useSelector(getUser);

    const SyncedPhraseInput = Sync(PhraseInput);
    const SyncedStartGame = Sync(StartGame);

    const syncedLobbyGame = syncDataItem(db.lobbyGame, props.gameId);
    const syncedConfig = syncedLobbyGame.prop('config');
    const syncedLobbyPlayer = syncedLobbyGame.prop('players').prop(user.uid);

    const localEnterPhrase = async (phrase: string) => {
        const result = await enterPhrase(props.gameId, user.uid, phrase);
        return result;
    };
    const localDeletePhrase = (phraseId: string) => deletePhrase(props.gameId, user.uid, phraseId);

    return (
        <>
            <br />
            <SyncedPhraseInput
                allPhrases={syncedLobbyPlayer.prop('phrases')}
                phraseCount={syncedConfig.prop('phrasesPerPlayer')}
                enterPhrase={localEnterPhrase}
                deletePhrase={localDeletePhrase}
            />
            <br />
            <SyncedStartGame
                {...props}
                host={syncedLobbyGame.prop('host')}
                players={syncedLobbyGame.prop('players') as SyncPropEvents<LobbyGame<GameType.Fishbowl>['players']>}
                numberOfTeams={syncedConfig.prop('numberOfTeams')}
                phraseCount={syncedConfig.prop('phrasesPerPlayer')}
            />
        </>
    );
};

export default Lobby;
