import { Paper, Grid, Icon, Typography, Box } from '@mui/material';
import * as React from 'react';
import { getColorSchemeRgb, getTeamName } from '../../../back-end';
import { useState } from 'react';
import { SyncedComponentProp } from '../../hoc/sync';
import { ActiveGame, Team } from '@playtime/database/src/model/activeGame';
import { makeStyles } from 'tss-react/mui';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const fontColor = (darkFont: boolean) => (darkFont ? '#666' : '#fff');

const useStyles = makeStyles<{
    isLeft: boolean;
    primaryColor: [number, number, number];
    secondaryColor?: [number, number, number];
    darkFont?: boolean;
}>()((_, { isLeft, primaryColor, secondaryColor, darkFont }) => {
    const rgbColors = getColorSchemeRgb(primaryColor, secondaryColor);
    const styles: Record<string, Record<string, string | number>> = {
        teamFlagContainer: {
            zIndex: 1,
            position: 'relative',
            flexGrow: 1,
        },
        teamFlag: {
            marginTop: '4px',
            boxShadow: '0 4px 10px 0 rgba(40,40,40,.45)',
            color: fontColor(darkFont ?? false),
            position: 'absolute',
            width: 'calc(100% + 23px)',
            zIndex: 3,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
        },
        teamNameText: {
            paddingLeft: '10px',
        },
        totalPoints: {
            backgroundImage: `linear-gradient( ${isLeft ? 135 : 45}deg, 
                rgb(${isLeft ? rgbColors.primary : rgbColors.secondary ?? rgbColors.primary}) 0%, 
                rgb(${rgbColors.intermediate ?? rgbColors.primary}) ${isLeft ? 70 : 30}%, 
                rgb(${isLeft ? rgbColors.secondary ?? rgbColors.primary : rgbColors.primary}) 100%)`,
            boxShadow: '0 4px 10px 0 rgba(40,40,40,.45)',
            color: fontColor(darkFont ?? false),
            fontWeight: 'bold',
            width: '50px',
            height: '50px',
            display: 'flex',
            position: 'relative',
            zIndex: 4,
            marginTop: '4px',
            alignItems: 'center',
            borderRadius: '50%',
            justifyContent: 'center',
        },
        rounds: {
            marginTop: -24,
            width: '44px',
            color: '#fff',
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,.5)',
            textAlign: 'center',
            paddingTop: 29,
        },
        decorationContainer: {
            width: '80%',
            marginTop: '-40px',
            paddingRight: '30.8px',
            paddingLeft: '48.4px',
        },
        decorationContainerReflect: {
            transform: 'scaleX(-1)',
        },
        decorationContainerBig: {
            width: '347px',
        },
        decorationContainerSmall: {
            width: '100%',
        },
    };
    styles.teamFlag[isLeft ? 'left' : 'right'] = '-23px';
    styles.teamFlag.padding = `2px ${isLeft ? 7 : 30}px 2px ${isLeft ? 30 : 7}px`;
    styles.teamFlag.backgroundImage = `linear-gradient( 90deg, 
        rgb(${isLeft ? rgbColors.primary : rgbColors.secondary ?? rgbColors.primary}) 0%, 
        rgb(${rgbColors.intermediate ?? rgbColors.primary}) ${isLeft ? 70 : 30}%, 
        rgb(${isLeft ? rgbColors.secondary ?? rgbColors.primary : rgbColors.primary}) 100%)`;
    styles.teamFlag.textAlign = isLeft ? 'start' : 'end';

    const opacity = 0.5;

    styles.rounds.backgroundImage = `linear-gradient( ${isLeft ? 135 : 45}deg, 
        rgba(${isLeft ? rgbColors.primary : rgbColors.secondary ?? rgbColors.primary},${opacity}) 0%, 
        rgba(${rgbColors.intermediate ?? rgbColors.primary},${opacity}) ${isLeft ? 70 : 30}%, 
        rgba(${isLeft ? rgbColors.secondary ?? rgbColors.primary : rgbColors.primary},${opacity}) 100%)`;
    if (!isLeft) styles.rounds.right = 2;
    return styles;
});

type ScoreBannerProps = {
    side: 'left' | 'right';
    teamName: string;
    team: SyncedComponentProp<Team & { scoreDisplay: number }>;
    roundIcons?: string[];
} & (
    | {
          TeamRoster: undefined;
      }
    | {
          TeamRoster: React.FunctionComponent<ScoreBannerTeamRosterProps>;
          gameId: string;
      }
);

export interface ScoreBannerTeamRosterProps {
    gameId: string;
    isLeft: boolean;
    primaryColor: [number, number, number];
    secondaryColor?: [number, number, number];
    team: ActiveGame['teams'][0];
}

const cache: Record<string, { showRoster: boolean; showRounds: boolean }> = {};
export const ScoreBanner: React.FunctionComponent<ScoreBannerProps> = (props) => {
    const isLeft = props.side === 'left';
    cache[props.teamName] = cache[props.teamName] ?? { showRoster: false, showRounds: false };
    const [showRoster, setShowRoster] = useState(cache[props.teamName].showRoster);
    const [showRounds, setShowRounds] = useState(cache[props.teamName].showRounds);
    //const isBig = useMediaQuery('(min-width:731px)');

    if (!props.team) return null;
    const { classes } = useStyles({
        isLeft,
        primaryColor: props.team?.primaryColor,
        secondaryColor: props.team?.secondaryColor,
        darkFont: props.team?.darkFont,
    });
    // const decorationContainerSizeClass = isBig ? classes.decorationContainerBig : classes.decorationContainerSmall;
    // const decorationContainerTransform = isLeft ? '' : classes.decorationContainerReflect;

    const toggleRounds = () => {
        setShowRounds(!showRounds);
        cache[props.teamName].showRounds = !showRounds;
    };
    const toggleRoster = () => {
        setShowRoster(!showRoster);
        cache[props.teamName].showRoster = !showRoster;
    };

    const orbDecoration = null;
    // (
    //     <Box position={'absolute'} zIndex={5} width={75} marginLeft={'-13px'} marginTop={'-10px'}>
    //         <img src="./decorations/christmas/orb.png" color="white" alt="" width="100%" height="100%" />
    //     </Box>
    // );
    const getTeamNamePadding = () => {
        if (orbDecoration) {
            return '4px';
        }
        return isLeft ? '-4px' : '12px';
    };

    const orb = (
        <Grid item sx={{ position: 'relative' }}>
            {orbDecoration}
            <Box
                className={classes.totalPoints}
                sx={{ cursor: props.roundIcons?.length ? 'pointer' : undefined }}
                onClick={toggleRounds}
            >
                <Typography variant="h5">{props.team.scoreDisplay}</Typography>
            </Box>
            {props.roundIcons?.length && (
                <Icon
                    onClick={toggleRounds}
                    component={showRounds ? KeyboardArrowUp : KeyboardArrowDown}
                    sx={{
                        zIndex: 5,
                        position: 'absolute',
                        right: '13px',
                        bottom: '-5px',
                        color: fontColor(props.team?.darkFont ?? false),
                        cursor: 'pointer',
                    }}
                />
            )}
            {props.roundIcons?.length && showRounds && (
                <Paper className={classes.rounds}>
                    {props.roundIcons.map((roundIcon, index) => (
                        <span key={roundIcon}>
                            <Icon fontSize="small">{roundIcon}</Icon>
                            &nbsp;
                            <span style={{ position: 'relative', top: -5 }}>{props.team?.scores[index]}</span>
                            <br />
                        </span>
                    ))}
                </Paper>
            )}
        </Grid>
    );
    const TeamRoster = props.TeamRoster;
    const flag = (
        <Grid item className={classes.teamFlagContainer}>
            <Paper onClick={toggleRoster} elevation={2} className={classes.teamFlag}>
                <Typography
                    sx={{
                        width: 'calc(100% - 10px)',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        marginLeft: getTeamNamePadding(),
                    }}
                >
                    {getTeamName(props.team)}
                </Typography>
            </Paper>
            {TeamRoster && (
                <Icon
                    component={showRoster ? KeyboardArrowUp : KeyboardArrowDown}
                    onClick={toggleRoster}
                    sx={{
                        zIndex: 3,
                        position: 'absolute',
                        right: isLeft ? '-1px' : undefined,
                        left: isLeft ? undefined : '-1px',
                        bottom: '20px',
                        color: fontColor(props.team?.darkFont ?? false),
                        cursor: 'pointer',
                    }}
                />
            )}
            {!showRoster || !TeamRoster ? null : (
                <TeamRoster
                    gameId={props.gameId}
                    team={props.team}
                    isLeft={isLeft}
                    primaryColor={props.team?.primaryColor}
                    secondaryColor={props.team?.secondaryColor}
                />
            )}
        </Grid>
    );

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Grid container style={{ width: '100%' }}>
                {isLeft ? (
                    <>
                        {orb}
                        {flag}
                    </>
                ) : (
                    <>
                        {flag}
                        {orb}
                    </>
                )}
            </Grid>

            {/* <Box
                className={`${classes.decorationContainer}  ${decorationContainerTransform} ${decorationContainerSizeClass}`}
                marginLeft={!isLeft ? 'auto' : undefined}
            >
                <img src="./decorations/christmas/banner.png" color="white" alt="" width="100%" height="50%" />
            </Box> */}
        </Box>
    );
};
