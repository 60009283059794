import React from 'react';
import { Container, Grid, TextField, Button } from '@mui/material';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { setFirebaseUser } from '../../store/auth/actions';
import { createSingleUseAccount } from '../../back-end';
import { HOME_ROUTE } from '../router/routing';
import { useSelector } from 'react-redux';
import { getFirebaseAuth } from '../../store/auth/selectors';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
}));

const SingleSignOn: React.FunctionComponent = () => {
    const [singleSignOnUsername, setSingleSignOnUsername] = useState<string>('');
    const [isSingleSignOnSubmitted, setIsSingleSignOnSubmitted] = useState<boolean>(false);
    const firebaseAuth = useSelector(getFirebaseAuth);
    const { classes } = useStyles();
    const history = useHistory();
    const location = useLocation<{ from: string }>();
    const { from } = location.state || { from: { pathname: HOME_ROUTE } };
    if (!firebaseAuth) return null;
    const redirectBack = () => {
        history.replace(from);
    };

    const isSingleSignOnUsernameInvalid = () => {
        return singleSignOnUsername === '' || singleSignOnUsername === null || singleSignOnUsername === undefined;
    };

    const createSingleSignOnAccount = async () => {
        setIsSingleSignOnSubmitted(true);
        if (isSingleSignOnUsernameInvalid()) return;

        const user = await createSingleUseAccount(firebaseAuth, singleSignOnUsername);
        setFirebaseUser(user);
        redirectBack();
    };

    const checkForEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            createSingleSignOnAccount();
        }
    };

    const singleSignOnHelperText = () => {
        if (!isSingleSignOnSubmitted) return '';
        if (isSingleSignOnUsernameInvalid()) return 'Username must be at least one character!';
        return '';
    };

    return !firebaseAuth ? null : (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Grid container justifyContent="center" spacing={1}>
                    <Grid item>
                        <TextField
                            label="Username"
                            variant="outlined"
                            size="small"
                            value={singleSignOnUsername}
                            error={isSingleSignOnSubmitted && isSingleSignOnUsernameInvalid()}
                            helperText={singleSignOnHelperText()}
                            onChange={(e) => setSingleSignOnUsername(e.target.value)}
                            onKeyDown={checkForEnter}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={createSingleSignOnAccount}>
                            Create
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

export default SingleSignOn;
