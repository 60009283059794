import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTeamForPlayer } from '../../../back-end';
import db from '../../../back-end/databases';
import { getUser } from '../../../store/auth/selectors';
import { Sync, syncDataItem } from '../../hoc/sync';
import { BaseFishbowlProps } from '../../models/game';
import GameContent from './GameContent';
import { GameHeader } from './GameHeader/GameHeader';

export const InGame: React.FunctionComponent<BaseFishbowlProps> = (props) => {
    const user = useSelector(getUser);

    const myTeam = getTeamForPlayer(props.initialGameState.teams, user.uid);
    const SyncedGameContent = Sync(GameContent);
    const SyncedGameHeader = Sync(GameHeader);

    const syncGame = syncDataItem(db.fishbowlGame, props.gameId);
    const playerTurn = syncGame.prop('teams').prop(myTeam.index).prop('playerTurn');

    return (
        <Grid container alignItems="stretch" direction="column" spacing={2} style={{ height: '100%' }}>
            <SyncedGameHeader {...props} {...syncGame.props('teamTurn', 'teams', 'players')} playerTurn={playerTurn} />
            <SyncedGameContent
                {...props}
                {...syncGame.props('round', 'teamTurn', 'turn', 'turnEndTime')}
                playerTurn={playerTurn}
            />
        </Grid>
    );
};
