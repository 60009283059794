import {
    GameResults,
    CodenamesResults,
    Result,
    success,
    mergeResults,
    inTranDefault,
    failure,
    StatsType,
    Inventory,
    inTranIfDefined,
} from '@playtime/database/src';
import * as core from '../business/store';
import * as starDust from '../business/pure/starDust';
import db from '../databases';
import { PremiumTeam, defaultUser, premiumTeams } from './lobby';

export async function addStarDust(gameResults: GameResults<CodenamesResults>): Promise<Result> {
    const starDustAmounts = starDust.getStarDustAmount(gameResults);
    return mergeResults(
        await Promise.all(
            Object.keys(gameResults).map(
                async (userId) =>
                    await inTranDefault(
                        db.users,
                        userId,
                        (user) => core.addStarDust(user, starDustAmounts[userId]),
                        defaultUser
                    )
            )
        )
    );
}

export async function buyTeamBanner(userId: string, teamBannerName: string) {
    const banner = premiumTeams[teamBannerName];
    if (!banner) return failure(`cannot buy banner, "${teamBannerName}": does not exist`);
    const userStats = await db.playerStats.fetch(userId);
    const bestElo = userStats ? Math.max(userStats[StatsType.Fishbowl].elo, userStats[StatsType.Codenames].elo) : 0;
    if (bestElo < (banner.rating ?? 0))
        return failure(`cannot buy banner, "${teamBannerName}": not high enough rating`);
    const buyTeamBannerResults = await inTranDefault(
        db.users,
        userId,
        (user) => core.buyTeamBanner(user, banner),
        defaultUser
    );
    if (!buyTeamBannerResults.success) return buyTeamBannerResults;
    return applyBannerToExistingGames(userId, banner);
}

export async function getPlayersTeamBanners(
    players: string[]
): Promise<Record<string, Inventory['teamBanners'] | undefined>> {
    return Object.fromEntries(
        await Promise.all(
            players.map(async (playerId) => [
                playerId,
                await db.users.property(playerId, 'inventory').fetch('teamBanners'),
            ])
        )
    );
}

async function applyBannerToExistingGames(userId: string, banner: PremiumTeam) {
    const userGameIds = await db.users.property(userId, 'games').get();
    if (!userGameIds) return success();
    return mergeResults(
        await Promise.all(
            userGameIds.reduce<Promise<Result>[]>(
                (promises, gameAssignment) =>
                    promises.concat([
                        inTranIfDefined(db.fishbowlGame, gameAssignment.id, (game) =>
                            core.applyBannerToExistingGame(userId, game, banner)
                        ),
                        inTranIfDefined(db.codenamesGame, gameAssignment.id, (game) =>
                            core.applyBannerToExistingGame(userId, game, banner)
                        ),
                    ]),
                []
            )
        )
    );
}
