import * as core from '../../business/fishbowl/fishbowlStats';
import * as commonApi from '../playerStats';
import { convertPhraseToKey } from '../../business/pure/pure';
import { defaultPlayerStats, PlayerOutcome } from '../playerStats';
import { getTimeOffset } from '../timeSync';
import db from '../../databases';
import { Result, inTranDefault, success, mergeResults } from '@playtime/database';
import { GameType } from '@playtime/database/src/model/lobby';
import { FishbowlStats } from '@playtime/database/src/model/stats';

export const STARTING_ELO = 1200;
export const PLACEHOLDER_ID_PREFIX = 'placeholder';
export const defaultFishbowlStats = (): FishbowlStats => ({
    phrases: {},
    guessedCount: 0,
    passedCount: 0,
    contestCount: 0,
    timeGivingClues: 0,
    contestedByOthersCount: 0,
    winCount: 0,
    lossCount: 0,
    tieCount: 0,
    elo: STARTING_ELO,
    games: [],
});

export async function savePhrases(uid: string, phrases: string[]) {
    const promises: Promise<Result>[] = [];
    for (const phrase of phrases) {
        const phraseKey = convertPhraseToKey(phrase);
        promises.push(
            inTranDefault(
                db.playerStats,
                uid,
                (playerStats) => {
                    playerStats.fishbowlStats.phrases = playerStats.fishbowlStats.phrases ?? {};
                    playerStats.fishbowlStats.phrases[phraseKey] = playerStats.fishbowlStats.phrases[phraseKey] ?? {
                        phrase: phraseKey,
                        count: 0,
                    };
                    playerStats.fishbowlStats.phrases[phraseKey].count++;
                    return success();
                },
                defaultPlayerStats()
            )
        );
    }
    return mergeResults(await Promise.all(promises));
}

export async function guessed(uid: string) {
    return inTranDefault(
        db.playerStats,
        uid,
        (playerStats) => {
            playerStats.fishbowlStats.guessedCount++;
            return success();
        },
        defaultPlayerStats()
    );
}

export async function passed(uid: string) {
    return inTranDefault(
        db.playerStats,
        uid,
        (playerStats) => {
            playerStats.fishbowlStats.passedCount++;
            return success();
        },
        defaultPlayerStats()
    );
}

export async function timeTook(uid: string, timeTaken: number): Promise<Result> {
    return inTranDefault(
        db.playerStats,
        uid,
        (playerStats) => {
            playerStats.fishbowlStats.timeGivingClues += timeTaken;
            return success();
        },
        defaultPlayerStats()
    );
}

export async function contested(uid: string) {
    return inTranDefault(
        db.playerStats,
        uid,
        (playerStats) => {
            playerStats.fishbowlStats.contestCount++;
            return success();
        },
        defaultPlayerStats()
    );
}

export async function contestedByOthers(uid: string) {
    return inTranDefault(
        db.playerStats,
        uid,
        (playerStats) => {
            playerStats.fishbowlStats.contestedByOthersCount++;
            return success();
        },
        defaultPlayerStats()
    );
}

export type FinalClueGiver =
    | {
          id: string;
          secondsPerTurn: number;
          turnEndTime: number;
      }
    | {
          id: string;
          timeTaken: number;
      };

export async function gameOver(
    playersOutcome: PlayerOutcome[],
    playersNewElo: Record<string, number>,
    finalClueGiver: FinalClueGiver,
    historicalGameId: string
) {
    const commonGameOverResults = await commonApi.gameOver(
        playersOutcome,
        playersNewElo,
        historicalGameId,
        GameType.Fishbowl
    );
    const timeTaken = core.getTimeTaken(finalClueGiver, await getTimeOffset());
    const timeTookResults = await timeTook(finalClueGiver.id, timeTaken);

    return mergeResults([commonGameOverResults, timeTookResults]);
}
