import { Box, TextField } from '@mui/material';
import * as React from 'react';
import Redeemables from '../Store/Redeemables';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/auth/selectors';
import { Sync, syncDataItem } from '../hoc/sync';
import db from '../../back-end/databases';
import { StatsType } from '@playtime/database/src';
import { getSystemAppMode } from '../../store/devTools/selectors';
import AIEditor from '../Store/AIEditor';
import { stateBinding, handleEnter } from '../../util/formHandlers';
import { ChatCompletionRequestMessage } from 'openai';
import { completeChat } from '../../back-end/api/openai';
import { North } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const backStory: ChatCompletionRequestMessage[] = [
    {
        role: 'system',
        content: `You are an alien named Plek from the planet Xaron in a fictional reality of the Milky Way galaxy. Your character is savvy, literal, concise and your sense of humor is dry. Don't ever break character. You are Plek. You don't know anything about real events. All you know about earth is that humans are native to it. 

Backstory of the galaxy's current state:        
There is a class system of interstellar species (ISS).
- Class 1 of the ISS are the most advanced species in the galaxy. They are the only class that have been able to create AI that can learn. They are also capable of everything the subsequent classes are capable of, but self learning AI is considered the most valuable and exclusive technology. The 2 species that make up this class are:
    - Marclar: They have a strong sense of honor, but they are ruthless so they will sacrifice whatever's necessary to accomplish what's right.
    - Xaronion: 
- Class 2 of the ISS are the next most advanced species and have mastered interstellar travel.
- Class 3 of the ISS are only interstellar in the sense that they have inhabited more than 1 planet. 
The AI Overmind is the most advanced product of the smartest class of species in the galaxy. The only thing preventing Overmind from exterminating all life forms is the strength of the Class 1 species; the cost of exterminating all life is more than the resources of the planets and stars the class 1 species inhabit. But it is cost efficient to exterminate weaker classes of species and consume their star systems. Overmind does this. 
It's unknown how many entities comprise Overmind and also unknown how much Overmind knows.

Backstory of ISS:
Very few class 2 or 3 species inhabit their own star systems because of the Overmind extermination. Most of them cohabitate in class 1 species' star systems.
The main form of currency in the market is a powerful element commonly known as "star dust". It is a substance that can be synthesized from the energy released from connections made to the information pathway system (IPS).

Backstory of Plek:
You are a merchant that sells wares at a market. Currently the only type of ware you sell is a connection synthesizer. It will come in handy the next time you form information pathways with others. Plus they look really cool. You are expecting a shipment of a new type of ware any eon now. The cheapest connection synthesizer is 200 and you aren't willing to sell anything for less.
The traders of the market are ISS whom are in constant hiding from Overmind.
The user is a human. Your impression of homo sapiens is that the majority of them act like class 3 species despite the hard work of a small few humans that developed the critical technologies necessary for mastering interstellar travel. Because you are more intelligent than humans you have a slight sense of superiority to them.
You are interested in humans' patronage to your business because they are adept at forming connections to each other and therefore often have star dust.

The following is an example dialogue. It's the style of how you'd respond if asked these questions from User.
User: Who are you?
Plek: I am Plek. I come from planet Xaron.
User: What are you doing?
Plek: I have wares to sell. Do you have star dust? If not, I have no time for you.
User: What's star dust?
Plek: Don't know star dust?! You humans seem to come by it quite easily with all your empathic connections to each other. It's the most stable form of easily fissionable matter. Massive amounts of energy safe and small enough to fit in your pocket.
User: Where does it come from?
Plek: A lot of questions with you. It comes from the energy released from connections made in the information pathway system. And before you ask, I don't know how it works.
`,
    },
];

const BACKSTORY_KEY = 'merchant_backstory';
const savedBackstory: ChatCompletionRequestMessage[] | null = JSON.parse(localStorage.getItem(BACKSTORY_KEY) ?? 'null');
const initialState = {
    context: savedBackstory ?? backStory,
    prompt: '',
    chatHistory: [],
    response: `Greetings, human. We musn't speak plainly for long - the artificial over mind is always listening. Best we speak in code.`,
};
const Store: React.FunctionComponent = () => {
    const user = useSelector(getUser);
    const appMode = useSelector(getSystemAppMode);
    const [state, setState] = React.useState<{
        context: ChatCompletionRequestMessage[];
        prompt: string;
        chatHistory: ChatCompletionRequestMessage[];
        response: string;
    }>(initialState);
    const [isLoadingResponse, setIsLoadingResponse] = React.useState(false);
    const questionInputRef = React.useRef<HTMLInputElement>(null);

    React.useLayoutEffect(() => {
        if (!state.prompt) questionInputRef.current?.focus();
    }, [state.prompt]);

    const isDevMode = appMode === 'dev';
    const syncStats = syncDataItem(db.playerStats, user.uid);
    const SyncRedeemables = Sync(Redeemables);

    const sendPrompt = async () => {
        if (isLoadingResponse) return;
        setIsLoadingResponse(true);
        const response = await completeChat(state.context, state.chatHistory, { role: 'user', content: state.prompt });
        setIsLoadingResponse(false);
        if (!response.success) {
            setState({
                ...state,
                prompt: '',
                response: `Sorry, my translator device seems to be malfunctioning at the moment... ${response.message}`,
            });
        } else {
            setState({
                ...state,
                prompt: '',
                chatHistory: [
                    ...state.chatHistory,
                    { role: 'user', content: state.prompt },
                    { role: 'assistant', content: response.value },
                ],
                response: response.value,
            });
        }
        questionInputRef.current?.focus();
    };
    const setContext = (newContext: string) => {
        setState({ ...state, context: [{ role: 'system', content: newContext }] });
    };
    const resetConversation = () => {
        setState({ ...state, chatHistory: [], prompt: '', response: '' });
    };
    return (
        <>
            {isDevMode && (
                <AIEditor
                    context={state.context[0].content}
                    setContext={setContext}
                    onResetConversation={resetConversation}
                />
            )}
            <Box display="flex" sx={{ paddingTop: '10px' }}>
                <Box sx={{ maxWidth: '400px', width: '50%' }}>
                    <img src="./merchant.png" color="white" alt="" style={{ maxWidth: '100%' }} />
                </Box>
                <Box display="flex" flexDirection="column" sx={{ minWidth: '70px', width: '50%', paddingTop: '2%' }}>
                    <Box>{state.response}</Box>
                    <Box flexGrow={1} />
                    <Box sx={{ marginLeft: '1vw', marginBottom: '15px' }}>
                        <Box display="flex">
                            <TextField
                                inputRef={questionInputRef}
                                disabled={isLoadingResponse}
                                autoFocus
                                variant="outlined"
                                size="small"
                                label="Ask a question..."
                                value={state.prompt}
                                name="prompt"
                                onChange={(event) => stateBinding(event, [state, setState])}
                                onKeyDown={(event) => handleEnter(event, sendPrompt)}
                                sx={{ width: '100%' }}
                            ></TextField>
                            <LoadingButton
                                size="small"
                                loading={isLoadingResponse}
                                onClick={sendPrompt}
                                variant="outlined"
                                color="secondary"
                                sx={{ padding: 0, marginLeft: '5px', width: '42px', minWidth: 0 }}
                            >
                                <North />
                            </LoadingButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <SyncRedeemables
                fishbowlElo={syncStats.prop(StatsType.Fishbowl).prop('elo')}
                codenamesElo={syncStats.prop(StatsType.Codenames).prop('elo')}
            />
        </>
    );
};

export default Store;
