import assert from 'assert';

export const swap = <T>(arr: T[], i: number, j: number): void => {
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
};

export const shuffle = <T>(arr: T[]): T[] => {
    for (let i = arr.length - 1; i > 0; i--) {
        const pickIx = Math.floor(Math.random() * (i + 1));
        swap(arr, i, pickIx);
    }
    return arr;
};

/**
 * gets count shuffled items from the passed arr
 * @param arr the array to shuffle and take count from
 * @param count how many elements to take from the array
 * @returns a subset of count shuffled arr elements
 */
export const shuffleTake = <T>(arr: T[], count: number): T[] => {
    assert(count <= arr.length, 'count should be less than or equal to the length of arr');
    const shuffled = [];
    for (let i = 0; i < count; i++) {
        const pickIx = i + Math.floor(Math.random() * (arr.length - i));
        swap(arr, i, pickIx);
        shuffled.push(arr[i]);
    }
    return shuffled;
};

export type GenericFunction = (...args: unknown[]) => void;
export type ImmutablePrimitives = undefined | null | boolean | string | number | GenericFunction;
export type Immutable<T> = T extends ImmutablePrimitives
    ? T
    : T extends (infer A)[]
    ? ReadonlyArray<Immutable<A>>
    : T extends Map<infer K, infer V>
    ? ReadonlyMap<Immutable<K>, Immutable<V>>
    : T extends Set<infer S>
    ? ReadonlySet<Immutable<S>>
    : { readonly [K in keyof T]: Immutable<T[K]> };

export function impossible(obj: never, message?: string): never {
    throw Error(message ?? `Impossible value: ${obj}`);
}

export type Maybe<T> = T extends Record<string, ImmutablePrimitives> ? Partial<T> : { [K in keyof T]?: Maybe<T[K]> };

export type NotOptional<T> = { [K in keyof Required<T>]: T[K] extends undefined ? T[K] | undefined : T[K] };

/**
 * Get index of array as cyclic, supporting backwards counting.
 * Returns -1 for empty arrays.
 */
export function cyclicIndex(array: readonly unknown[], index: number): number {
    if (array.length < 1) {
        return -1;
    }
    if (index < 0) {
        return array.length + ((index + 1) % array.length) - 1;
    }
    return index % array.length;
}

/** Get item out of array, but treat the array as cyclic, and support backwards counting */
export function getCyclic<T>(array: readonly T[], index: number): T {
    return array[cyclicIndex(array, index)];
}

export function consolidateSpaces(value: string): string {
    return value
        .trim()
        .split(' ')
        .filter((part) => part)
        .join(' ');
}

const escapeMap: ReadonlyMap<string, string> = new Map([
    ['.', '\\d'],
    ['#', '\\h'],
    ['$', '\\m'],
    ['/', '\\s'],
    ['[', '\\o'],
    [']', '\\c'],
    ['\\', '\\\\'],
]);
export function escapeFirebaseKey(key: string): string {
    let result = '';
    for (let i = 0; i < key.length; i++) {
        result += escapeMap.get(key[i]) ?? key[i];
    }
    return result;
}

/** Returns a new array with arr[i] = rounded average of arr1[i] and arr2[i] or no average if one array has no corresponding i entry.  */
export function getAverage(arr1: readonly number[], arr2: readonly number[]): number[] {
    const [longerArray, shorterArray] = arr1.length > arr2.length ? [arr1, arr2] : [arr2, arr1];
    return longerArray.map((x, i) => (shorterArray.length > i ? Math.round((x + shorterArray[i]) / 2) : x));
}

export function deepClone<T extends Record<string, unknown>>(item: Immutable<T>): T {
    const newItem: Record<string, unknown> = {};
    Object.entries(item).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
            newItem[key] = deepClone(value);
        } else {
            newItem[key] = value;
        }
    });

    return newItem as T;
}

export function filterObject<T>(obj: Record<string, T>, condition: (entry: [string, T]) => boolean): Record<string, T> {
    return Object.fromEntries(Object.entries(obj).filter((entry) => condition(entry)));
}

export function mapObject<T, M>(obj: Record<string, T>, mapping: (entry: [string, T]) => M): Record<string, M> {
    return Object.fromEntries(Object.entries(obj).map((entry) => [entry[0], mapping(entry)]));
}

export function propertiesMatch(obj1: Record<string, unknown>, obj2: Record<string, unknown>): boolean {
    const obj1Keys = Object.keys(obj1);
    return obj1Keys.every((key) => key in obj2) && obj1Keys.length === Object.keys(obj2).length;
}

export function push<T>(arr: T[], item: T, count: number): void {
    for (let i = 0; i < count; i++) arr.push(item);
}

export function isNullOrUndefined<T>(item: T | null | undefined): item is null | undefined {
    return item === null || item === undefined;
}
