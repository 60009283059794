import * as types from './types';
import store from '..';
import { Immutable } from '../../util/util';
import { LobbyGame, User } from '@playtime/database/src/model/lobby';

export const setGame = (gameId?: string, game?: Partial<Immutable<LobbyGame>>, games?: User['games']): void => {
    store.store.dispatch({
        type: types.SET_GAME,
        id: gameId ?? null,
        game: gameId ? game ?? null : null,
        games: games ?? null,
    });
};
