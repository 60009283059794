import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BottomNavigation, BottomNavigationAction, Badge } from '@mui/material';
import { Box } from '@mui/material';
import { GamesSelection } from '../Home/GameSelection/GamesSelection';
import MyGames from '../Home/MyGames/MyGames';
import { AddBox, GridOn } from '@mui/icons-material';
import { getGame } from '../../store/game/selectors';
import { getSystemAppMode, getSystemDbMode } from '../../store/devTools/selectors';
import { Handles } from '../../util/handles';
import { filterObject } from '../../util/util';
import db from '../../back-end/databases';
import { ActiveGame } from '@playtime/database/src/model/activeGame';
import { JoinableActiveGame } from '@playtime/database/src/model/joinableActiveGame';
import { Games, LobbyGame, GameType } from '@playtime/database/src/model/lobby';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    linkNoFormat: {
        textDecoration: 'none',
    },
    selected: {
        color: theme.palette.secondary.main,
        '& svg': {
            color: theme.palette.secondary.main,
        },
    },
    fullScreenWidth: {
        position: 'fixed',
        marginLeft: 'auto',
        marginRight: 'auto',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    container: {
        paddingBottom: 60,
    },
}));

enum HomePageTabs {
    MyGames,
    NewGames,
}

const Home: React.FunctionComponent = () => {
    const game = useSelector(getGame);
    const { classes } = useStyles();
    const systemDbMode = useSelector(getSystemDbMode);
    const systemMode = useSelector(getSystemAppMode);
    const isDev = systemMode === 'dev';
    const [lobbyGames, setLobbyGames] = useState<Games<LobbyGame>>({});
    const [joinableActiveGames, setJoinableActiveGames] = useState<Games<JoinableActiveGame>>({});
    const [homePageTab, setHomePageTab] = useState<HomePageTabs>(HomePageTabs.MyGames);
    const [activeGames, setActiveGames] = useState<Games<ActiveGame>>({});

    useEffect(() => {
        const handles = new Handles(
            db.lobbyGame.listenAll((games) => setLobbyGames(games ?? {})),
            db.joinableActiveGames.listenAll((games) => setJoinableActiveGames(games ?? {}))
        );
        if (!isDev) return handles.stop;
        handles.add(
            db.fishbowlGame.listenAll((games) =>
                setActiveGames((curActiveGames) => {
                    const notFishbowlGames = filterObject(
                        curActiveGames,
                        ([, activeGame]) => activeGame.gameType !== GameType.Fishbowl
                    );
                    return { ...(games ?? {}), ...notFishbowlGames };
                })
            )
        );
        handles.add(
            db.codenamesGame.listenAll((games) =>
                setActiveGames((curActiveGames) => {
                    const notCodenamesGames = filterObject(
                        curActiveGames,
                        ([, activeGame]) => activeGame.gameType !== GameType.Codenames
                    );
                    return { ...(games ?? {}), ...notCodenamesGames };
                })
            )
        );
        return handles.stop;
    }, [systemMode, systemDbMode]);

    const joinableGames = { ...lobbyGames, ...joinableActiveGames };

    const hasTurnBasedGames = !!game?.games?.length;
    const homePageContent = () => {
        if (!hasTurnBasedGames || homePageTab === HomePageTabs.NewGames)
            return (
                <GamesSelection
                    lobbyGames={lobbyGames}
                    joinableActiveGames={joinableActiveGames}
                    activeGames={activeGames}
                />
            );
        return <MyGames games={game?.games} />;
    };
    const belongsToGame = (gameId: string) => (game?.games ?? []).find((game) => game.id === gameId);
    return (
        <>
            <Box display="flex" justifyContent="space-around" className={hasTurnBasedGames ? classes.container : ''}>
                {homePageContent()}
            </Box>

            {hasTurnBasedGames && (
                <BottomNavigation
                    value={homePageTab}
                    onChange={(_, newValue) => {
                        setHomePageTab(newValue);
                    }}
                    showLabels
                    className={classes.fullScreenWidth}
                >
                    <BottomNavigationAction
                        label="My Games"
                        icon={<GridOn />}
                        value={HomePageTabs.MyGames}
                        classes={{ selected: classes.selected }}
                    />
                    <BottomNavigationAction
                        label="Join/New Games"
                        icon={
                            <Badge
                                overlap="rectangular"
                                badgeContent={
                                    Object.keys(filterObject(joinableGames, ([id]) => !belongsToGame(id))).length
                                }
                                color="secondary"
                            >
                                <AddBox />
                            </Badge>
                        }
                        value={HomePageTabs.NewGames}
                        classes={{ selected: classes.selected }}
                    />
                </BottomNavigation>
            )}
        </>
    );
};

export default Home;
