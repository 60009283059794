import * as types from './types';
import { AnyAction } from 'redux';
import { Immutable } from '../../util/util';
import { LobbyGame, User } from '@playtime/database/src/model/lobby';

export interface GameState {
    id: string | null;
    game: Partial<Immutable<LobbyGame>> | null;
    games: User['games'] | null;
}
const initialState: GameState = {
    id: null,
    game: null,
    games: null,
};

const game = (state = initialState, action: AnyAction): GameState => {
    switch (action.type) {
        case types.SET_GAME:
            return {
                ...state,
                id: action.id,
                game: action.game,
                games: action.games,
            };
    }
    return state;
};

export default game;
