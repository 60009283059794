import assert from 'assert';
import {
    child,
    DatabaseReference,
    onValue,
    DataSnapshot,
    off,
    runTransaction,
    get,
    push,
    set,
    remove,
} from 'firebase/database';
import {
    Database,
    DataEventHandler,
    DatabaseEvents,
    DataEvents,
    DataCollectionType,
    DataCollection,
    ChildDatabasePropertyEvents,
    ValidProperty,
    ValueOfProperty,
} from './contract';
import { Reference, DataSnapshot as adminDataSnapshot } from '@firebase/database-types';

export interface Handle {
    /** Unsubscribe */
    stop(): void;
}

export class Handles implements Handle {
    // TODO: this class can be made more generic
    public static join(...handles: Handle[]): Handle {
        return { stop: new Handles(...handles).stop };
    }
    private readonly handles: Handle[] = [];
    constructor(...initialHandles: Handle[]) {
        this.handles = [...initialHandles];
    }
    public add(handle: Handle): Handle {
        this.handles.push(handle);
        if (this.handles.length > 100 && this.handles.length % 100 === 0) {
            console.log(`warning: large number of unsubscribe handles: ${this.handles.length}+`);
        }
        return handle;
    }
    public readonly stop = (): void => {
        while (this.handles.length > 0) {
            try {
                this.handles.pop()?.stop();
            } finally {
                // nothing
            }
        }
    };
}

function isDatabaseReference(ref: DatabaseReference | Reference): ref is DatabaseReference {
    return !('child' in ref);
}

// export function firebaseRoot() {
//     const systemDbMode = getSystemDbMode(store.store.getState());
//     const firebaseApp = getFirebaseApp(store.store.getState());
//     assert(firebaseApp, "firebase hasn't loaded yet");
//     return ref(getDatabase(firebaseApp), systemDbMode);
// }

export function firebaseListenRef<T>(ref: DatabaseReference | Reference, handler: DataEventHandler<T>): Handle {
    const wrappedHandler = (snapshot: DataSnapshot | adminDataSnapshot) => handler(snapshot.val() ?? undefined);
    if (isDatabaseReference(ref)) {
        onValue(ref, wrappedHandler);
        return {
            stop() {
                off(ref, 'value', wrappedHandler);
            },
        };
    }
    ref.on('value', wrappedHandler);
    return {
        stop() {
            ref.off('value', wrappedHandler);
        },
    };
}

const firebaseDatabases: FirebaseDatabaseEvents<unknown, string>[] = [];

/** Clear all listeners from all databases. */
export function resetDatabases(): void {
    for (const database of firebaseDatabases) {
        database.removeAllListeners();
    }
}

class PropertyHelper<B, T> implements ChildDatabasePropertyEvents<T> {
    constructor(
        private readonly base: FirebaseDatabaseEvents<B>,
        private readonly baseKey: string,
        private readonly path: string
    ) {}

    public property<P extends ValidProperty<T>>(property: P) {
        return new PropertyHelper<B, ValueOfProperty<T, P>>(
            this.base,
            this.baseKey,
            `${this.path}/${String(property)}`
        );
    }

    async fetch<P extends ValidProperty<T>>(property: P): Promise<ValueOfProperty<T, P> | undefined> {
        const ref = this.base.childRef(this.baseKey, `${this.path}/${String(property)}`);
        return (isDatabaseReference(ref) ? await get(ref) : await ref.get()).val() ?? undefined;
    }

    async get(): Promise<T> {
        const ref = this.base.childRef(this.baseKey, `${this.path}`);
        return (isDatabaseReference(ref) ? await get(ref) : await ref.get()).val() ?? {};
    }

    public readonly listen = (handler: DataEventHandler<T>) =>
        this.base.node<T>(this.baseKey, this.path).listen(handler);
}

export class FirebaseDatabaseEvents<
    T,
    K extends string = string,
    C extends DataCollectionType = Record<string, unknown> | []
> implements DatabaseEvents<T, K>
{
    constructor(
        protected readonly getFirebaseRoot: () => DatabaseReference | Reference,
        protected readonly basePath: string
    ) {
        firebaseDatabases.push(this);
    }
    private readonly unsubscribeHandles = new Handles();

    protected baseRef(): DatabaseReference | Reference {
        const rootRef = this.getFirebaseRoot();
        return isDatabaseReference(rootRef) ? child(rootRef, this.basePath) : rootRef.child(this.basePath);
    }

    public ref(key: K): DatabaseReference | Reference {
        const baseRef = this.baseRef();
        return isDatabaseReference(baseRef) ? child(baseRef, `${key}`) : baseRef.child(`${key}`);
    }

    public childRef(key: K, childPath: string): DatabaseReference | Reference {
        const ref = this.ref(key);
        return isDatabaseReference(ref) ? child(ref, childPath) : ref.child(childPath);
    }

    private listenCore<T>(ref: DatabaseReference | Reference, handler: DataEventHandler<T>): Handle {
        return this.unsubscribeHandles.add(firebaseListenRef(ref, handler));
    }

    public listen(key: K, handler: DataEventHandler<T>): Handle {
        return this.listenCore(this.ref(key), handler);
    }

    public listenAll(handler: DataEventHandler<DataCollection<T, C>>): Handle {
        return this.listenCore(this.baseRef(), handler);
    }

    public node<ChildType>(key: K, childPath: string): DataEvents<ChildType> {
        return {
            listen: (handler: DataEventHandler<ChildType>) => this.listenCore(this.childRef(key, childPath), handler),
        };
    }

    public property<P extends keyof T & string>(key: K, property: P): ChildDatabasePropertyEvents<T[P]> {
        return new PropertyHelper<T, T[P]>(this, key, property);
    }

    public removeAllListeners(): void {
        this.unsubscribeHandles.stop();
    }
}

export class FirebaseDatabase<T, K extends string = string, C extends DataCollectionType = Record<string, unknown>>
    extends FirebaseDatabaseEvents<T, K, C>
    implements Database<T, K, C>
{
    public async transaction(key: K, action: (obj: T | undefined) => T | undefined): Promise<void> {
        const ref = this.ref(key);
        const callback = (obj: T | null) => action(obj ?? undefined) ?? null;
        isDatabaseReference(ref) ? await runTransaction(ref, callback) : await ref.transaction(callback);
    }

    public async fetch(key: K): Promise<T | undefined> {
        const ref = this.ref(key);
        return isDatabaseReference(ref) ? (await get(ref)).val() ?? undefined : (await ref.get()).val();
    }

    public async create(obj: T): Promise<K> {
        const baseRef = this.baseRef();
        const ref = isDatabaseReference(baseRef) ? await push(baseRef, obj) : await baseRef.push(obj);
        assert(ref.key, 'Error creating database reference');
        return ref.key as K; // violates K; create shouldnt be used with K
    }

    public async set(key: K, obj: T): Promise<void> {
        const ref = this.ref(key);
        isDatabaseReference(ref) ? await set(ref, obj) : await ref.set(obj);
    }

    public async delete(key: K): Promise<void> {
        const ref = this.ref(key);
        isDatabaseReference(ref) ? await remove(ref) : ref.remove();
    }

    // TODO: this fails for arrays
    public async getAll(): Promise<{ [key: string]: T }> {
        const baseRef = this.baseRef();
        return (isDatabaseReference(baseRef) ? (await get(baseRef)).val() : (await baseRef.get()).val()) ?? {};
    }

    public async deleteAll(): Promise<void> {
        const baseRef = this.baseRef();
        isDatabaseReference(baseRef) ? await remove(baseRef) : await baseRef.remove();
    }
}

export function events<T, K>(databaseEvents: DatabaseEvents<T, K>, key: K): DataEvents<T> {
    return {
        listen: (handler: DataEventHandler<T>) => databaseEvents.listen(key, handler),
    };
}
