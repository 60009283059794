import * as types from './types';
import store from '..';
import { SystemMode } from './reducer';
import { resetDatabases } from '@playtime/database';

export const setSystemAppMode = (systemAppMode: SystemMode, userSet = false) => {
    if (userSet) window.sessionStorage.setItem(types.SET_SYSTEM_APP_MODE, systemAppMode);
    store.store.dispatch({
        type: types.SET_SYSTEM_APP_MODE,
        systemAppMode: window.sessionStorage.getItem(types.SET_SYSTEM_APP_MODE) ?? systemAppMode,
    });
};

export const setSystemDbMode = (systemDbMode: SystemMode, userSet = false) => {
    if (userSet) window.sessionStorage.setItem(types.SET_SYSTEM_DB_MODE, systemDbMode);
    resetDatabases();
    store.store.dispatch({
        type: types.SET_SYSTEM_DB_MODE,
        systemDbMode: window.sessionStorage.getItem(types.SET_SYSTEM_DB_MODE) ?? systemDbMode,
    });
};
