export function getTextColor(
    darkFont: boolean,
    primaryColor: [number, number, number],
    secondaryColor?: [number, number, number]
): Record<string, string> {
    if (!secondaryColor) {
        return {
            color: `rgb(${primaryColor.join(',')})`,
            ...(darkFont ?? false ? { WebkitTextStroke: '.4px #666' } : {}),
        };
    }
    return {
        background: `-webkit-linear-gradient(rgb(${primaryColor}), rgb(${secondaryColor}))`,
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        color: 'transparent',
        WebkitTextFillColor: 'transparent',
        ...(darkFont ?? false ? { WebkitTextStroke: '.4px #666' } : {}),
    };
}
