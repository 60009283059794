import { DataEvents, ValidProperty, ValueOfProperty, ChildDatabasePropertyEvents, Database } from '@playtime/database';
import * as React from 'react';
import { ComponentType, useEffect, useState } from 'react';
import { Handles } from '../../util/handles';

const syncDataEventsSymbol = Symbol();

/** Data events that can be consumed as props by a synced component. */
export interface SyncDataEvents<T> extends DataEvents<T> {
    [syncDataEventsSymbol]: true;
}

/** Wrap some database events so they can be passed as props to a synced component. */
export function syncEvents<T>(events: DataEvents<T>): SyncDataEvents<T> {
    return {
        listen: events.listen,
        [syncDataEventsSymbol]: true,
    };
}

function isSyncEvents<T>(maybeEvents: unknown): maybeEvents is SyncDataEvents<T> {
    return (
        typeof maybeEvents === 'object' &&
        !!maybeEvents &&
        (maybeEvents as SyncDataEvents<T>)[syncDataEventsSymbol] === true
    );
}

/** Data with child property events that can be consumed as props by a synced component. */
export interface SyncPropEvents<T> extends SyncDataEvents<T> {
    prop<P extends ValidProperty<T>>(property: P): SyncPropEvents<ValueOfProperty<T, P>>;
    props<P extends (keyof T & string)[]>(
        ...property: P
    ): { [X in P extends (infer U)[] ? U : never]: SyncPropEvents<T[X]> };
}

export function syncPropEvents<T>(events: ChildDatabasePropertyEvents<T>): SyncPropEvents<T> {
    return {
        ...syncEvents(events),
        prop: (p) => syncPropEvents(events.property(p)),
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        props: (...p) => syncPropsEvents(events, ...p),
    };
}

function syncPropsEvents<T, P extends (keyof T & string)[]>(events: ChildDatabasePropertyEvents<T>, ...properties: P) {
    return Object.fromEntries(
        properties.map((p) => [p, syncPropEvents(events.property(p as ValidProperty<T>))])
    ) as unknown as {
        [X in P extends (infer U)[] ? U : never]: SyncPropEvents<T[X]>;
    };
}

/** Wrap some database property events so they can be passed as props to a synced component. */
export function syncProp<T, K, P extends keyof T & string>(database: Database<T, K>, key: K, property: P) {
    // TODO: database should only need to be DatabasePropertyEvents<T, K>, but type inference fails for database
    return syncPropEvents(database.property(key, property));
}

/** Wrap events for several database properties so they can be passed as props to a synced component. */
export function syncProps<T, K, P extends (keyof T & string)[]>(database: Database<T, K>, key: K, ...properties: P) {
    return Object.fromEntries(properties.map((p) => [p, syncProp(database, key, p)])) as {
        [X in P extends (infer U)[] ? U : never]: SyncPropEvents<T[X]>;
    };
}

/** Helper to wrap database property events for a specific database and key. */
export function syncDataItem<T, K>(database: Database<T, K>, key: K) {
    return {
        prop<P extends keyof T & string>(property: P) {
            return syncProp(database, key, property);
        },
        props<P extends (keyof T & string)[]>(...properties: P) {
            return syncProps(database, key, ...properties);
        },
    };
}

/** For use in the component's props definition. This ensures
 * the component will properly handle the 3 cases:
 * 1. null - database fetch hasn't returned yet
 * 2. undefined - database fetch couldn't find anything
 * 3. T - database finished finding and fetching the value
 */
export type SyncedComponentProps<T> = T extends Record<string, unknown>
    ? { [K in keyof T]: SyncedComponentProp<T[K]> }
    : SyncedComponentProp<T>;
/** For use in the component's props definition. This ensures
 * the component will properly handle the 3 cases:
 * 1. null - database fetch hasn't returned yet
 * 2. undefined - database fetch couldn't find anything
 * 3. T - database finished finding and fetching the value
 */
export type SyncedComponentProp<T> = T | null | undefined;
// TODO: typing could probably be improved w.r.t. undefined
export type SyncedProps<T> = { [K in keyof T]: T[K] | SyncDataEvents<T[K]> };

function syncCore<T>(WrappedComponent: ComponentType<T>) {
    const SyncedComponent: ComponentType<SyncedProps<T>> = (props) => {
        const states: Partial<{ [K in keyof T]: T[K] | undefined | null }> = {};
        const syncs: {
            setState: (val: T[keyof T] | undefined | null) => void;
            events: SyncDataEvents<T[keyof T]>;
        }[] = [];

        for (const [key, value] of Object.entries(props)) {
            if (isSyncEvents<T[keyof T]>(value)) {
                // null represents no database value yet. undefined indicates the database responded with nothing.
                const [state, setState] = useState<T[keyof T] | undefined | null>(null);
                states[key as keyof T] = state;
                syncs.push({ setState, events: value });
            }
        }

        useEffect(() => {
            return Handles.join(...syncs.map(({ setState, events }) => events.listen(setState))).stop;
        }, [props]);

        return <WrappedComponent {...({ ...props, ...states } as T & JSX.IntrinsicAttributes)} />;
    };
    SyncedComponent.displayName = `Synced${WrappedComponent.displayName ?? WrappedComponent.name}`;
    return SyncedComponent;
}

/** Wrap a component such that some of its props can be synced to update with database events. */
export function Sync<T>(WrappedComponent: ComponentType<T>) {
    const [SyncedComponent] = React.useState(() => syncCore(WrappedComponent));
    return SyncedComponent;
}
