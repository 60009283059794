import * as types from './types';
import { AnyAction } from 'redux';
import { FirebaseApp } from '@firebase/app';

export interface AppSettings {
    databaseURL: string | null;
    debugEnabled: boolean | null;
    isFirebaseLoaded: boolean;
    firebaseApp: FirebaseApp | null;
    backendUri: string | null;
    vapidKey: string | null;
}
const initialState: AppSettings = {
    databaseURL: null,
    debugEnabled: null,
    isFirebaseLoaded: false,
    firebaseApp: null,
    backendUri: null,
    vapidKey: null,
};

const appSettings = (state = initialState, action: AnyAction): AppSettings => {
    switch (action.type) {
        case types.FETCH_APPSETTINGS:
            return {
                ...state,
                ...action.appSettings,
            };
        case types.SET_FIREBASE_APP:
            return {
                ...state,
                firebaseApp: action.firebaseApp,
            };
    }
    return state;
};

export default appSettings;
