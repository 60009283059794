import { IconButton, Dialog, Grid, Button, Icon } from '@mui/material';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    dialogPadding: {
        padding: '0.8em 0.8em',
        '& .MuiGrid-item': {
            padding: '0.8em 0.8em',
        },
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
}));

interface IconDialogProps {
    iconName: string;
    showDialog: boolean;
    setShowDialog(showDialog: boolean): void;
    confirmText: string;
    declineText: string;
    confirm(): void;
    decline?(): void;
    ariaLabel?: string;
    ariaControls?: string;
}

const IconDialog: React.FunctionComponent<React.PropsWithChildren & IconDialogProps> = (props) => {
    const { classes } = useStyles();

    const confirm = () => {
        props.confirm();
        props.setShowDialog(false);
    };

    const decline = () => {
        if (props.decline) props.decline();
        props.setShowDialog(false);
    };

    return (
        <>
            <IconButton
                aria-label={props.ariaLabel}
                aria-controls={props.ariaControls}
                color="inherit"
                edge="start"
                onClick={() => props.setShowDialog(!props.showDialog)}
                size="large"
            >
                <Icon>{props.iconName}</Icon>
            </IconButton>
            <Dialog open={props.showDialog}>
                <Grid container className={classes.dialogPadding} direction="column">
                    <Grid item>{props.children}</Grid>
                    <Grid item className={classes.buttons}>
                        <Button variant="contained" color="secondary" onClick={decline}>
                            {props.declineText}
                        </Button>
                        <Button variant="contained" color="primary" onClick={confirm}>
                            {props.confirmText}
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
};

export default IconDialog;
