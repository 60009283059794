import * as React from 'react';
import GenericTurnCommands from '../../../../common/TurnCommands';

interface TurnCommandsProps {
    onPassTurn(): void;
}

const TurnCommands: React.FunctionComponent<TurnCommandsProps> = (props) => {
    return <GenericTurnCommands onNextTurn={props.onPassTurn} text={'Pass Turn'} />;
};

export default TurnCommands;
