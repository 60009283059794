import { FishbowlStats } from '@playtime/database/src/model/stats';
import * as React from 'react';
import { SyncedComponentProps } from '../../hoc/sync';
import NumberStat from '../../Stats/NumberStat';

const Contests: React.FunctionComponent<
    SyncedComponentProps<Pick<FishbowlStats, 'contestCount' | 'winCount' | 'lossCount' | 'tieCount'>>
> = (props) => {
    const contestsPerGame = () => {
        if (
            props.winCount === null ||
            props.lossCount === null ||
            props.tieCount === null ||
            props.contestCount === null
        )
            return null;
        if (
            props.winCount === undefined ||
            props.lossCount === undefined ||
            props.tieCount === undefined ||
            props.contestCount === undefined ||
            props.winCount + props.lossCount + props.tieCount === 0
        )
            return 'None';
        if (props.contestCount === 0) return 0;
        return (props.contestCount / (props.winCount + props.lossCount + props.tieCount)).toFixed(1);
    };
    return (
        <NumberStat
            title="Contests"
            caption="Contests / Game"
            number={contestsPerGame()}
            toolTip="Average number of times you contested others per game"
        />
    );
};

export default Contests;
