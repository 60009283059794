import { getTeamSize } from '..';
import { defaultPlayerStats, PLACEHOLDER_ID_PREFIX, PlayerOutcome, STARTING_ELO } from '../api/playerStats';
import { success, failure } from '@playtime/database';
import { GameType } from '@playtime/database/src/model/lobby';
import { PlayerStats, StatsType } from '@playtime/database/src/model/stats';

export function updateStats(
    playerStats: PlayerStats,
    playerOutcome: PlayerOutcome,
    playersNewElo: Record<string, number>,
    statsType: StatsType
) {
    playerStats[statsType].winCount += playerOutcome.outcome === 'win' ? 1 : 0;
    playerStats[statsType].lossCount += playerOutcome.outcome === 'loss' ? 1 : 0;
    playerStats[statsType].tieCount += playerOutcome.outcome === 'tie' ? 1 : 0;
    if (!playersNewElo[playerOutcome.id]) return failure("player's elo wasn't properly fetched");
    playerStats[statsType].elo = playersNewElo[playerOutcome.id];
    return success();
}

export function updateElo(playerStats: PlayerStats, elo: number, statsType: StatsType) {
    playerStats[statsType].elo = elo;
}

export function getPlaceholderId(playerCount: number, numTeams: number) {
    return `${PLACEHOLDER_ID_PREFIX}_${getTeamSize(playerCount, numTeams)}`;
}

export function getPlaceholderGuessedElo(playerCount: number, numTeams: number) {
    const teamSize = getTeamSize(playerCount, numTeams);
    const eloFraction = (teamSize - 1) / teamSize;
    return eloFraction * STARTING_ELO;
}

export function getStatsType(gameType: GameType) {
    switch (gameType) {
        case GameType.Fishbowl:
            return StatsType.Fishbowl;
        case GameType.Codenames:
            return StatsType.Codenames;
    }
}

export function statsOrDefault<T extends StatsType>(stats: PlayerStats, statsType: T): PlayerStats[T] {
    if (stats[statsType]) return stats[statsType];
    const defaultStats = defaultPlayerStats();
    return defaultStats[statsType];
}
