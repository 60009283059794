import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CodenamesGame, isClue, Clue } from '@playtime/database/src/model/codenames';
import * as React from 'react';
import { getColorSchemeRgb } from '../../../../back-end';
import OutlinedLabel from '../../../common/OutlinedLabel';
import { SyncedComponentProps } from '../../../hoc/sync';
import { BaseCodenamesProps } from '../../../models/game';

const gradientBackground = (
    opacity: number,
    primaryColor: [number, number, number],
    secondaryColor?: [number, number, number]
) => {
    const rgbColors = getColorSchemeRgb(primaryColor, secondaryColor);
    return secondaryColor
        ? `linear-gradient(45deg, rgb(${rgbColors.secondary}, ${opacity}) 5%, rgb(${rgbColors.primary}, ${opacity}) 70%)`
        : `rgb(${rgbColors.primary}, ${opacity})`;
};

const useStyles = makeStyles({
    lightText: {
        color: '#fff',
    },
    darkText: {
        color: '#666',
    },
});

const CurrentClue: React.FunctionComponent<
    SyncedComponentProps<Pick<CodenamesGame, 'clueGiven' | 'gameLog' | 'teamTurn' | 'teams'>> & BaseCodenamesProps
> = (props) => {
    const classes = useStyles();
    if (props.teamTurn === undefined || props.teamTurn === null) return null;
    const lastGivenClue = !!props.gameLog && (props.gameLog.find((log) => isClue(log)) as undefined | Clue);
    if (!props.clueGiven || !lastGivenClue || !props.teams) return null;
    const activeTeam = props.teams[props.teamTurn];

    return (
        <OutlinedLabel
            label="Current Clue"
            background={gradientBackground(0.85, activeTeam.primaryColor, activeTeam.secondaryColor)}
            labelBackground={gradientBackground(0.15, activeTeam.primaryColor, activeTeam.secondaryColor)}
        >
            <Box minWidth="125px" textAlign="center">
                <Typography variant="h5" className={activeTeam.darkFont ? classes.darkText : classes.lightText}>
                    {lastGivenClue.clue}, {lastGivenClue.count}
                </Typography>
            </Box>
        </OutlinedLabel>
    );
};

export default CurrentClue;
