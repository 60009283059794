import { Result, result, success, failure } from '@playtime/database';
import { CodenamesConfig, CodenamesGame, SpyType, CodenamesTeam } from '@playtime/database/src/model/codenames';
import assert from 'assert';
import { cyclicIndex, isNullOrUndefined, push, shuffle } from '../../../util/util';
import { getPlayerIds, trueNow } from '../pure/pure';
import { StreakEnder } from './gameplay';

export function getNewWordSet(
    words: string[],
    config: Pick<CodenamesConfig, 'numberOfClues' | 'numberOfAssassins' | 'numberOfWords'>,
    teams: CodenamesGame['teams']
): Result<CodenamesGame['words']> {
    if (words.length !== config.numberOfWords)
        return failure("codenames word count didn't equal the configured number of words");
    if (config.numberOfWords < config.numberOfClues * teams.length + config.numberOfAssassins)
        return failure('invalid codenames config. too many special cards');
    const spyTypes: SpyType[] = [];
    // + 1 because the first team has one more clue to guess to balance the game;
    const numberOfTeamWords = config.numberOfClues * teams.length + 1;
    push(spyTypes, SpyType.bystander, config.numberOfWords - numberOfTeamWords - config.numberOfAssassins);
    push(spyTypes, SpyType.Assassin, config.numberOfAssassins);
    for (let i = 0; i < teams.length; i++) {
        // the team that starts clue giving has 1 more clue to guess
        push(spyTypes, SpyType.TeamStart + i, i === 0 ? config.numberOfClues + 1 : config.numberOfClues);
    }
    shuffle(spyTypes);
    return result(
        Object.fromEntries(
            words.map((val, ix) => [
                val,
                {
                    word: val,
                    spyType: spyTypes[ix],
                    revealed: false,
                },
            ])
        )
    );
}

export function getNextTurn(team: CodenamesTeam) {
    return cyclicIndex(team.players, team.playerTurn + 1);
}

export function removeWordVotes(game: CodenamesGame) {
    Object.keys(game.words).forEach((w) => delete game.words[w].voters);
}

export function passTeam(game: CodenamesGame) {
    game.teamTurn = cyclicIndex(game.teams, game.teamTurn + 1);
    removeWordVotes(game);
}

export function getClueGiver(game: CodenamesGame) {
    const clueGivingTeam = game.teams[game.teamTurn];
    return clueGivingTeam.players[clueGivingTeam.playerTurn];
}

export function getClueGivers(game: CodenamesGame): string[] {
    return game.teams.map((team) => team.players[team.playerTurn]);
}

export function endStreak(
    game: CodenamesGame,
    streakEnder: StreakEnder,
    gameEnded: boolean,
    timeOffset?: number
): Result<{
    streakEnded: true;
    streak: string[];
    clueGiver: string;
    players: readonly string[];
    config: CodenamesConfig;
    streakEnder: StreakEnder;
}> {
    const streak = [...(game.streak ?? [])];
    game.streak = [];
    game.clueGiven = false;

    const addTeamGameResultsResult = addTeamGameResults(game, streak.length, streakEnder);
    if (!addTeamGameResultsResult.success) return addTeamGameResultsResult;

    const clueGiver = getClueGiver(game);
    const players = game.teams[game.teamTurn].players;
    passTeam(game);

    if (game.config.secondsPerTurn > 0 && !isNullOrUndefined(timeOffset)) {
        if (gameEnded) {
            delete game.turnEndTime;
        } else {
            resetTurnTimer(game, timeOffset);
        }
    }

    game.turn++;
    return result({ streakEnded: true, streak, clueGiver, players, config: game.config, streakEnder });
}

export function resetGame(game: CodenamesGame) {
    game.words = {};
    game.gameLog = [];
    game.teams.forEach((t) => {
        t.playerTurn = getNextTurn(t);
        t.isReady = false;
        t.scores[0] = 0;
    });
    const lastTeam = game.teams.pop();
    if (!lastTeam) return failure('no teams are in the game');
    game.teams.unshift(lastTeam);
    game.teamTurn = 0;
    game.gameResults = {};
    return success();
}

function addTeamGameResults(game: CodenamesGame, streakLength: number, streakEnder: StreakEnder) {
    const players = getPlayerIds(game);
    const playerCount = players.length;
    if (Object.keys(game.gameResults ?? {}).length !== playerCount) {
        game.gameResults = Object.fromEntries(
            players.map((id) => [
                id,
                game.gameResults?.[id] ?? {
                    allyAgentCount: 0,
                    enemyAgentCount: 0,
                    bystanderCount: 0,
                    assassinCount: 0,
                },
            ])
        );
    }

    const activeTeamPlayerIds = game.teams[game.teamTurn].players;
    activeTeamPlayerIds.forEach((id) => {
        assert(game.gameResults, 'gameResults is defined for all players');
        game.gameResults[id].allyAgentCount += streakLength;
        if (streakEnder === 'assassin') game.gameResults[id].assassinCount++;
        else if (streakEnder === 'bystander') game.gameResults[id].bystanderCount++;
        else if (streakEnder === 'opposingTeam') game.gameResults[id].enemyAgentCount++;
    });

    return success();
}

export function resetTurnTimer(game: CodenamesGame, timeOffset: number) {
    game.turnEndTime = trueNow(timeOffset) + game.config.secondsPerTurn * 1000;
}
