import { Button, Dialog, Grid, IconButton } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { codenamesConfigSettings } from '@playtime/database/src/model/codenames';
import { fishbowlConfigSettings } from '@playtime/database/src/model/fishbowl';
import { GameConfig, GameType } from '@playtime/database/src/model/lobby';
import React, { useEffect, useState } from 'react';
import ConfigSetting from './ConfigSettings/ConfigSetting';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    settingsIcon: {
        padding: '.35em .1em',
    },
    rowPadding: {
        padding: '0.8em 0.8em',
    },
    gameConfig: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '0.5em',
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    gameConfigTextInput: {
        width: '210px',
    },
    help: {
        paddingLeft: 10,
    },
}));

export type ConfigState = { [P in keyof GameConfig<GameType>]: { value: GameConfig<GameType>[P]; hasError: boolean } };
const getConfigState = (config?: GameConfig<GameType>) =>
    !config
        ? undefined
        : (Object.fromEntries(
              Object.entries(config).map(([key, value]) => [key, { value, hasError: false }])
          ) as ConfigState);
const getConfigFromState = (configState: ConfigState) =>
    Object.fromEntries(
        Object.entries(configState).map(([key, value]) => [key, value.value])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any as GameConfig<GameType>;

interface ConfigButtonProps {
    config: GameConfig<GameType> | undefined;
    gameType: GameType;
    editConfig: (config: GameConfig<GameType>) => Promise<void>;
}

export const ConfigButton: React.FunctionComponent<ConfigButtonProps> = (props) => {
    const { classes } = useStyles();

    const [showGameSettings, setShowGameSettings] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [gameConfig, setGameConfig] = useState<ConfigState | undefined>(getConfigState(props.config));

    useEffect(() => {
        setGameConfig(getConfigState(props.config));
    }, [props.config]);

    if (!gameConfig) return null;

    const updateGameConfig = (delta: Partial<ConfigState>) => {
        setGameConfig({ ...gameConfig, ...delta });
    };

    const configsHaveError = () => Object.values(gameConfig).some((val) => val.hasError);

    const getConfigElements = (): (JSX.Element | null)[] => {
        const configSettings = props.gameType === GameType.Fishbowl ? fishbowlConfigSettings : codenamesConfigSettings;
        return Object.entries(configSettings).map(([key, value]) => {
            const gameConfigKey = key as keyof GameConfig<GameType>;
            if (!gameConfig[gameConfigKey]) return null;
            return (
                <Grid key={gameConfigKey} item className={classes.gameConfig}>
                    <ConfigSetting
                        property={gameConfigKey}
                        value={gameConfig[gameConfigKey].value}
                        isDirty={isSubmitted}
                        onChange={updateGameConfig}
                        {...value}
                    />
                </Grid>
            );
        });
    };

    const editConfig = async () => {
        setIsSubmitted(true);
        if (configsHaveError()) {
            return;
        }
        await props.editConfig(getConfigFromState(gameConfig));
        setShowGameSettings(false);
    };

    return (
        <>
            <IconButton
                aria-label="game configuration"
                aria-controls="menu-appbar"
                color="inherit"
                edge="start"
                className={classes.settingsIcon}
                onClick={() => setShowGameSettings(!showGameSettings)}
                size="large"
            >
                <Settings />
            </IconButton>
            <Dialog open={showGameSettings}>
                <Grid container className={classes.rowPadding} direction="column">
                    {getConfigElements()}
                    <Grid item className={classes.buttons}>
                        <Button variant="contained" color="primary" onClick={() => editConfig()}>
                            Update
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => setShowGameSettings(false)}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
};
