import * as core from '../../business/fishbowl/gameplay';
import { getTimeOffset } from '../timeSync';
import * as historicalGames from '../historicalGames';
import * as fishbowlStats from './fishbowlStats';
import * as phraseCollection from './phraseCollection';
import { makeMutableGameConfigCopy, secondsDiff, trueNow } from '../../business/pure/pure';
import { filterObject } from '../../../util/util';
import { FinalClueGiver } from './fishbowlStats';
import { gameNotFoundMsg } from '../game';
import db from '../../databases';
import { inTranIfDefined, Result, success, failure } from '@playtime/database';
import { GameType, LobbyGame } from '@playtime/database/src/model/lobby';

export async function guessPhrase(gameId: string, userId: string, turn: number, phrase: string) {
    const timeOffset = await getTimeOffset();
    const guessedResult = await inTranIfDefined(
        db.fishbowlGame,
        gameId,
        (game) => core.guessPhrase(game, userId, turn, phrase, timeOffset),
        gameNotFoundMsg
    );
    if (!guessedResult.success) return guessedResult;

    const statsPromises: Promise<unknown>[] = [
        phraseCollection.guessed(phrase, secondsDiff(trueNow(timeOffset), guessedResult.value.previousPhraseStartTime)),
        fishbowlStats.guessed(guessedResult.value.currentClueGiverId),
    ];
    // if game ended, record players time taken for this turn and players game outcome
    if (!guessedResult.value.gameStillGoing) {
        await Promise.all(statsPromises);
        const gameRecordResults = await historicalGames.recordGame(
            guessedResult.value.game,
            guessedResult.value.winningTeamIndices,
            GameType.Fishbowl
        );
        if (!gameRecordResults.success) return gameRecordResults;
        const finalClueGiver: FinalClueGiver = {
            id: guessedResult.value.currentClueGiverId,
            secondsPerTurn: guessedResult.value.game.config.secondsPerTurn,
            turnEndTime: guessedResult.value.turnEndTime,
        };
        statsPromises.push(
            fishbowlStats.gameOver(
                guessedResult.value.playersOutcome,
                gameRecordResults.value.playersNewElo,
                finalClueGiver,
                gameRecordResults.value.historicalGameId
            )
        );
    }
    await Promise.all(statsPromises);
    return guessedResult;
}

export function undoGuess(gameId: string, turn: number) {
    return inTranIfDefined(db.fishbowlGame, gameId, (game) => core.undoGuess(game, turn), gameNotFoundMsg);
}

export async function contestPhrase(gameId: string, userId: string, guessedPhraseIndex: number, turn: number) {
    const contestOutcome = await inTranIfDefined(
        db.fishbowlGame,
        gameId,
        (game) => core.contestPhrase(game, userId, guessedPhraseIndex, turn),
        gameNotFoundMsg
    );
    if (!contestOutcome.success) return contestOutcome;

    const statsPromises = [fishbowlStats.contested(userId)];
    // if successful contest, add stats
    if (contestOutcome.value.didContestPass) {
        statsPromises.push(fishbowlStats.contestedByOthers(contestOutcome.value.currentClueGiverId));
        statsPromises.push(phraseCollection.contested(contestOutcome.value.contestedPhrase));
    }
    await Promise.all(statsPromises);
    return contestOutcome;
}

export async function skipPhrase(gameId: string, userId: string, turn: number, phrase: string) {
    const timeOffset = await getTimeOffset();
    const skipResult = await inTranIfDefined(
        db.fishbowlGame,
        gameId,
        (game) => core.skipPhrase(game, userId, turn, timeOffset, phrase),
        gameNotFoundMsg
    );
    if (!skipResult.success) return skipResult;

    await Promise.all([
        fishbowlStats.passed(userId),
        phraseCollection.passed(phrase, secondsDiff(trueNow(timeOffset), skipResult.value.previousPhraseStartTime)),
    ]);

    return skipResult;
}

export async function startClueGiving(gameId: string, turn: number) {
    const timeOffset = await getTimeOffset();
    return inTranIfDefined(
        db.fishbowlGame,
        gameId,
        (game) => core.startClueGiving(game, turn, timeOffset),
        gameNotFoundMsg
    );
}

export async function passClueGiving(gameId: string, turn: number) {
    return inTranIfDefined(db.fishbowlGame, gameId, (game) => core.passClueGiving(game, turn), gameNotFoundMsg);
}

export async function changeTurns(gameId: string, userId: string, teamTurn: number, playerTurn: number) {
    const changeTurnsOutcome = await inTranIfDefined(
        db.fishbowlGame,
        gameId,
        (game) => core.changeTurns(game, userId, teamTurn, playerTurn),
        gameNotFoundMsg
    );
    if (!changeTurnsOutcome.success) return changeTurnsOutcome;

    const timeOffset = await getTimeOffset();
    await Promise.all([
        fishbowlStats.timeTook(userId, changeTurnsOutcome.value.gameConfig.secondsPerTurn),
        phraseCollection.timeTook(
            changeTurnsOutcome.value.previousPhrase,
            secondsDiff(trueNow(timeOffset), changeTurnsOutcome.value.previousPhraseStartTime)
        ),
    ]);
    return changeTurnsOutcome;
}

export async function restartFishbowl(gameId: string): Promise<Result> {
    const gameToRestart = await db.fishbowlGame.fetch(gameId);
    if (!gameToRestart) return failure("Tried fetching a fishbowl game that doesn't exist");
    if ((await db.lobbyGame.fetch(gameId)) !== undefined) {
        return failure('Lobby game with same id already exists');
    }

    // Remove player entered phrases
    const players: LobbyGame<GameType.Fishbowl>['players'] = filterObject(
        gameToRestart.players,
        (entry) => entry[1].isActive
    );
    for (const player of Object.values(players)) {
        delete player.phrases;
    }

    // Initialize lobby game
    const game: LobbyGame<GameType.Fishbowl> = {
        name: gameToRestart.name,
        host: gameToRestart.host,
        gameType: GameType.Fishbowl,
        config: makeMutableGameConfigCopy(gameToRestart.config),
        players,
    };

    // Move game from active to lobby
    await db.lobbyGame.set(gameId, game);
    await db.fishbowlGame.delete(gameId);

    return success();
}
