import { Button, Typography } from '@mui/material';
import * as React from 'react';

export interface AreYouReadyProps {
    pauseRemainingTimeMs: number | undefined;
    startClueGiving: () => void;
}

export const AreYouReady: React.FunctionComponent<AreYouReadyProps> = (props) => {
    const secRemaining = props.pauseRemainingTimeMs ? Math.ceil(props.pauseRemainingTimeMs / 1000) : undefined;
    return (
        <div style={{ textAlign: 'center' }}>
            <Typography variant="h4">It&apos;s your turn!</Typography>

            <Typography variant="h5">
                {secRemaining
                    ? `Round over. ${secRemaining} sec remaining for next round!`
                    : 'Ready to give clues for your teammates?'}
            </Typography>

            <Button variant="outlined" onClick={props.startClueGiving}>
                {secRemaining ? 'Continue' : 'Start!'}
            </Button>
        </div>
    );
};
