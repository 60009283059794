export interface Handle {
    /** Unsubscribe */
    stop(): void;
}

export class Handles implements Handle {
    // TODO: this class can be made more generic
    public static join(...handles: Handle[]): Handle {
        return { stop: new Handles(...handles).stop };
    }
    private readonly handles: Handle[] = [];
    constructor(...initialHandles: Handle[]) {
        this.handles = [...initialHandles];
    }
    public add(handle: Handle): Handle {
        this.handles.push(handle);
        if (this.handles.length > 100 && this.handles.length % 100 === 0) {
            console.log(`warning: large number of unsubscribe handles: ${this.handles.length}+`);
        }
        return handle;
    }
    public readonly stop = () => {
        while (this.handles.length > 0) {
            try {
                this.handles.pop()?.stop();
            } finally {
                // nothing
            }
        }
    };
}
