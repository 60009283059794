import * as codenames from './codenames/gameplay';
import { CodenamesGame, failure, Result } from '@playtime/database';
import db from '../databases';
import { GameType } from '@playtime/database/src/model/lobby';

export async function trackJoinableActiveGame(id: string, gameType: GameType, name: string) {
    return await db.joinableActiveGames.set(id, { id, gameType, name });
}

export async function joinActiveGame(id: string, userId: string, displayName: string): Promise<Result<CodenamesGame>> {
    const activeGame = await db.joinableActiveGames.fetch(id);
    if (!activeGame) return failure(`game id passed either doesn't exist or isn't a joinable active game`);
    if (activeGame.gameType === GameType.Codenames) return codenames.joinAfterGameStart(id, userId, displayName);
    return failure('cannot join this game type after game start yet');
}
