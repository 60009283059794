import { PlayerStats, StatsType } from '@playtime/database/src/model/stats';
import * as React from 'react';
import NumberStat from './NumberStat';

const Elo: React.FunctionComponent<Pick<PlayerStats[StatsType], 'elo'>> = (props) => {
    const playerElo = () => {
        if (props.elo === null) return null;
        if (!props.elo) return 'None';
        if (props.elo === 0) return 0;
        return props.elo;
    };
    return (
        <NumberStat
            title="Rating"
            number={playerElo()}
            toolTip="The player's rating calculated based off of the strength of wins and losses. New players start with a rating of 1200."
        />
    );
};

export default Elo;
