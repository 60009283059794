import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { ActiveGame } from '@playtime/database/src';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/auth/selectors';
import { makeStyles } from 'tss-react/mui';
import { green, red } from '@mui/material/colors';

const useStyles = makeStyles()({
    leftCell: {
        paddingLeft: '6px',
        paddingRight: '45px',
    },
});

export interface GameResultsProps extends Pick<ActiveGame, 'gameResults'> {
    additionalGameResults?: [string, React.ReactElement, React.ReactElement][];
}

const GameResults: React.FunctionComponent<GameResultsProps> = (props) => {
    const user = useSelector(getUser);
    const { classes } = useStyles();
    const userGameResults = props.gameResults?.[user.uid];
    if (!userGameResults) return null;
    const hasWon = userGameResults.outcome === 'win';
    return (
        <Table sx={{ width: '100%' }}>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.leftCell}>Outcome</TableCell>
                    <TableCell>
                        {hasWon && (
                            <Box display="flex">
                                <Typography sx={{ fontWeight: 'bolder', color: hasWon ? green[600] : red[600] }}>
                                    {hasWon ? '+' : ''}
                                    {userGameResults.eloChange}
                                </Typography>
                                <Box sx={{ marginTop: '4px', marginLeft: '5px' }}>
                                    <img src="./star-dust.png" color="white" alt="" width="18" height="18" />
                                </Box>
                            </Box>
                        )}
                    </TableCell>
                    <TableCell align="right">
                        <Typography sx={{ fontWeight: 'bolder', color: hasWon ? green[600] : red[600] }}>
                            {userGameResults.outcome}
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.leftCell}>Rating</TableCell>
                    <TableCell align="right">
                        <Typography sx={{ fontWeight: 'bolder', color: hasWon ? green[600] : red[600] }}>
                            {hasWon ? '+' : ''}
                            {userGameResults.eloChange}
                        </Typography>
                    </TableCell>
                    <TableCell align="right">
                        <Typography sx={{ fontWeight: 'bolder' }}>{userGameResults.newElo}</Typography>
                    </TableCell>
                </TableRow>
                {props.additionalGameResults?.map(([name, change, total]) => (
                    <TableRow key={name}>
                        <TableCell className={classes.leftCell}>{name}</TableCell>
                        <TableCell align="right">{change}</TableCell>
                        <TableCell align="right">{total}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default GameResults;
