import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from './view/Layout/Layout';
import './App.css';
import theme from './setup/theme';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import ServiceWorkerWrapper from './view/ServiceWorkerWrapper';

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline>
                    <BrowserRouter>
                        <SnackbarProvider maxSnack={10} disableWindowBlurListener={true}>
                            <Layout />
                        </SnackbarProvider>
                        <ServiceWorkerWrapper />
                    </BrowserRouter>
                </CssBaseline>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default connect()(App);
