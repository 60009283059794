import { Grid } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { getUser } from '../../../../store/auth/selectors';
import { useSelector } from 'react-redux';

export interface GameFooterProps {
    currentPlayerName: string;
    nextPlayerName: string;
    nextPlayerId: string;
}

export const GameFooter: FunctionComponent<GameFooterProps> = (props) => {
    const user = useSelector(getUser);
    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid item>
                <h3>{props.currentPlayerName} is giving clues.</h3>
            </Grid>
            <Grid item>
                <h3>{props.nextPlayerId === user.uid ? 'You are up next!' : `${props.nextPlayerName} is next.`}</h3>
            </Grid>
        </Grid>
    );
};
