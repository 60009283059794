import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Route, Link, Switch, useHistory, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import SignIn from '../pages/SignIn';
import SingleSignOn from '../pages/SingleSignOn';
import { Fishbowl } from '../pages/Games/Fishbowl';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Tooltip,
    Switch as MuiSwitch,
    Divider,
    Container,
    Button,
    Box,
} from '@mui/material';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import StorageIcon from '@mui/icons-material/Storage';
import AccountCircle from '@mui/icons-material/AccountCircle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { isFirebaseLoaded } from '../../store/appSettings/selectors';
import PrivateRoute from '../router/PrivateRoute';
import { checkForUser } from '../../store/auth/selectors';
import { useSelector } from 'react-redux';
import { Management } from '../pages/Management';
import SettingsIcon from '@mui/icons-material/Settings';
import { activateGame, leaveActiveGame, leaveGame } from '../../back-end';
import Stats from '../pages/Stats';
import Codenames from '../pages/Games/Codenames';
import {
    CODENAMES_ROUTE,
    FISHBOWL_ROUTE,
    HOME_ROUTE,
    MANAGEMENT_ROUTE,
    STATS_ROUTE,
    STORE_ROUTE,
} from '../router/routing';
import { setGame } from '../../store/game/actions';
import { Immutable } from '../../util/util';
import { getGame } from '../../store/game/selectors';
import { SystemMode } from '../../setup/mode';
import { setSystemAppMode, setSystemDbMode } from '../../store/devTools/actions';
import { getSystemAppMode, getSystemDbMode } from '../../store/devTools/selectors';
import { getMessaging, MessagePayload, onMessage } from '@firebase/messaging';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';
import store from '../../store';
import { Close, Leaderboard, Store as StoreIcon } from '@mui/icons-material';
import Logout from './MenuItems/Logout';
import IconDialog from '../common/IconDialog';
import assert from 'assert';
import db from '../../back-end/databases';
import { LobbyGame } from '@playtime/database/src/model/lobby';
import { makeStyles } from 'tss-react/mui';
import GameRouting from './GameRouting';
import { Sync, syncDataItem } from '../hoc/sync';
import StarDust from './MenuItems/StarDust';
import Store from '../pages/Store';
import { setUser } from '../../store/auth/actions';

const useStyles = makeStyles()(() => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        cursor: 'pointer',
        webkitTouchCallout: 'none',
        webkitUserSelect: 'none',
        khtmlUserSelect: 'none',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    link: {
        textDecoration: 'inherit',
        color: 'inherit',
    },
    content: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '15px',
        height: 'calc(100% - 91px)',
    },
    tooltip: {
        whiteSpace: 'pre-line',
    },
}));

export default function Layout() {
    const isDev = (mode: SystemMode) => mode === 'dev';
    const getMode = (checked: boolean) => (checked ? 'dev' : 'prod');
    const { classes } = useStyles();
    const route = useHistory();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [copyGameLinkTooltipOpen, setCopyGameLinkTooltipOpen] = useState(false);
    const [copyGameLink, setCopyGameLink] = useState('');
    const [showCopyUserIdTooltip, setShowCopyUserIdTooltip] = useState(false);
    const [showLeaveGameConfirmation, setShowLeaveGameConfirmation] = useState(false);

    const _isFirebaseLoaded = useSelector(isFirebaseLoaded);
    const firebaseUser = useSelector(checkForUser);
    const game = useSelector(getGame);
    const systemAppMode = useSelector(getSystemAppMode);
    const systemDbMode = useSelector(getSystemDbMode);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const SyncStarDust = Sync(StarDust);

    const toggleAppModeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSystemAppMode(getMode(e.target.checked), true);
    };
    const toggleDbModeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSystemDbMode(getMode(e.target.checked), true);
    };

    useEffect(() => {
        if (firebaseUser !== null && _isFirebaseLoaded) {
            // TODO: not sure if i like adding this here, let a lone to redux
            return db.users.listen(firebaseUser.uid, async (user) => {
                let game: Partial<Immutable<LobbyGame>> | undefined;
                if (user) setUser(user);
                if (user?.activeGameId) {
                    game = (
                        await Promise.all([
                            db.lobbyGame.fetch(user.activeGameId),
                            db.fishbowlGame.fetch(user.activeGameId),
                            db.codenamesGame.fetch(user.activeGameId),
                        ])
                    ).reduce<Partial<Immutable<LobbyGame>> | undefined>(
                        (prev, item) => (prev = prev ?? item),
                        undefined
                    );
                }
                setGame(user?.activeGameId, game, user?.games);
                setCopyGameLink(`${window.location.origin}/${user?.activeGameId}`);
            }).stop;
        }
        return undefined;
    }, [firebaseUser?.uid, _isFirebaseLoaded, systemDbMode]);

    const isInGame = game?.id && [CODENAMES_ROUTE, FISHBOWL_ROUTE].includes(location.pathname);
    const isInCodenamesGame = game?.id && [CODENAMES_ROUTE].includes(location.pathname);

    const goToTurnbaseGame = async (gameId: string, snackbarKey: SnackbarKey) => {
        if (!firebaseUser?.uid) return;
        await activateGame(firebaseUser.uid, gameId);
        route.push(CODENAMES_ROUTE);
        closeSnackbar(snackbarKey);
    };

    function dismissNotification(key: SnackbarKey) {
        return (
            <IconButton onClick={() => closeSnackbar(key)} size="large">
                <Close />
            </IconButton>
        );
    }
    const goToTurnbaseGameButton: (notification: MessagePayload) => SnackbarAction = (notification) =>
        function goToGame(key) {
            return (
                <Box display="flex">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => notification.data?.gameId && goToTurnbaseGame(notification.data.gameId, key)}
                    >
                        <span style={{ color: 'white' }}>Go to game</span>
                    </Button>
                    <IconButton onClick={() => closeSnackbar(key)} size="large">
                        <Close />
                    </IconButton>
                </Box>
            );
        };
    React.useEffect(() => {
        const setupNotifications = async () => {
            if (!_isFirebaseLoaded) return;
            const firebaseApp = store.store.getState().appSettings.firebaseApp;
            if (!firebaseApp) return;
            const messaging = getMessaging(firebaseApp);
            onMessage(messaging, (notification) => {
                const isOnGamePage =
                    notification.data?.gameId === game?.id && window.location.pathname === CODENAMES_ROUTE;
                enqueueSnackbar(`${notification.data?.title} ${notification.data?.body}`, {
                    autoHideDuration: 7000,
                    variant: 'info',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    action: isOnGamePage ? dismissNotification : goToTurnbaseGameButton(notification),
                });
            });
        };

        setupNotifications();
    }, [_isFirebaseLoaded]);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setDrawerOpen(open);
    };
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const goToStats = () => {
        route.push(STATS_ROUTE);
    };
    const goToStore = () => {
        route.push(STORE_ROUTE);
    };
    const goHome = () => {
        route.push(HOME_ROUTE);
    };

    const closeCopyLinkTooltip = () => {
        setCopyGameLinkTooltipOpen(true);
        setTimeout(() => setCopyGameLinkTooltipOpen(false), 1000);
    };

    const tempShowCopyUserIdTooltip = () => {
        setShowCopyUserIdTooltip(true);
        setTimeout(() => setShowCopyUserIdTooltip(false), 1000);
    };

    const getCopyGameLinkButton = () => {
        return (
            <CopyToClipboard text={copyGameLink} onCopy={closeCopyLinkTooltip}>
                <Tooltip title="Link copied!" open={copyGameLinkTooltipOpen} placement="bottom" arrow>
                    <IconButton
                        aria-label="copy link to game"
                        aria-controls="menu-appbar"
                        color="inherit"
                        edge="start"
                        size="large"
                    >
                        <FileCopyIcon />
                    </IconButton>
                </Tooltip>
            </CopyToClipboard>
        );
    };

    const confirmLeaveGame = () => {
        assert(
            firebaseUser?.uid && game?.id && game?.game?.gameType !== undefined,
            "user doesn't exist or doesn't belong to a game to leave"
        );
        leaveGame(firebaseUser.uid, game.id);
        leaveActiveGame(firebaseUser.uid, game.id, game.game.gameType);
    };

    const getUserBlock = () => {
        if (!firebaseUser || !_isFirebaseLoaded) return null;
        const syncLobby = syncDataItem(db.users, firebaseUser.uid);
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                {isInGame ? <>{getCopyGameLinkButton()}</> : null}
                {isInCodenamesGame && (
                    <IconDialog
                        iconName="exit_to_app"
                        showDialog={showLeaveGameConfirmation}
                        setShowDialog={setShowLeaveGameConfirmation}
                        confirmText="Yes"
                        declineText="No"
                        confirm={confirmLeaveGame}
                    >
                        Are you sure you want to leave this game?
                    </IconDialog>
                )}
                {isDev(systemAppMode) && (
                    <CopyToClipboard text={firebaseUser.uid} onCopy={tempShowCopyUserIdTooltip}>
                        <Tooltip title="user ID copied" open={showCopyUserIdTooltip} placement="bottom" arrow>
                            <Typography variant="caption" sx={{ cursor: 'pointer' }}>
                                {firebaseUser.displayName}
                            </Typography>
                        </Tooltip>
                    </CopyToClipboard>
                )}

                <SyncStarDust starDust={syncLobby.prop('starDust')} sx={{ marginRight: '-5px' }} />
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    size="large"
                >
                    <AccountCircle />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem dense>{firebaseUser.displayName}</MenuItem>
                    <Divider />
                    <MenuItem onClick={goToStats}>
                        <ListItemIcon>
                            <Leaderboard />
                        </ListItemIcon>
                        Stats
                    </MenuItem>
                    <MenuItem onClick={goToStore}>
                        <ListItemIcon>
                            <StoreIcon />
                        </ListItemIcon>
                        Store
                    </MenuItem>
                    <Logout onClick={handleClose} />
                </Menu>
            </Box>
        );
    };

    return (
        <Switch>
            <Route path="/signin" render={() => <SignIn />} />
            <Route exact path="/single-sign-on">
                <SingleSignOn />
            </Route>
            <PrivateRoute
                exact
                path={[HOME_ROUTE, FISHBOWL_ROUTE, CODENAMES_ROUTE, STATS_ROUTE, STORE_ROUTE, MANAGEMENT_ROUTE]}
            >
                <AppBar position="static" color={isDev(systemDbMode) ? 'default' : 'secondary'}>
                    <Toolbar>
                        <>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                size="large"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                                <div
                                    className={classes.list}
                                    role="presentation"
                                    onClick={toggleDrawer(false)}
                                    onKeyDown={toggleDrawer(false)}
                                >
                                    <List>
                                        <Link to="/" className={classes.link}>
                                            <ListItem button key="Home">
                                                <ListItemIcon>
                                                    <HomeIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Home" />
                                            </ListItem>
                                        </Link>
                                        <Link to="/management" className={classes.link}>
                                            <ListItem button key="Management">
                                                <ListItemIcon>
                                                    <SettingsIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Management" />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </div>
                                <div>
                                    <List>
                                        <Divider variant="inset" component="li" />
                                        <ListItem>
                                            <ListItemIcon>
                                                <DeveloperModeIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="App dev mode" />
                                            <ListItemSecondaryAction>
                                                <MuiSwitch
                                                    edge="end"
                                                    checked={isDev(systemAppMode)}
                                                    onChange={toggleAppModeCheck}
                                                ></MuiSwitch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <StorageIcon></StorageIcon>
                                            </ListItemIcon>
                                            <ListItemText primary="Use dev DB" />
                                            <ListItemSecondaryAction>
                                                <MuiSwitch
                                                    edge="end"
                                                    checked={isDev(systemDbMode)}
                                                    onChange={toggleDbModeCheck}
                                                ></MuiSwitch>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </div>
                            </Drawer>
                            <Typography variant="h6" className={classes.title} onClick={() => goHome()}>
                                PlayTime
                            </Typography>

                            {getUserBlock()}
                        </>
                    </Toolbar>
                </AppBar>
                {!_isFirebaseLoaded ? (
                    <div>Loading...</div>
                ) : (
                    <Container maxWidth="md" className={classes.content}>
                        <Switch>
                            <PrivateRoute exact path={FISHBOWL_ROUTE}>
                                <Fishbowl />
                            </PrivateRoute>
                            <PrivateRoute exact path={[CODENAMES_ROUTE]}>
                                <Codenames />
                            </PrivateRoute>
                            <PrivateRoute exact path={STATS_ROUTE}>
                                <Stats />
                            </PrivateRoute>
                            <PrivateRoute exact path={STORE_ROUTE}>
                                <Store />
                            </PrivateRoute>
                            <PrivateRoute exact path={MANAGEMENT_ROUTE}>
                                <Management />
                            </PrivateRoute>
                            <Route exact path={HOME_ROUTE}>
                                <Home />
                            </Route>
                        </Switch>
                    </Container>
                )}
            </PrivateRoute>
            <PrivateRoute path="/:routeGameId">
                <GameRouting />
            </PrivateRoute>
        </Switch>
    );
}
