import { Result, result, success, failure, Inventory, Team, TeamBanner, TeamName } from '@playtime/database';
import { ActiveGame } from '@playtime/database/src/model/activeGame';
import { LobbyGame, User, GameAssignmentType, GameType } from '@playtime/database/src/model/lobby';
import { allTeamNames, premiumTeams } from '../api/lobby';
import { getFarAwayColor } from './pure/pure';
import { randomInt } from '../../util/random';
import { balanceTeams } from './pure/elo';
import { addPlaceholderPlayers } from './fishbowl/gameplayCore';
import { PLACEHOLDER_ID_PREFIX } from '../api/playerStats';

export function removePlayer(game: LobbyGame, userId: string): Result<boolean> {
    if (game.players) {
        delete game.players[userId];
    }

    // If host leaves, assign a new host
    const playerIds = Object.keys(game.players ?? {});
    if (playerIds.length < 1) {
        return result(true);
    } else if (playerIds.indexOf(game.host) < 0) {
        game.host = playerIds[0];
    }
    return result(false);
}

/**
 * @returns true if player joined the game, false if the player was already in the game
 */
export function assignPlayerToGame(user: User, gameId: string, gameType: GameAssignmentType): Result<boolean> {
    user.activeGameId = gameId;
    if (user.games?.find((game) => gameId === game.id)) return result(false);
    user.games = [{ id: gameId, type: gameType }, ...(user.games ?? [])];
    return result(true);
}

export function unassignPlayerFromGame(user: User, gameId: string): Result {
    if (!user.games?.length) return failure('Cannot unassign player from game without belonging to any games');
    const gameIx = user.games.findIndex((game) => game.id === gameId);
    if (gameIx < 0) return failure("Tried unassigning player from a game that doesn't belong to the player");
    if (user.activeGameId === gameId) delete user.activeGameId;
    user.games.splice(gameIx, 1);
    return success();
}

export function unassignPlayerFromActiveGame(user: User): Result<string> {
    const gameId = user.activeGameId;
    if (!gameId) {
        return failure('Player not actively in a game');
    }

    delete user.activeGameId;
    const gameIx = user.games?.findIndex((game) => game.id === gameId);
    if (gameIx !== undefined && gameIx >= 0) user.games?.splice(gameIx, 1);
    return result(gameId);
}

/** Returns true if all players are inactive */
export function deactivatePlayer(game: ActiveGame, userId: string): Result<boolean> {
    if (!game.players) return result(true);

    if (game.players[userId]) game.players[userId].isActive = false;

    const usersTeamIx = game.teams.findIndex((team) => team.players.includes(userId));
    const usersTeam = game.teams[usersTeamIx];
    if (usersTeam) {
        usersTeam.players.splice(usersTeam.players.indexOf(userId), 1);
        if (!usersTeam.players.length) game.teams.splice(usersTeamIx, 1);
    }

    const activePlayerKvp = Object.entries(game.players).find(([, val]) => val.isActive);
    if (!activePlayerKvp) return result(true);
    if (userId === game.host) game.host = activePlayerKvp[0];
    return result(false);
}

export function activateGame(user: User, gameId: string) {
    if (!user.games?.length) return failure('Cannot activate game without belonging to any games');
    if (!user.games.find((game) => game.id === gameId))
        return failure("Tried activating game that doesn't belong to the player");
    user.activeGameId = gameId;
    return success();
}

export function setGameType(user: User, gameId: string, gameType: GameType) {
    if (!user.games?.length) return failure('Cannot set game without belonging to any games');
    const gameIx = user.games.findIndex((game) => game.id === gameId);
    if (gameIx < 0) return failure("Tried setting game type of a game that doesn't belong to the player");
    user.games[gameIx] = { id: gameId, type: gameType };
    return success();
}

const allTeamSuffixes: Record<string, string[]> = {
    A: ['Alliance', 'Army', 'Association', 'Athletics', 'Aces', 'Angels', 'Assembly', 'All-Stars'],
    B: ['Bears', 'Bombers', 'Bulls', 'Bulldogs', 'Band', 'Bunch', 'Batch', 'Battalion', 'Brigade', 'Bros'],
    C: [
        'Cats',
        'Cartel',
        'Clique',
        'Conglomerate',
        'Crew',
        'Cavalry',
        'Cobras',
        'Cubs',
        'Community',
        'Club',
        'Collective',
        'Company',
    ],
    D: ['Dolphins', 'Dragons', 'Ducks', 'Dudes', 'Division', 'Dynasty', 'Dames'],
    E: ['Eagles', 'Ensemble', 'Elves', 'Empire', 'Elite', 'Echelon', 'Expedition', 'Exiles'],
    F: ['Falcons', 'Formation', 'Fighters', 'Flock', 'Force', 'Fury', 'Fusion', 'Faction', 'Federation', 'Fam'],
    G: ['Gators', 'Gang', 'Group', 'Guys', 'Gals'],
    I: ['Inc', 'Incorporated', 'Institution', 'Invaders'],
    L: ['Lions', 'Lizards', 'Llamas', 'Lobos', 'Lynx', 'League', 'Legion', 'Lads', 'Ladies', 'LLC', 'Lot'],
    M: ['Mob', 'Mafia', 'Mates', 'Musketeers', 'Militia', 'Marauders', 'Mavericks'],
    N: ['Ninjas', 'Navy', 'Nation', 'Natives', 'Nuggets', 'Nerds'],
    O: ['Outfit', 'Organization', 'Outlaws', 'Owls'],
    P: ['Pride', 'Pack', 'Pirates', 'Posse', 'Pals', 'Pandas', 'Punks', 'Patriots', 'Pilots'],
    R: ['Raiders', 'Rangers', 'Raptors', 'Rays', 'Rebels', 'Regiment', 'Riders', 'Rockets', 'Royals', 'Ring'],
    S: ['Squad', 'Cyclones', 'Saints', 'Sentinels', 'Sonics'],
    T: ['Tigers', 'Titans', 'Tornadoes', 'Tsunamis', 'Team'],
    U: ['Unit', 'Union', 'United'],
    Y: ['Yankees', 'Yodelers', 'Yaks', 'Youngins', 'Yahoos'],
};

type TeamWithMaybeBanner = Omit<Team, keyof TeamBanner | keyof TeamName> & Partial<TeamBanner>;
const hasPurchasedBanner = (team: TeamWithMaybeBanner): team is Team => !!team.primaryColor;
export function mergePurchasedBanners(
    balancedTeams: Pick<Team, 'players'>[],
    playersTeamBanners: Record<string, Inventory['teamBanners'] | undefined>
) {
    const teamsWithMaybeBanner: TeamWithMaybeBanner[] = balancedTeams.map((balancedTeam) => {
        const highestPurchasedBanner = balancedTeam.players.reduce<Inventory['teamBanners'][number] | undefined>(
            (highestBanner, playerId) => {
                const playersActiveBanner: Inventory['teamBanners'][number] | undefined = (
                    playersTeamBanners[playerId] ?? []
                ).find((banner) => banner.isActive);
                if (!highestBanner) return playersActiveBanner;
                if (!playersActiveBanner) return highestBanner;
                return premiumTeams[playersActiveBanner.name].value > premiumTeams[highestBanner.name].value
                    ? playersActiveBanner
                    : highestBanner;
            },
            undefined
        );
        const teamWithMaybeBanner: TeamWithMaybeBanner = {
            ...balancedTeam,
            ...(highestPurchasedBanner ? premiumTeams[highestPurchasedBanner.name] : {}),
            scores: [0],
            playerTurn: 0,
        };
        return teamWithMaybeBanner;
    });

    // put all the teams with purchased banners up front so we can
    // get far away colors for teams without purchased banners
    const orderedTeamsWithMaybeBanner = teamsWithMaybeBanner.sort((a, b) =>
        hasPurchasedBanner(a) ? -1 : hasPurchasedBanner(b) ? 1 : 0
    );
    const teams: Team[] = [];
    for (const teamWithMaybeBanner of orderedTeamsWithMaybeBanner) {
        if (hasPurchasedBanner(teamWithMaybeBanner)) {
            teams.push({
                ...teamWithMaybeBanner,
                suffix: getAlliterationSuffix(teamWithMaybeBanner),
            });
            continue;
        }
        const farAwayColorBanner = getFarAwayColor(
            allTeamNames,
            teams.map((team) => team.primaryColor)
        );
        const team: Team = {
            ...teamWithMaybeBanner,
            ...farAwayColorBanner,
            suffix: getAlliterationSuffix(farAwayColorBanner),
        };
        teams.push(team);
    }
    return teams;
}

export function getBalancedTeams(
    playersElo: Record<string, number>,
    placeholderElo: { id: string; elo: number } | null,
    count: number
): Omit<Team, keyof TeamBanner | keyof TeamName>[] {
    addPlaceholderPlayers(playersElo, placeholderElo, Object.keys(playersElo).length % count);
    const playersAndEloWithPlaceholders = Object.entries(playersElo);

    const balancedTeamsWithPlaceholders = balanceTeams(playersAndEloWithPlaceholders, count);
    const balancedTeams = balancedTeamsWithPlaceholders.map((x) =>
        x.filter((y) => y.indexOf(PLACEHOLDER_ID_PREFIX) === -1)
    );

    return balancedTeams
        .map((team) => ({
            scores: [0],
            playerTurn: 0,
            players: team,
        }))
        .sort((a, b) => a.players.length - b.players.length);
}

function getAlliterationSuffix(teamBanner: TeamBanner) {
    const suffixChar = teamBanner.suffixChar ?? teamBanner.prefix[0];
    const suffixOptions = allTeamSuffixes[suffixChar];
    return suffixOptions[randomInt(suffixOptions.length)];
}
