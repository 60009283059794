import { Edit } from '@mui/icons-material';
import { Box, Button, Drawer, Fab, TextareaAutosize, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';

interface AIEditorProps {
    context: string;
    setContext: (newContext: string) => void;
    onResetConversation: () => void;
}

const AIEditor: React.FunctionComponent<AIEditorProps> = (props) => {
    const [showEditor, setShowEditor] = React.useState(false);
    const isSmall = useMediaQuery('(max-width:500px)');
    return (
        <>
            <Fab
                sx={{
                    position: 'absolute',
                    right: 4,
                    top: 60,
                }}
                size="small"
                onClick={() => setShowEditor(!showEditor)}
                color="secondary"
                aria-label="edit ai"
            >
                <Edit />
            </Fab>
            <Drawer
                PaperProps={{
                    sx: { width: isSmall ? '100%' : '50%' },
                }}
                anchor="right"
                open={showEditor}
                onClose={() => setShowEditor(false)}
            >
                <Typography sx={{ paddingLeft: '5px' }}>AI Prompt</Typography>
                <TextareaAutosize
                    value={props.context}
                    onChange={(event) => props.setContext(event.target.value)}
                    style={{ overflow: 'auto' }}
                />
                <Box display="flex">
                    <Button variant={'outlined'} onClick={() => props.onResetConversation()}>
                        Reset Conversation
                    </Button>
                    <Box flexGrow={1} />
                    <Button variant={'outlined'} color="secondary" onClick={() => setShowEditor(false)}>
                        Close
                    </Button>
                </Box>
            </Drawer>
        </>
    );
};

export default AIEditor;
