import { CodenamesGame } from '@playtime/database/src';
import { isNullOrUndefined } from '../../../util/util';
import { SyncedComponentProp } from '../../../view/hoc/sync';

export function getPlayersTurn(
    teams: SyncedComponentProp<CodenamesGame['teams']>,
    teamTurn: SyncedComponentProp<CodenamesGame['teamTurn']>,
    words: SyncedComponentProp<CodenamesGame['words']>,
    clueGiven: SyncedComponentProp<CodenamesGame['clueGiven']>
): string[] {
    if (!teams || isNullOrUndefined(teamTurn)) return [];

    // no 'words' indicate the game is waiting for clue givers to be ready
    if (!Object.keys(words ?? {}).length) {
        return teams.reduce<string[]>((playersTurn, team) => {
            if (!team.isReady) playersTurn.push(team.players[team.playerTurn]);
            return playersTurn;
        }, []);
    }

    // at this point, props.words is defined and so it's either the clue givers turn
    // (activePlayer) or clue guessers turn
    const activeTeam = teams[teamTurn];
    const currentClueGiver = activeTeam.players[activeTeam.playerTurn];
    return !clueGiven ? [currentClueGiver] : activeTeam.players.filter((p) => p !== currentClueGiver);
}
