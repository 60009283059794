import { Grid } from '@mui/material';
import { StatsType } from '@playtime/database/src/model/stats';
import * as React from 'react';
import { useSelector } from 'react-redux';
import db from '../../../back-end/databases';
import { getUser } from '../../../store/auth/selectors';
import { Sync, syncDataItem } from '../../hoc/sync';
import Elo from '../../Stats/Elo';
import WinRate from '../../Stats/WinRate';
import AgentDistribution from './AgentDistribution';
import StreakDistribution from './StreakDistribution';

const Stats: React.FunctionComponent = () => {
    const user = useSelector(getUser);
    const SyncedWinRate = Sync(WinRate);
    const SyncedElo = Sync(Elo);
    const SyncedAgentDistribution = Sync(AgentDistribution);
    const SyncedStreakDistribution = Sync(StreakDistribution);
    const syncStats = syncDataItem(db.playerStats, user.uid);
    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <SyncedWinRate
                    winCount={syncStats.prop(StatsType.Codenames).prop('winCount')}
                    lossCount={syncStats.prop(StatsType.Codenames).prop('lossCount')}
                    tieCount={syncStats.prop(StatsType.Codenames).prop('tieCount')}
                />
            </Grid>
            <Grid item xs={4}>
                <SyncedElo elo={syncStats.prop(StatsType.Codenames).prop('elo')} />
            </Grid>
            <Grid item xs={12} style={{ maxWidth: '100%' }}>
                <SyncedAgentDistribution
                    assassinCount={syncStats.prop(StatsType.Codenames).prop('assassinCount')}
                    bystanderCount={syncStats.prop(StatsType.Codenames).prop('bystanderCount')}
                    enemyAgentCount={syncStats.prop(StatsType.Codenames).prop('enemyAgentCount')}
                    streaks={syncStats.prop(StatsType.Codenames).prop('streaks')}
                />
            </Grid>
            <Grid item xs={12} style={{ maxWidth: '100%' }}>
                <SyncedStreakDistribution streaks={syncStats.prop(StatsType.Codenames).prop('streaks')} />
            </Grid>
        </Grid>
    );
};

export default Stats;
