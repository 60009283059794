import React from 'react';
import { Link, Typography } from '@mui/material';

const Copyright: React.FunctionComponent = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="/" underline="hover">
                PlayTime
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

export default Copyright;
