import React, { FC, useEffect, useState } from 'react';
import { Snackbar, IconButton } from '@mui/material';
import * as serviceWorker from '../setup/serviceWorker';
import { Close } from '@mui/icons-material';
import { cyan } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    root: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: cyan['A700'],
        },
        '& .MuiSnackbarContent-root .MuiSnackbarContent-message': {
            color: 'black',
        },
    },
});

const ServiceWorkerWrapper: FC = () => {
    const { classes } = useStyles();
    const [showMessage, setShowMessage] = useState(localStorage.getItem('VersionUpdated') === 'true');
    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
        localStorage.setItem('VersionUpdated', 'true');
        window.location.reload();
    };

    const hideMessage = () => {
        localStorage.setItem('VersionUpdated', 'false');
        setShowMessage(false);
    };

    useEffect(() => {
        serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);

    return (
        <Snackbar
            className={classes.root}
            open={showMessage}
            onClose={hideMessage}
            message="PlayTime updated to latest version!"
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={7000}
            action={
                <IconButton onClick={hideMessage} size="large">
                    <Close />
                </IconButton>
            }
        />
    );
};

export default ServiceWorkerWrapper;
