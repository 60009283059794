import { Notification } from '../api/messaging';
import { Result, result } from '@playtime/database';
import { LobbyGame, User } from '@playtime/database/src/model/lobby';

export function getNotificationsForLobbyHost(
    game: LobbyGame,
    gameId: string,
    joiningPlayerId: string,
    isLeaving: boolean,
    hostUser: User
): Result<Notification[]> {
    if (!hostUser.deviceToken) return result([]);
    const joiningPlayer = game.players[joiningPlayerId];
    const enoughPlayersToStart = !isLeaving && Object.keys(game.players).length >= game.config.numberOfTeams * 2;
    return result([
        {
            deviceToken: hostUser.deviceToken.token,
            title: `A player has ${isLeaving ? 'left' : 'joined'}`,
            body: `${joiningPlayer.displayName} ${isLeaving ? 'left' : 'joined'} your game. ${
                enoughPlayersToStart ? 'There are enough players to start!' : ''
            }`,
            gameId,
            userId: joiningPlayerId,
        },
    ]);
}
