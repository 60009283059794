import { CodenamesGame } from '@playtime/database/src/model/codenames';
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import * as React from 'react';
import ScoreBanners from '../../../common/ScoreBanner/ScoreBanners';
import { dismissNotification } from '../../../common/snackbar';
import { Sync, SyncedComponentProps, syncDataItem } from '../../../hoc/sync';
import { BaseCodenamesProps } from '../../../models/game';
import ScoreBannerRosterWithData from './ScoreBannerTeamRoster';
import { getTeamName } from '../../../../back-end';
import { Box } from '@mui/material';
import db from '../../../../back-end/databases';
import { Timer } from '../../../common/Timer';
import { getPlayersTurn } from '../../../../back-end/business/pure/codenames';
import { endGuessing, giveClue } from '../../../../back-end/api/codenames/gameplay';
import { isNullOrUndefined } from '../../../../util/util';
import assert from 'assert';

function notifyPlayerCountChange(
    players: CodenamesGame['players'],
    notify: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
    notificationConfig: OptionsObject
) {
    // notify players when players leave and join game. will
    // probably be a candidate when we do big notification module refactor
    const prevActivePlayers: string[] | null = JSON.parse(sessionStorage.getItem('players') ?? 'null');
    const curActivePlayers = Object.keys(players).filter((id) => (players as CodenamesGame['players'])[id].isActive);
    sessionStorage.setItem('players', JSON.stringify(curActivePlayers));
    if (prevActivePlayers == null) return;

    if (prevActivePlayers.length > curActivePlayers.length) {
        for (const id in curActivePlayers) prevActivePlayers.splice(prevActivePlayers.indexOf(id), 1);
        const missingPlayersText = prevActivePlayers
            .map((id) => (players as CodenamesGame['players'])[id]?.displayName)
            .join(', ');
        notify(`${missingPlayersText} has left the game.`, {
            ...notificationConfig,
            variant: 'warning',
        });
    } else if (prevActivePlayers.length < curActivePlayers.length) {
        for (const id in prevActivePlayers) curActivePlayers.splice(curActivePlayers.indexOf(id), 1);
        const extraPlayersText = curActivePlayers
            .map((id) => (players as CodenamesGame['players'])[id].displayName)
            .join(', ');
        notify(`${extraPlayersText} has joined the game.`, notificationConfig);
    }
}

const GameHeader: React.FunctionComponent<
    BaseCodenamesProps &
        SyncedComponentProps<Pick<CodenamesGame, 'teams' | 'players' | 'teamTurn' | 'words' | 'clueGiven'>>
> = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const SyncedTimer = Sync(Timer);
    const syncGame = syncDataItem(db.codenamesGame, props.gameId);
    const playerJoinLeaveSnackbarConfig: OptionsObject = {
        autoHideDuration: 5000,
        variant: 'info',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        action: dismissNotification(closeSnackbar),
    };
    React.useEffect(() => sessionStorage.removeItem('players'), []);
    props.players?.length && notifyPlayerCountChange(props.players, enqueueSnackbar, playerJoinLeaveSnackbarConfig);

    const getScoreForTeamn = (team: CodenamesGame['teams'][number]) => {
        if (!props.teams) return 0;
        const totalClues =
            props.initialGameState.config.numberOfClues + (getTeamName(props.teams[0]) === getTeamName(team) ? 1 : 0);
        return totalClues - team.scores[0];
    };
    const localChangeTurns = () => {
        assert(props.players, 'players not defined when passing turns');
        assert(!isNullOrUndefined(props.teamTurn), 'team turn not defined when passing turns');
        const playersTurn = getPlayersTurn(props.teams, props.teamTurn, props.words, props.clueGiven);
        const designatedActingPlayer = playersTurn[0];
        if (props.clueGiven) {
            endGuessing(props.gameId, designatedActingPlayer, props.initialGameState.config.secondsPerTurn > 0);
        } else {
            giveClue(
                props.gameId,
                '',
                '∞',
                props.players[designatedActingPlayer].displayName,
                props.teamTurn,
                props.initialGameState.config.secondsPerTurn > 0
            );
        }
    };
    return (
        <>
            <ScoreBanners
                gameId={props.gameId}
                initialGameState={props.initialGameState}
                TeamRoster={ScoreBannerRosterWithData(props)}
                teams={props.teams?.map((team) => ({ ...team, scoreDisplay: getScoreForTeamn(team) }))}
            />
            {props.initialGameState.config.secondsPerTurn > 0 && (
                <Box display="flex" flexGrow={1} justifyContent="center">
                    <SyncedTimer
                        endTime={syncGame.prop('turnEndTime')}
                        totalTimeInSeconds={props.initialGameState.config.secondsPerTurn}
                        onExpiration={localChangeTurns}
                    />
                </Box>
            )}
        </>
    );
};

export default GameHeader;
