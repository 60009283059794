import { DatabaseReference } from 'firebase/database';
import { getAccountsDb } from './accounts';
import { getCodenamesGameDb } from './codenamesGame';
import { getCodenamesWordCollectionDb } from './codenamesWordCollection';
import { getFishbowlGameDb } from './fishbowlGame';
import { getHistoricalGamesDb } from './historicalGames';
import { getJoinableActiveGameDb } from './joinableActiveGame';
import { getLobbyGameDb } from './lobbyGame';
import { getPhraseCollectionDb } from './phraseCollection';
import { getPlayerStatsDb } from './playerStats';
import { Reference } from '@firebase/database-types';
import { getUsersDb } from './players';

// let this be dynamic return type. way too much typing to reiterate
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getDatabases(getDbRef: () => DatabaseReference | Reference) {
    return {
        accounts: getAccountsDb(getDbRef),
        codenamesGame: getCodenamesGameDb(getDbRef),
        codenamesWordCollection: getCodenamesWordCollectionDb(getDbRef),
        fishbowlGame: getFishbowlGameDb(getDbRef),
        historicalGames: getHistoricalGamesDb(getDbRef),
        joinableActiveGames: getJoinableActiveGameDb(getDbRef),
        lobbyGame: getLobbyGameDb(getDbRef),
        phraseCollection: getPhraseCollectionDb(getDbRef),
        users: getUsersDb(getDbRef),
        playerStats: getPlayerStatsDb(getDbRef),
    };
}
