import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import React, { FunctionComponent } from 'react';
import { countRemainingPhrases, countTotalPhrases } from '../../../../back-end';
import { NotOptional } from '../../../../util/util';
import { BaseFishbowlProps } from '../../../models/game';

export type RemainingPhraseCountProps = NotOptional<Pick<FishbowlGame, 'phrasePool' | 'skippedPhrases'>> &
    BaseFishbowlProps;

const RemainingPhraseCount: FunctionComponent<RemainingPhraseCountProps> = (props) => {
    const totalPhraseCount = countTotalPhrases(props.initialGameState.players);
    const remainingPhraseCount = countRemainingPhrases(props);

    return (
        <h3>
            Phrases: {remainingPhraseCount}/{totalPhraseCount}
        </h3>
    );
};
export default RemainingPhraseCount;
