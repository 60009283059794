import { cyclicIndex, isNullOrUndefined } from '../../../util/util';
import { PlayerOutcome } from '../../api/playerStats';
import { getClueCount, getLastClue, getPlayersOutcome, getTeamForPlayer, isPlayersTurn } from '../pure/pure';
import * as core from './gameplayCore';
import { Notification } from '../../api/messaging';
import { getTeamEloSum } from '../pure/elo';
import { Result, result, success, failure, Users } from '@playtime/database';
import {
    CodenamesGame,
    CodenamesConfig,
    Clue,
    isClue,
    isGuess,
    Word,
    SpyType,
} from '@playtime/database/src/model/codenames';

export function passTurn(game: CodenamesGame, userId: string): Result<CodenamesConfig> {
    const { index, team } = getTeamForPlayer(game.teams, userId);
    if (!team) return failure("player trying to pass turn doesn't belong to a team");
    game.teams[index].playerTurn = core.getNextTurn(game.teams[index]);
    return result(game.config);
}

export function readyClueGiver(game: CodenamesGame, userId: string, words: string[]): Result<{ allReady: boolean }> {
    const playerTeam = getTeamForPlayer(game.teams, userId);
    if (!playerTeam.team) return failure("player trying to start ready process doesn't belong to a team");
    if (!isPlayersTurn(playerTeam.team, userId))
        return failure("it's not the turn of the player trying to start the ready process");

    game.teams[playerTeam.index].isReady = true;
    if (game.teams.some((t) => !t.isReady)) return result({ allReady: false });

    const wordSetResult = core.getNewWordSet(words, game.config, game.teams);
    if (!wordSetResult.success) return wordSetResult;

    game.words = wordSetResult.value;
    return result({ allReady: true });
}

export function giveClue(
    game: CodenamesGame,
    clue: string,
    count: Clue['count'],
    clueGiverName: string,
    clueGiverTeam: number,
    timeOffset?: number
) {
    if (game.teamTurn !== clueGiverTeam) return failure('tried giving a clue from an inactive team');
    const activeTeam = game.teams[game.teamTurn];
    if (game.players[activeTeam.players[activeTeam.playerTurn]].displayName !== clueGiverName)
        return failure('tried giving a clue from a non-clue-giver');
    const lastLog = game.gameLog?.length && game.gameLog[0];
    if (game.config.numberOfTeams > 1 && lastLog && isClue(lastLog) && lastLog.clueGiverTeam === clueGiverTeam)
        return failure('tried giving a second clue');
    if (game.config.numberOfTeams > 1 && lastLog && isGuess(lastLog) && lastLog.guesserTeam === clueGiverTeam)
        return failure('tried giving a clue after guessing has happened');
    game.gameLog = game.gameLog ?? [];
    game.gameLog.unshift({ clue, count, clueGiverName, clueGiverTeam, turn: game.turn });
    game.clueGiven = true;
    if (game.config.secondsPerTurn > 0 && !isNullOrUndefined(timeOffset)) core.resetTurnTimer(game, timeOffset);
    return success();
}

export type StreakEnder = 'victory' | 'assassin' | 'bystander' | 'opposingTeam' | 'pass';

export function selectWord(
    game: CodenamesGame,
    userId: string,
    word: Word,
    timeOffset?: number
): Result<
    (
        | { gameEnded: false }
        | {
              gameEnded: true;
              winningTeamIndex: number;
              playersOutcome: PlayerOutcome[];
              game: CodenamesGame;
              clueGivers: string[];
          }
    ) &
        (
            | { streakEnded: false }
            | {
                  streakEnded: true;
                  streak: string[];
                  streakEnder: StreakEnder;
                  clueGiver: string;
                  players: readonly string[];
                  config: CodenamesConfig;
              }
        )
> {
    const { index, team } = getTeamForPlayer(game.teams, userId);
    if (!team) return failure("player trying to select a word doesn't belong to a team");
    if (index !== game.teamTurn) return failure("it's not the turn of the team of the player trying to select a word");
    if (team.players[team.playerTurn] === userId) return failure("clue giver can't select words");
    if (!game.words[word.word]) return failure("selected word doesn't exist in this game");
    if (!game.clueGiven) return failure('tried selecting a word before clue was given');
    const lastClue = getLastClue(game.gameLog);
    if (!lastClue) return failure('cannot select a word before a clue is given');
    const clueCount = getClueCount(lastClue);
    if (game.streak?.length && lastClue && clueCount !== -1 && game.streak.length > clueCount)
        return failure('tried selecting more words than allowed');

    const alreadyVotedIx = word.voters?.indexOf(userId);
    if (alreadyVotedIx !== undefined && alreadyVotedIx >= 0) {
        game.words[word.word].voters?.splice(alreadyVotedIx, 1);
        return result({ gameEnded: false, streakEnded: false });
    }

    const newNumberOfVoters = (game.words[word.word].voters?.length ?? 0) + 1;
    if (newNumberOfVoters < team.players.length - 1) {
        game.words[word.word].voters = [...(game.words[word.word].voters ?? []), userId];
        return result({ gameEnded: false, streakEnded: false });
    }

    // enough votes to reveal
    game.words[word.word].revealed = true;
    game.words[word.word].voters = [];
    game.gameLog?.unshift({ word: word.word, guesserTeam: game.teamTurn, turn: game.turn });

    if (word.spyType === SpyType.bystander) {
        const streakEndedResults = core.endStreak(game, 'bystander', false /** gameEnded */, timeOffset);
        return !streakEndedResults.success
            ? streakEndedResults
            : result({
                  gameEnded: false,
                  ...streakEndedResults.value,
              });
    }

    const wordsTeamIx = word.spyType - SpyType.TeamStart;
    const correctGuess = wordsTeamIx === index;
    if (word.spyType >= SpyType.TeamStart) {
        const wordsTeamWentFirst = wordsTeamIx === 0;
        const totalClueCount = game.config.numberOfClues + (wordsTeamWentFirst ? 1 : 0);
        const revealedCount = Object.entries(game.words).filter(
            (w) => w[1].spyType === word.spyType && w[1].revealed
        ).length;
        game.teams[wordsTeamIx].scores[0]++;

        if (correctGuess) game.streak = [...(game.streak ?? []), word.word];
        if (revealedCount < totalClueCount) {
            if (correctGuess) {
                const guessedMaximumAllowed =
                    clueCount !== -1 && (game.streak ?? []).length >= clueCount + game.config.numberOfExtraGuesses;
                if (!guessedMaximumAllowed) {
                    return result({ gameEnded: false, streakEnded: false });
                }
                const streakEndedResults = core.endStreak(game, 'pass', false /** gameEnded */, timeOffset);
                return !streakEndedResults.success
                    ? streakEndedResults
                    : result({
                          gameEnded: false,
                          ...streakEndedResults.value,
                      });
            }
            const streakEndedResults = core.endStreak(game, 'opposingTeam', false /** gameEnded */, timeOffset);
            return !streakEndedResults.success
                ? streakEndedResults
                : result({
                      gameEnded: false,
                      ...streakEndedResults.value,
                  });
        }
    }

    // game has ended
    game.teams.forEach((t) => {
        t.isReady = false;
    });

    game.previousGame = {
        words: { ...game.words },
        gameLog: [...(game.gameLog ?? [])],
        teams: [...game.teams],
    };

    if (word.spyType === SpyType.Assassin) {
        const winningTeamIndex = cyclicIndex(game.teams, index + 1);
        const streakEndedResults = core.endStreak(game, 'assassin', true /** gameEnded */, timeOffset);
        return !streakEndedResults.success
            ? streakEndedResults
            : result({
                  gameEnded: true,
                  winningTeamIndex,
                  playersOutcome: getPlayersOutcome(game.teams, [winningTeamIndex]),
                  game,
                  clueGivers: core.getClueGivers(game),
                  ...streakEndedResults.value,
              });
    }

    const winningTeamIndex = wordsTeamIx;
    const streakEndedResults = core.endStreak(
        game,
        correctGuess ? 'victory' : 'opposingTeam',
        true /** gameEnded */,
        timeOffset
    );
    return !streakEndedResults.success
        ? streakEndedResults
        : result({
              gameEnded: true,
              winningTeamIndex,
              playersOutcome: getPlayersOutcome(game.teams, [winningTeamIndex]),
              game,
              clueGivers: core.getClueGivers(game),
              ...streakEndedResults.value,
          });
}

export function endGuessing(
    game: CodenamesGame,
    userId: string,
    timeOffset?: number
): Result<{ streakEnded: true; streak: string[]; clueGiver: string; players: readonly string[] }> {
    const { index, team } = getTeamForPlayer(game.teams, userId);
    if (!team) return failure("player trying to pass team turn doesn't belong to a team");
    if (game.teamTurn !== index) return failure("it's not the turn of the team of the player trying to pass team turn");
    return core.endStreak(game, 'pass', false /** gameEnded */, timeOffset);
}

export function nextClueGiver(game: CodenamesGame): Result {
    core.resetGame(game);
    return success();
}

export function changeTeams(game: CodenamesGame, teams: CodenamesGame['teams']): Result {
    core.resetGame(game);
    game.teams = teams;
    return success();
}

export function setCancellationUris(game: CodenamesGame, playerCancellationUris: Record<string, string>): Result {
    for (const [playerId, player] of Object.entries(game.players)) {
        player.reminderCancelUri = playerCancellationUris[playerId] ?? null;
    }
    return success();
}

export function getNotificationsForGame(game: CodenamesGame, gameId: string, gameUsers: Users): Result<Notification[]> {
    const notifications: Notification[] = [];
    const gameInProgress = !game.teams.some((team) => !team.isReady);
    if (!gameInProgress) {
        const gameOver = game.words;
        // clue givers get-ready notification
        if (!gameOver) {
            for (const team of game.teams) {
                const playerId = team.players[team.playerTurn];
                const deviceToken = gameUsers[playerId]?.deviceToken;
                if (!deviceToken?.token) continue;
                notifications.push({
                    deviceToken: deviceToken.token,
                    title: 'Game Started!',
                    body: "You're the clue giver. Tap ready or pass.",
                    gameId,
                    userId: playerId,
                });
            }
        }
        // game over notification
        else {
            for (const playerId of Object.keys(game.players)) {
                const deviceToken = gameUsers[playerId]?.deviceToken;
                if (!deviceToken?.token) continue;
                notifications.push({
                    deviceToken: deviceToken.token,
                    title: 'Game Over!',
                    body: 'You can now see the revealed board.',
                    gameId,
                    userId: playerId,
                });
            }
        }
        return result(notifications);
    }

    // game in progress
    const activeTeam = game.teams[game.teamTurn];
    const clueGiverId = activeTeam.players[activeTeam.playerTurn];

    // active team's guesser(s) notification
    if (game.clueGiven) {
        for (const playerId of activeTeam.players) {
            if (playerId === clueGiverId) continue;
            const deviceToken = gameUsers[playerId]?.deviceToken;
            if (!deviceToken?.token) continue;
            notifications.push({
                deviceToken: deviceToken.token,
                title: "It's your turn!",
                body: `${game.players[clueGiverId].displayName} gave a clue.`,
                gameId,
                userId: playerId,
            });
        }
        return result(notifications);
    }

    // active team's clue giver notification
    const deviceToken = gameUsers[clueGiverId]?.deviceToken;
    if (!deviceToken?.token) return result(notifications);
    notifications.push({
        deviceToken: deviceToken.token,
        title: "It's your turn!",
        body: 'Give a clue to your teammates.',
        gameId,
        userId: clueGiverId,
    });
    return result(notifications);
}

export function getReminderCancellations(game: CodenamesGame): string[] {
    const reminderCancellations: string[] = [];
    Object.values(game.players).forEach(
        (player) => player.reminderCancelUri && reminderCancellations.push(player.reminderCancelUri)
    );
    return reminderCancellations;
}

export function joinAfterGameStart(
    game: CodenamesGame,
    userId: string,
    displayName: string,
    playersElo: Record<string, number>
) {
    // add player to game
    game.players[userId] = {
        displayName: displayName,
        isActive: true,
    };

    // add player to team with less players
    if (!game.teams.length) return failure('cannot late join this game. there are no teams available');
    const largestTeam = game.teams.reduce(
        (prevTeam, curTeam) => (prevTeam.players.length > curTeam.players.length ? prevTeam : curTeam),
        game.teams[0]
    );
    const smallestTeam = game.teams.reduce(
        (prevTeam, curTeam) => (prevTeam.players.length < curTeam.players.length ? prevTeam : curTeam),
        game.teams[0]
    );
    if (smallestTeam.players.length < largestTeam.players.length) {
        smallestTeam.players = [...smallestTeam.players, userId];
        return success();
    }

    // since all teams have equal number of players, add to team with least elo
    const lowestEloTeam = game.teams.reduce(
        (prevTeam, curTeam) =>
            getTeamEloSum(prevTeam, playersElo) < getTeamEloSum(curTeam, playersElo) ? prevTeam : curTeam,
        game.teams[0]
    );
    lowestEloTeam.players = [...lowestEloTeam.players, userId];
    return success();
}

export function setGameResults(
    game: CodenamesGame,
    winningTeams: number[],
    eloChanges: number[],
    playersNewElo: Record<string, number>
) {
    for (const [id, player] of Object.entries(game.players)) {
        if (!player.isActive || !game.gameResults?.[id]) continue;
        const { index } = getTeamForPlayer(game.teams, id);
        game.gameResults[id].outcome = winningTeams.includes(index) ? 'win' : 'loss';
        game.gameResults[id].eloChange = eloChanges[index];
        game.gameResults[id].newElo = playersNewElo[id];
    }
    // by the time we get these end-game stats in gameResults, the game is over
    // so previousGame should get set with the updated gameResults immediately
    if (game.previousGame) game.previousGame.gameResults = structuredClone(game.gameResults);
    return result(game.gameResults);
}
