import { defaultCodenamesStats } from './codenames/codenamesStats';
import { defaultFishbowlStats } from './fishbowl/fishbowlStats';
import * as core from '../business/playerStats';
import { findObject } from '..';
import { inTranIfDefined, success, mergeResults, Result, result, inTranDefault } from '@playtime/database';
import db from '../databases';
import { Outcome } from '@playtime/database/src/model/historicalGames';
import { GameType } from '@playtime/database/src/model/lobby';
import { PlayerStats, StatsType } from '@playtime/database/src/model/stats';

export const defaultPlayerStats = (): PlayerStats => ({
    fishbowlStats: defaultFishbowlStats(),
    codenamesStats: defaultCodenamesStats(),
});

export const STARTING_ELO = 1200;
export const PLACEHOLDER_ID_PREFIX = 'placeholder';
export type PlayerOutcome = { id: string; outcome: Outcome };

export async function updateElo(uid: string, newElo: number, statType: StatsType) {
    return inTranIfDefined(db.playerStats, uid, (playerStats) => {
        core.updateElo(playerStats, newElo, statType);
        return success();
    });
}

export async function addHistoricalGame(historicalGameId: string, playerIds: string[], gameType: GameType) {
    const statsType = core.getStatsType(gameType);
    return mergeResults(
        await Promise.all(
            playerIds.map((id) =>
                inTranIfDefined(db.playerStats, id, (playerStats) => {
                    const games = playerStats[statsType].games;
                    if (Array.isArray(games)) games.push(historicalGameId);
                    else playerStats[statsType].games = [historicalGameId];
                    return success();
                })
            )
        )
    );
}

export async function getPlayersElo(
    playerIds: readonly string[],
    gameType: GameType
): Promise<Result<Record<string, number>>> {
    const playerEloMap: Record<string, number> = {};
    const statsType = core.getStatsType(gameType);
    const elos = await Promise.all(
        playerIds.map(async (id) => {
            return db.playerStats.property(id, statsType).fetch('elo');
        })
    );

    for (let i = 0; i < playerIds.length; i++) {
        const elo = elos[i];
        playerEloMap[playerIds[i]] = elo ?? STARTING_ELO;
    }
    return result(playerEloMap);
}

export async function getPlaceholderElo(playerCount: number, numTeams: number, gameType: GameType) {
    const statsType = core.getStatsType(gameType);
    const placeholderCount = playerCount % numTeams;
    if (placeholderCount === 0) return result(null);

    const placeholderId = core.getPlaceholderId(playerCount, numTeams);
    const placeholderElo =
        (await db.playerStats.property(placeholderId, statsType).fetch('elo')) ??
        core.getPlaceholderGuessedElo(playerCount, numTeams);

    return result({ id: placeholderId, elo: placeholderElo });
}

export async function gameOver(
    playersOutcome: PlayerOutcome[],
    playersNewElo: Record<string, number>,
    historicalGameId: string,
    gameType: GameType
) {
    const statsType = core.getStatsType(gameType);
    const defaultStats = defaultPlayerStats();
    const baseStatsUpdateResults = await Promise.all(
        playersOutcome.map((playerOutcome) =>
            inTranDefault(
                db.playerStats,
                playerOutcome.id,
                (playerStats) => {
                    playerStats[statsType] = (playerStats[statsType] ??
                        defaultStats[statsType]) as PlayerStats[StatsType.Fishbowl] & PlayerStats[StatsType.Codenames];
                    core.updateStats(playerStats, playerOutcome, playersNewElo, statsType);
                    return success();
                },
                defaultStats
            )
        )
    );

    const placeholderElo = findObject(playersNewElo, (playerElo) => playerElo[0].indexOf(PLACEHOLDER_ID_PREFIX) !== -1);
    if (placeholderElo) baseStatsUpdateResults.push(await updateElo(placeholderElo[0], placeholderElo[1], statsType));
    baseStatsUpdateResults.push(await addHistoricalGame(historicalGameId, Object.keys(playersNewElo), gameType));

    return mergeResults(baseStatsUpdateResults);
}
