import { Grid } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/auth/selectors';
import { Sync, syncDataItem } from '../../hoc/sync';
import Contested from './Contested';
import Contests from './Contests';
import Elo from '../../Stats/Elo';
import Skips from './Skips';
import TimePerClue from './TimePerClue';
import WinRate from '../../Stats/WinRate';
import db from '../../../back-end/databases';
import { StatsType } from '@playtime/database/src/model/stats';

const Stats: React.FunctionComponent = () => {
    const user = useSelector(getUser);
    const SyncedWinRate = Sync(WinRate);
    const SyncedTimePerClue = Sync(TimePerClue);
    const SyncedContests = Sync(Contests);
    const SyncedSkips = Sync(Skips);
    const SyncedContested = Sync(Contested);
    const SyncedElo = Sync(Elo);
    const syncStats = syncDataItem(db.playerStats, user.uid);
    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <SyncedWinRate
                    winCount={syncStats.prop(StatsType.Fishbowl).prop('winCount')}
                    lossCount={syncStats.prop(StatsType.Fishbowl).prop('lossCount')}
                    tieCount={syncStats.prop(StatsType.Fishbowl).prop('tieCount')}
                />
            </Grid>
            <Grid item xs={4}>
                <SyncedTimePerClue
                    guessedCount={syncStats.prop(StatsType.Fishbowl).prop('guessedCount')}
                    timeGivingClues={syncStats.prop(StatsType.Fishbowl).prop('timeGivingClues')}
                />
            </Grid>
            <Grid item xs={4}>
                <SyncedContests
                    contestCount={syncStats.prop(StatsType.Fishbowl).prop('contestCount')}
                    winCount={syncStats.prop(StatsType.Fishbowl).prop('winCount')}
                    lossCount={syncStats.prop(StatsType.Fishbowl).prop('lossCount')}
                    tieCount={syncStats.prop(StatsType.Fishbowl).prop('tieCount')}
                />
            </Grid>
            <Grid item xs={4}>
                <SyncedSkips
                    guessedCount={syncStats.prop(StatsType.Fishbowl).prop('guessedCount')}
                    passedCount={syncStats.prop(StatsType.Fishbowl).prop('passedCount')}
                />
            </Grid>
            <Grid item xs={4}>
                <SyncedContested
                    guessedCount={syncStats.prop(StatsType.Fishbowl).prop('guessedCount')}
                    contestedByOthersCount={syncStats.prop(StatsType.Fishbowl).prop('contestedByOthersCount')}
                />
            </Grid>
            <Grid item xs={4}>
                <SyncedElo elo={syncStats.prop(StatsType.Fishbowl).prop('elo')} />
            </Grid>
        </Grid>
    );
};

export default Stats;
