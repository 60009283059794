import { Button, Card, CardContent, Typography, Box } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { guessPhrase, skipPhrase, undoGuess } from '../../../../back-end';
import { getUser } from '../../../../store/auth/selectors';
import { BaseFishbowlProps } from '../../../models/game';
import { makeStyles } from 'tss-react/mui';

export interface ClueGivingProps extends BaseFishbowlProps {
    currentPhrase: string;
    guessedPhrases: string[];
    skipsUsed: number;
    turn: number;
}

const useStyles = makeStyles()({
    bigButton: {
        width: '100%',
        fontSize: '40px',
        borderRadius: '15px',
        marginTop: '5px',
    },
    mediumButton: {
        width: '100%',
        height: '100%',
        fontSize: '20px',
        borderRadius: '10px',
    },
});

export const ClueGiving: React.FunctionComponent<ClueGivingProps> = (props) => {
    const user = useSelector(getUser);
    const localGuessPhrase = () => guessPhrase(props.gameId, user.uid, props.turn, props.currentPhrase);
    const localSkipPhrase = () => skipPhrase(props.gameId, user.uid, props.turn, props.currentPhrase);
    const localUndoGuess = () => undoGuess(props.gameId, props.turn);
    const { classes } = useStyles();

    const skipSuffix =
        props.skipsUsed < props.initialGameState.config.freeSkips
            ? ''
            : ` (-${props.initialGameState.config.skipPenaltySeconds})`;

    return (
        <Box display="flex" flexDirection="column" flexGrow={2}>
            <Box display="flex" justifyContent="center">
                <Card>
                    <CardContent>
                        <Typography variant="h5">{props.currentPhrase}</Typography>
                    </CardContent>
                </Card>
            </Box>
            <Box flexGrow={2} />
            <Box display="flex">
                <Box flexGrow={1} flexBasis={0}>
                    {props.initialGameState.config.enableUndoGuess && props.guessedPhrases.length > 0 ? (
                        <Button
                            onClick={localUndoGuess}
                            className={classes.mediumButton}
                            variant="contained"
                            color="secondary"
                        >
                            Undo
                        </Button>
                    ) : null}
                </Box>
                &nbsp;
                <Box flexGrow={1} flexBasis={0}>
                    <Button
                        style={{ flexGrow: 1 }}
                        onClick={localSkipPhrase}
                        className={classes.mediumButton}
                        variant="contained"
                    >
                        Skip{skipSuffix}
                    </Button>
                </Box>
            </Box>
            <Button onClick={localGuessPhrase} className={classes.bigButton} variant="contained" color="primary">
                Guessed!
            </Button>
        </Box>
    );
};
