export enum StatsType {
    Fishbowl = 'fishbowlStats',
    Codenames = 'codenamesStats',
}

export interface PlayerStats {
    [StatsType.Fishbowl]: FishbowlStats;
    [StatsType.Codenames]: CodenamesStats;
}

interface CommonStats {
    winCount: number;
    lossCount: number;
    tieCount: number;
    elo: number;
    // default of array type, [], will convert to undefined in firebase, so this needs to be marked optional.
    games?: string[];
}

export interface FishbowlStats extends CommonStats {
    // default of object type, {}, will convert to undefined in firebase, so this needs to be marked optional.
    phrases?: Record<string, PhraseData>;
    guessedCount: number;
    passedCount: number;
    contestCount: number;
    timeGivingClues: number;
    contestedByOthersCount: number;
}

interface PhraseData {
    phrase: string;
    count: number;
}

export interface Streak {
    count: number;
    words?: string[][];
}

export interface CodenamesStats extends CommonStats {
    streaks?: Record<number, Streak>;
    clueGiverCount: number;
    assassinCount: number;
    bystanderCount: number;
    enemyAgentCount: number;
    assassinGuesserCount: number;
    bystanderGuesserCount: number;
    allyAgentGuesserCount: number;
    enemyAgentGuesserCount: number;
}
