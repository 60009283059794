import assert from 'assert';
import Axios from 'axios';
import { getEarlyBackendUri } from '../../store/appSettings/selectors';

const timeDataCache = {
    /** Round-trip time for checking server time */
    rtt: 0,
    /** Time offset in milliseconds (client time - server time) */
    offset: 0,
    /** Number of times server time has been checked */
    timesChecked: 0,
};
type TimeData = typeof timeDataCache;
type TimeDataEntry = Omit<TimeData, 'timesChecked'>;

const notFoundTimeData: TimeDataEntry = {
    rtt: 0,
    offset: 0,
};

function calcRttAndOffset(clientSendTime: number, clientReceiveTime: number, serverTime: number): TimeDataEntry {
    const rtt = clientReceiveTime - clientSendTime;
    const offset = clientReceiveTime - serverTime - rtt / 2;
    return { rtt, offset };
}

async function checkUtcTime(): Promise<TimeDataEntry> {
    const backendUri = getEarlyBackendUri();
    if (!backendUri) return Promise.resolve(notFoundTimeData);
    const getTimeUrl = `${backendUri}/api/getTime`;

    const clientSendTime = Date.now();
    const response = await Axios.post<number>(getTimeUrl);
    const clientReceiveTime = Date.now();
    const timeEntry = calcRttAndOffset(clientSendTime, clientReceiveTime, response.data);
    return timeEntry;
}

function rollingAvg(prevAvg: number, prevCount: number, newValue: number): number {
    return (prevAvg * prevCount + newValue) / (prevCount + 1);
}

let lock = false;
async function syncTime() {
    if (lock) {
        return;
    }
    lock = true;
    try {
        const newTimeEntry = await checkUtcTime();
        timeDataCache.rtt = rollingAvg(timeDataCache.rtt, timeDataCache.timesChecked, newTimeEntry.rtt);
        timeDataCache.offset = rollingAvg(timeDataCache.offset, timeDataCache.timesChecked, newTimeEntry.offset);
        timeDataCache.timesChecked++;
        console.log('time sync: ' + JSON.stringify(timeDataCache));
    } finally {
        // Only sync every 5 sec at most
        setTimeout(() => {
            lock = false;
        }, 5000);
    }
}

// Do a sync as soon as possible
const initialPromise = syncTime();

/** Get time offset and try recalc; only await if not complete yet */
export async function getTimeOffset(): Promise<number> {
    syncTime();
    if (timeDataCache.timesChecked < 1) {
        try {
            await initialPromise;
        } catch {
            // Error encountered trying to get offset, let it default to zero.
            return timeDataCache.offset;
        }
        assert(timeDataCache.timesChecked > 0, 'Missing time sync data');
    }
    return timeDataCache.offset;
}

/** Return time offset without recalc; may return 0 */
export function tryGetTimeOffset(): number {
    return timeDataCache.offset;
}
