import { Result, success, failure } from '@playtime/database';
import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import { getCyclic, shuffle } from '../../../util/util';
import { getAllPhrases, getCurrentPhrase, trueNow } from '../pure/pure';

export function pause(game: FishbowlGame, offset: number, now?: number): Result {
    if (game.turnEndTime === undefined) {
        return failure('Not currently in a turn');
    }
    const remainingTimeMs = game.turnEndTime - trueNow(offset, now);
    if (remainingTimeMs <= 0) {
        return failure('Negative remaining time');
    }
    game.pausedRemainingMs = remainingTimeMs;
    delete game.turnEndTime;
    return success();
}

/** Get all player-entered phrases and move to active phrases. */
export function populatePhrasePool(game: FishbowlGame): void {
    const allPhrases = getAllPhrases(game.players);
    game.phrasePool = shuffle(allPhrases);
}

export function nextRound(game: FishbowlGame, offset: number, now?: number): boolean {
    const gameOverRound = game.config.rounds.length;
    if (++game.round >= gameOverRound) {
        // End game
        game.round = gameOverRound;
        delete game.turnEndTime;
        return false;
    }
    populatePhrasePool(game);
    pause(game, offset, now);
    return true;
}

function changeScore(game: FishbowlGame, scoreDiff: number): void {
    if (game.round >= game.config.rounds.length) {
        // Game is over
        return;
    }

    // If timer is not going, it should impact previous team, unless paused
    const offset = game.turnEndTime !== undefined ? 0 : game.pausedRemainingMs !== undefined ? 0 : 1;
    const currentTeam = getCyclic(game.teams, game.teamTurn - offset);
    currentTeam.scores[game.round] += scoreDiff;
}

export const incrementScore = (game: FishbowlGame) => changeScore(game, 1);
export const decrementScore = (game: FishbowlGame) => changeScore(game, -1);

export function returnSkippedPhrasesToPool(game: FishbowlGame) {
    game.phrasePool = shuffle([...(game.phrasePool ?? []), ...(game.skippedPhrases ?? [])]);
    delete game.skippedPhrases;
}

export function getNextPhrase(game: FishbowlGame): string | undefined {
    const nextPhrase = getCurrentPhrase(game.phrasePool);
    if (nextPhrase) {
        return nextPhrase;
    }
    returnSkippedPhrasesToPool(game);
    return getCurrentPhrase(game.phrasePool);
}

function setPhraseStartTime(game: FishbowlGame, offset: number, now?: number) {
    game.currentPhraseStartMs = trueNow(offset, now);
}

export function goNextPhrase(game: FishbowlGame, offset: number, now?: number): string | undefined {
    let nextPhrase: string | undefined;
    do {
        nextPhrase = getNextPhrase(game);
    } while (!nextPhrase && nextRound(game, offset, now));
    setPhraseStartTime(game, offset, now);
    return nextPhrase;
}

export function nextPlayerTurn(game: FishbowlGame) {
    const team = game.teams[game.teamTurn];
    if (++team.playerTurn >= team.players.length) {
        team.playerTurn = 0;
    }
}

export function addPlaceholderPlayers(
    playersElo: Record<string, number>,
    placeholderElo: { id: string; elo: number } | null,
    numPlaceholders: number
) {
    if (numPlaceholders === 0 || !placeholderElo) return;
    for (let i = 0; i < numPlaceholders; i++) {
        playersElo[`${placeholderElo.id}_${i}`] = placeholderElo.elo;
    }
}
