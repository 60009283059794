import { Box, Paper, Typography } from '@mui/material';
import { FindInPage, Opacity } from '@mui/icons-material';
import { GameType } from '@playtime/database/src/model/lobby';
import * as React from 'react';
import { useState } from 'react';
import { getColorSchemeRgb, hexToRgb, RGBColorScheme } from '../../../back-end';
import {
    codenamesColorPrimary,
    codenamesColorSecondary,
    fishbowlColorPrimary,
    fishbowlColorSecondary,
} from '../../../setup/theme';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ fishbowlColors: RGBColorScheme; codenamesColors: RGBColorScheme }>()(
    (_, { fishbowlColors, codenamesColors }) => ({
        paper: { width: '82px', margin: '5px' },
        fishbowl: {
            backgroundImage: `linear-gradient(45deg, rgb(${fishbowlColors.primary}) 0%, 
                rgb(${fishbowlColors.intermediate}) 30%, 
                rgb(${fishbowlColors.secondary}) 100%)`,
            boxShadow: 'rgb(173 216 230) 0px 0px 5px 5px',
        },
        codenames: {
            backgroundImage: `linear-gradient(45deg, rgb(${codenamesColors.primary}) 0%, 
                rgb(${codenamesColors.intermediate}) 70%, 
                rgb(${codenamesColors.secondary}) 100%)`,
            boxShadow: 'rgb(173 216 230) 0px 0px 5px 5px',
        },
    })
);

const fishbowlColors = getColorSchemeRgb(
    hexToRgb(fishbowlColorPrimary) ?? [0, 0, 0],
    hexToRgb(fishbowlColorSecondary) ?? [0, 0, 0]
);
const codenamesColors = getColorSchemeRgb(
    hexToRgb(codenamesColorPrimary) ?? [0, 0, 0],
    hexToRgb(codenamesColorSecondary) ?? [0, 0, 0]
);

interface GameTypeSelectionProps {
    onChange(type: GameType | null): void;
}

const GameTypeSelection: React.FunctionComponent<GameTypeSelectionProps> = (props) => {
    const { classes } = useStyles({ fishbowlColors, codenamesColors });
    const [selectedGameType, setSelectedGameType] = useState<GameType | null>(null);
    const onGameTypeClick = (gameType: GameType) => {
        const newType = selectedGameType === gameType ? null : gameType;
        setSelectedGameType(newType);
        props.onChange(newType);
    };
    const getTextColor = (gameType: GameType) => {
        return selectedGameType === gameType ? 'white' : 'black';
    };
    return (
        <Box display="flex" flexGrow={1} paddingBottom="7px" justifyContent="center">
            <Paper
                elevation={2}
                onClick={() => onGameTypeClick(GameType.Fishbowl)}
                className={`${classes.paper} ${selectedGameType === GameType.Fishbowl ? classes.fishbowl : ''}`}
            >
                <Box display="flex" flexDirection="column" alignItems="center" margin="7px">
                    <Opacity style={{ fontSize: 40, color: getTextColor(GameType.Fishbowl) }} />

                    <Typography style={{ fontSize: 12, color: getTextColor(GameType.Fishbowl) }}>Fishbowl</Typography>
                </Box>
            </Paper>
            <Paper
                elevation={2}
                onClick={() => onGameTypeClick(GameType.Codenames)}
                className={`${classes.paper} ${selectedGameType === GameType.Codenames ? classes.codenames : ''}`}
            >
                <Box display="flex" flexDirection="column" alignItems="center" margin="7px">
                    <FindInPage style={{ fontSize: 40, color: getTextColor(GameType.Codenames) }} />

                    <Typography style={{ fontSize: 12, color: getTextColor(GameType.Codenames) }}>Codenames</Typography>
                </Box>
            </Paper>
        </Box>
    );
};

export default GameTypeSelection;
