import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { Help } from '../common/Help';
import { makeStyles } from 'tss-react/mui';

export interface StatCardProps {
    title: string;
    caption?: string;
    toolTip?: string | JSX.Element;
}

const useStyles = makeStyles()({
    fillHeight: {
        height: '100%',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        paddingBottom: 4,
    },
    content: {
        padding: 8,
        paddingBottom: '8px !important',
    },
});

const StatCard: React.FunctionComponent<React.PropsWithChildren<StatCardProps>> = (props) => {
    const { classes } = useStyles();
    return (
        <Card className={classes.fillHeight}>
            <CardContent className={`${classes.content} ${classes.fillHeight}`}>
                <Grid container direction="column" justifyContent="space-between" className={classes.fillHeight}>
                    <Grid item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography className={classes.title} variant="h5">
                                    {props.title}
                                </Typography>
                            </Grid>
                            {props.toolTip ? (
                                <Grid item>
                                    <Help>{props.toolTip}</Help>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid item style={{ width: '100%' }}>
                        {props.children}
                    </Grid>
                    <Grid item>
                        {props.caption ? (
                            <Box justifyContent="flex-start">
                                <Typography variant="caption">{props.caption}</Typography>
                            </Box>
                        ) : null}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default StatCard;
