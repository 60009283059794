import { Dialog, Button, Box, Typography } from '@mui/material';
import * as React from 'react';
import { PremiumTeam } from '../../back-end';

interface ConfirmPurchaseProps {
    banner?: Pick<PremiumTeam, 'cost' | 'prefix' | 'rating'>;
    showDialog: boolean;
    userStarDust: number;
    userRating: number;
    onPurchase(): void;
    onCancel?(): void;
}

const ConfirmPurchase: React.FunctionComponent<React.PropsWithChildren<ConfirmPurchaseProps>> = (props) => {
    const failedValidation = [];
    if (!props.banner) return null;
    if (props.banner.cost > props.userStarDust) failedValidation.push('you do not have enough star dust');
    if (props.banner.rating && props.banner.rating > props.userRating)
        failedValidation.push('you are not high enough rating');
    let validationText = undefined;
    if (failedValidation.length > 0) {
        validationText = failedValidation.join(' and ');
        validationText = `${validationText[0].toUpperCase()}${validationText.substring(1)}`;
        validationText = `${validationText}.`;
    }
    return (
        <Dialog open={props.showDialog}>
            <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                    {validationText ? (
                        <Typography sx={{ textAlign: 'center' }}>{validationText}</Typography>
                    ) : (
                        props.children
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    <Button variant="contained" color="secondary" onClick={props.onCancel} sx={{ margin: '10px' }}>
                        Cancel
                    </Button>
                    {!validationText && (
                        <Button variant="contained" color="primary" onClick={props.onPurchase} sx={{ margin: '10px' }}>
                            Confirm
                        </Button>
                    )}
                </Box>
            </Box>
        </Dialog>
    );
};

export default ConfirmPurchase;
