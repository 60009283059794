import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { CodenamesGame } from '@playtime/database/src';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../../store/auth/selectors';
import GameResults, { GameResultsProps } from '../../../../common/GameResults';
import { getTextColor } from '../../../../common/styles';
import { SyncedComponentProps } from '../../../../hoc/sync';
import { BaseCodenamesProps } from '../../../../models/game';
import GameLog from './GameLog';

interface LogsProps
    extends SyncedComponentProps<Pick<CodenamesGame, 'gameLog' | 'words' | 'teams' | 'gameResults'>>,
        BaseCodenamesProps {
    logTab: 'gameLog' | 'gameResults';
    setLogTab: (tab: 'gameLog' | 'gameResults') => void;
}

const Logs: React.FunctionComponent<LogsProps> = (props) => {
    const user = useSelector(getUser);
    const gameResults = props.gameResults && Object.values(props.gameResults);
    const showGameResults = !!(gameResults && gameResults.length > 0 && gameResults[0].outcome);
    if (!showGameResults && !props.gameLog) return null;
    const onlyOneTab = !showGameResults;
    const userGameResults = props.gameResults && props.gameResults[user.uid];
    const additionalStats: GameResultsProps['additionalGameResults'] = !userGameResults
        ? []
        : [
              [
                  'Correct Guesses',
                  userGameResults?.allyAgentCount ?? 0 > 0 ? (
                      <Box display="flex" key="correctGuesses">
                          <Typography sx={{ fontWeight: 'bolder', color: green[600] }}>
                              +{userGameResults?.allyAgentCount}
                          </Typography>
                          <Box sx={{ marginTop: '4px', marginLeft: '5px' }}>
                              <img src="./star-dust.png" color="white" alt="" width="18" height="18" />
                          </Box>
                      </Box>
                  ) : (
                      <></>
                  ),
                  <Typography
                      sx={{ fontWeight: 'bolder', color: userGameResults.allyAgentCount > 0 ? green[600] : undefined }}
                      key="allyAgentCount"
                  >
                      {userGameResults?.allyAgentCount}
                  </Typography>,
              ],
              [
                  'Incorrect Guesses',
                  <></>,
                  <Typography
                      sx={{ fontWeight: 'bolder', color: userGameResults.enemyAgentCount > 0 ? red[600] : undefined }}
                      key="enemyAgentCount"
                  >
                      {userGameResults?.enemyAgentCount}
                  </Typography>,
              ],
              [
                  'Bystander Guesses',
                  <></>,
                  <Typography
                      sx={{
                          fontWeight: 'bolder',
                          color: userGameResults.bystanderCount > 0 ? getTextColor(true, [220, 213, 185]) : undefined,
                      }}
                      key="bystanderCount"
                  >
                      {userGameResults?.bystanderCount}
                  </Typography>,
              ],
          ];
    if ((userGameResults?.assassinCount ?? 0) > 0) {
        additionalStats.unshift([
            'Assassin Guessed',
            <></>,
            <Typography sx={{ fontWeight: 'bolder', color: 'red' }} key="assassinCount">
                <img src="./skull-crossbones.png" color="white" alt="" width="24" height="16" />
            </Typography>,
        ]);
    }
    return (
        <TabContext value={onlyOneTab ? 'gameLog' : props.logTab}>
            <Box pb={1}>
                <TabList
                    onChange={(_, val) => {
                        console.log('changing?!');
                        props.setLogTab(val);
                    }}
                    centered
                    sx={onlyOneTab ? { '& .MuiTabs-indicator': { display: 'none' } } : undefined}
                >
                    <Tab
                        label="Game Log"
                        value="gameLog"
                        sx={onlyOneTab ? { pointerEvents: 'none', color: 'rgba(0, 0, 0, 0.6) !important' } : undefined}
                    />
                    {showGameResults && <Tab label="Game Results" value="gameResults" />}
                </TabList>
                <Box
                    sx={{
                        height: '30vh',
                        overflow: 'auto',
                        borderRadius: '6px',
                        border: '1px solid rgba(0, 0, 0, 0.32)',
                        '& .MuiTabPanel-root': {
                            padding: '6px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                        },
                    }}
                >
                    <TabPanel value="gameLog">
                        <GameLog {...props} />
                    </TabPanel>
                    {showGameResults && userGameResults && props.gameResults && (
                        <TabPanel value="gameResults">
                            <GameResults gameResults={props.gameResults} additionalGameResults={additionalStats} />
                        </TabPanel>
                    )}
                </Box>
            </Box>
        </TabContext>
    );
};

export default Logs;
