import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import React, { FunctionComponent } from 'react';
import { getCyclic, isNullOrUndefined } from '../../../../util/util';
import { SyncedComponentProp } from '../../../hoc/sync';
import { BaseFishbowlProps } from '../../../models/game';
import ClueGuesser from './ClueGuesser';
import { GameFooter } from './GameFooter';
import { GameOver } from './GameOver';
import { GuessedPhrases } from './GuessedPhrases';
import StandBy from './StandBy';

export interface NotClueGiverProps extends BaseFishbowlProps, Pick<FishbowlGame, 'turn' | 'teamTurn'> {
    teams: SyncedComponentProp<FishbowlGame['teams']>;
    phase: 'gameOver' | 'standBy' | 'guessing';
}

const getCurrentPlayer = (team: FishbowlGame['teams'][0] | undefined, players: FishbowlGame['players']) => {
    if (!team) return undefined;
    const id = team.players[team.playerTurn];
    return { id, displayName: players[id].displayName };
};

export const NotClueGiver: FunctionComponent<NotClueGiverProps> = (props) => {
    if (isNullOrUndefined(props.teamTurn) || !props.teams?.length) return null;
    // Calculate current and next players
    const currentPlayer = getCurrentPlayer(props.teams[props.teamTurn], props.initialGameState.players);
    const nextPlayer = getCurrentPlayer(getCyclic(props.teams, props.teamTurn + 1), props.initialGameState.players);
    if (!currentPlayer || !nextPlayer) return null;

    return (
        <>
            {props.phase === 'gameOver' ? (
                <GameOver gameId={props.gameId} initialGameState={props.initialGameState} />
            ) : (
                <>
                    {props.phase === 'standBy' ? <StandBy /> : <ClueGuesser />}
                    <GameFooter
                        currentPlayerName={currentPlayer.displayName}
                        nextPlayerName={nextPlayer.displayName}
                        nextPlayerId={nextPlayer.id}
                    />
                    <GuessedPhrases gameId={props.gameId} initialGameState={props.initialGameState} turn={props.turn} />
                </>
            )}
        </>
    );
};
